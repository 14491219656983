import {
  JsonParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'
import { useState } from 'react'
import { QualificationTierMap } from '@nxcr-org/web-api-interface/lib/domain_pb'
import { Vehicle } from '@nxcr-org/web-api-interface/lib/vehicle_pb'
import { FleetService } from '../../../../api/fleet-service'
import { getDealerAddress, getDealerName } from '../../../../utils/getDealer'
import { useQuery } from 'react-query'
import { RESERVATION_STATUSES } from '../../../../utils'

export function useAssignmentBlock({
  assignVehicle,
  customerTier,
  assignedVehicle,
  unAssignVehicle,
  reservationStatus,
  make,
  programId,
  vehicleId,
}: {
  assignVehicle: (vehicleId: string) => void
  customerTier: QualificationTierMap[keyof QualificationTierMap]
  reload: boolean
  assignedVehicle: Vehicle.AsObject
  unAssignVehicle: () => void
  reservationStatus: string
  make?: string
  programId?: string
  vehicleId?: string
}) {
  const [{ visibleAssignmentColumns, vehiclePage, vin }, setQueryParams] =
    useQueryParams({
      vehiclePage: withDefault(NumberParam, 0),
      visibleAssignmentColumns: withDefault(JsonParam, {
        color: true,
        make: true,
        model: true,
        mileage: true,
        trim: true,
        dealerName: true,
        dealerAddress: false,
        acquired: true,
        vin: true,
        assignment: true,
      }),
      vin: StringParam,
    })

  const criteria = [
    {
      property: 'make',
      query: make?.toLowerCase?.() || 'tesla',
    },
    {
      property: 'status',
      query: 'eligible',
    },
    ...(vin?.length
      ? [
          {
            property: 'vin',
            query: vin,
          },
        ]
      : []),
  ]

  // TODO - remove?
  // criteria.push({
  //   property: 'model',
  //   query: '',
  //   // query: isModel3 ? '3' : 'Y',
  // })

  const requestParams = {
    page: vehiclePage,
    customerId: '',
    criteriaList: criteria,
    resultsPerPage: 5,
  }

  const onColumnVisibilityModelChange = (newModel) => {
    setQueryParams({
      visibleAssignmentColumns: newModel,
    })
  }

  const setPage = (nextPage: number) => {
    setQueryParams({ vehiclePage: nextPage })
  }

  const setVIN = (nextVIN: string) => {
    setQueryParams({ vin: nextVIN })
  }

  const defaultVinModalState = {
    open: false,
    onClose: undefined,
    handleYesClick: undefined,
    headerText: '',
  }

  const [vinModal, setVinModal] = useState(defaultVinModalState)

  const { data: vehicles, isFetching } = useQuery(
    ['getVehicles', requestParams, vin, customerTier, assignedVehicle],
    async () => {
      return FleetService.getVehicles(requestParams, programId).then(
        async (vehicleResponse) => {
          const customerIsQualified =
            reservationStatus === RESERVATION_STATUSES.QUALIFIED ||
            reservationStatus === RESERVATION_STATUSES.ASSIGNED_VEHICLE ||
            reservationStatus === RESERVATION_STATUSES.ACTIVATED ||
            reservationStatus === 'QUALIFIED' ||
            reservationStatus === 'ASSIGNED_VEHICLE' ||
            reservationStatus === 'ACTIVATED'

          const vehicles = vehicleResponse.vehiclesList.map((v) => ({
            ...v.vehicle,
            color: getExteriorColor(v.vehicle),
            dealerAddress: getDealerAddress(v.vehicle),
            dealerName: getDealerName(v.vehicle),
            text: 'Assign',
            assignVehicle: () => assignVehicle(v.vehicle.id),
            disabled: !customerIsQualified,
          }))
          return {
            vehiclesList: vehicles,
            total: vehicleResponse.total,
          }
        }
      )
    },
    {
      placeholderData: {
        vehiclesList: [],
        total: 0,
      },
      enabled: !!make,
    }
  )

  const { data: vehicleById } = useQuery(
    ['getVehicleById', vehicleId],
    async () => {
      return FleetService.getVehicleById(vehicleId).then(async (vehicle) => {
        const telematics = await FleetService.getTelematics(vehicle.vehicle.id)
        return {
          vehiclesList: [
            {
              ...vehicle.vehicle,
              text: 'Unassign',
              assignVehicle: () => unAssignVehicle(),
              color: vehicle.vehicle.exteriorColor,
              mileage: telematics.telematicsList[0]?.odometer?.reading || '',
              odo_date: telematics.telematicsList[0]?.odometer?.date || '',
            },
          ],
          total: 1,
        }
      })
    },
    {
      enabled: !!vehicleId,
    }
  )

  return {
    vehicles:
      reservationStatus === 'ASSIGNED_VEHICLE' && vehicleId
        ? vehicleById
        : vehicles,
    visibleAssignmentColumns,
    onColumnVisibilityModelChange,
    setPage,
    vehiclePage,
    isFetching,
    setVIN,
    vin,
    vinModal,
    setVinModal,
    defaultVinModalState,
  }
}

function getExteriorColor(vehicle: Vehicle.AsObject) {
  return vehicle?.exteriorColorsList?.[0]?.title
}

export interface AssignableVehicle {
  id: string
  make: string
  bodyType: string
  model: string
  mileage: string
  color: string
  acquired: string
  vin: string
  trim: string
  dealerAddress: string
  dealerName: string
}
