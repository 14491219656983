import { useOktaAuth } from '@okta/okta-react'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { availableRoutes } from '../../src/available-routes'

export function useMainNavigation() {
  const { oktaAuth } = useOktaAuth()
  const [isSidebarOpen, setSidebarOpen] = useState(true)

  const logout = async () => {
    oktaAuth.tokenManager.clear()
    await oktaAuth.signOut()
  }

  const toggleSidebar = () => {
    setSidebarOpen((wasOpen) => !wasOpen)
  }

  const { data: user } = useQuery(
    'authenticatedUser',
    () => {
      return oktaAuth.getUser().then((user) => {
        const initials = `${user.given_name?.[0]}${user.family_name?.[0]}`

        return {
          ...user,
          initials,
        }
      })
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore :: Only this is needed to start
      placeholderData: {
        initials: '',
      },
    }
  )
  return {
    logout,
    routes: availableRoutes(),
    user,
    isSidebarOpen,
    toggleSidebar,
  }
}
