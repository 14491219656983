import React, { createContext, useState } from 'react'
import { useQuery } from 'react-query'
import { SelectChangeEvent } from '@mui/material'
import { NexusService } from 'global-apis/nexus-service'

interface Client {
  id: string
  name: string
}

interface ClientContextType {
  clientId: string
  onHandleChange: (e: SelectChangeEvent) => void // or your actual event handler type
  clients: Client[] | undefined
}

export const ClientContext = createContext<ClientContextType | undefined>(
  undefined
)

const ClientIDProvider = ({ children }) => {
  const [clientId, setClientId] = useState<string>('')
  const { data } = useQuery('clients', NexusService.getListClients, {
    placeholderData: [],
  })

  const onHandleChange = (e: SelectChangeEvent) => {
    setClientId(e.target.value)
  }

  const stateData: ClientContextType = {
    clientId: clientId || data?.[0]?.id || '',
    onHandleChange,
    clients: data,
  }

  return (
    <ClientContext.Provider value={stateData}>
      {children}
    </ClientContext.Provider>
  )
}

export default ClientIDProvider
