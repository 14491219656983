import React, { useContext } from 'react'
import { Box, MenuItem, Select } from '@mui/material'
import { ClientContext } from '../../utils/clientContext'

const Clients = () => {
  const { clients, onHandleChange, clientId } = useContext(ClientContext)

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'column',
        marginRight: '16px',
      }}
    >
      <Select value={clientId} onChange={onHandleChange}>
        {clients?.map((client) => (
          <MenuItem key={client.id} value={client.id}>
            {client.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}

export default Clients
