// source: vehicle.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var domain_pb = require('./domain_pb.js');
goog.object.extend(proto, domain_pb);
goog.exportSymbol('proto.vehicle.Activity', null, global);
goog.exportSymbol('proto.vehicle.ActivityFailure', null, global);
goog.exportSymbol('proto.vehicle.ActivityState', null, global);
goog.exportSymbol('proto.vehicle.Annotation', null, global);
goog.exportSymbol('proto.vehicle.BuyersGuide', null, global);
goog.exportSymbol('proto.vehicle.Color', null, global);
goog.exportSymbol('proto.vehicle.Color.Name', null, global);
goog.exportSymbol('proto.vehicle.ColorCode', null, global);
goog.exportSymbol('proto.vehicle.ColorCode.Type', null, global);
goog.exportSymbol('proto.vehicle.Coordinates', null, global);
goog.exportSymbol('proto.vehicle.CustodianType', null, global);
goog.exportSymbol('proto.vehicle.Custody', null, global);
goog.exportSymbol('proto.vehicle.Custody.CustodianCase', null, global);
goog.exportSymbol('proto.vehicle.Document', null, global);
goog.exportSymbol('proto.vehicle.Document.TypeCase', null, global);
goog.exportSymbol('proto.vehicle.Infleeting', null, global);
goog.exportSymbol('proto.vehicle.Infleeting.InfleetingStatus', null, global);
goog.exportSymbol('proto.vehicle.InfleetingVehicle', null, global);
goog.exportSymbol('proto.vehicle.Ingestion', null, global);
goog.exportSymbol('proto.vehicle.Ingestion.ChangeType', null, global);
goog.exportSymbol('proto.vehicle.Inspection', null, global);
goog.exportSymbol('proto.vehicle.InspectionPhoto', null, global);
goog.exportSymbol('proto.vehicle.Location', null, global);
goog.exportSymbol('proto.vehicle.LocationUpdate', null, global);
goog.exportSymbol('proto.vehicle.LocationUpdated', null, global);
goog.exportSymbol('proto.vehicle.Maintenance', null, global);
goog.exportSymbol('proto.vehicle.Media', null, global);
goog.exportSymbol('proto.vehicle.Media.Angle', null, global);
goog.exportSymbol('proto.vehicle.Media.Category', null, global);
goog.exportSymbol('proto.vehicle.Model', null, global);
goog.exportSymbol('proto.vehicle.Odometer', null, global);
goog.exportSymbol('proto.vehicle.Odometer.ReadingType', null, global);
goog.exportSymbol('proto.vehicle.Odometer.Units', null, global);
goog.exportSymbol('proto.vehicle.OdometerUpdate', null, global);
goog.exportSymbol('proto.vehicle.OdometerUpdate.Units', null, global);
goog.exportSymbol('proto.vehicle.Preference', null, global);
goog.exportSymbol('proto.vehicle.Preference.Color', null, global);
goog.exportSymbol('proto.vehicle.Priority', null, global);
goog.exportSymbol('proto.vehicle.Profile', null, global);
goog.exportSymbol('proto.vehicle.Property', null, global);
goog.exportSymbol('proto.vehicle.PropertyUpdated', null, global);
goog.exportSymbol('proto.vehicle.PropertyUpdated.UpdatedPropertyCase', null, global);
goog.exportSymbol('proto.vehicle.PurchaseOrder', null, global);
goog.exportSymbol('proto.vehicle.Reg262', null, global);
goog.exportSymbol('proto.vehicle.Reg51', null, global);
goog.exportSymbol('proto.vehicle.Reg553', null, global);
goog.exportSymbol('proto.vehicle.Registration', null, global);
goog.exportSymbol('proto.vehicle.State', null, global);
goog.exportSymbol('proto.vehicle.Title', null, global);
goog.exportSymbol('proto.vehicle.UsedVehicleHistoryDisclosure', null, global);
goog.exportSymbol('proto.vehicle.Valuation', null, global);
goog.exportSymbol('proto.vehicle.Valuation.ValuationType', null, global);
goog.exportSymbol('proto.vehicle.Vehicle', null, global);
goog.exportSymbol('proto.vehicle.VehicleDeleted', null, global);
goog.exportSymbol('proto.vehicle.VehicleDeleted.Reason', null, global);
goog.exportSymbol('proto.vehicle.VehicleInfleetingUpdated', null, global);
goog.exportSymbol('proto.vehicle.VehiclePreferencesNotAvailable', null, global);
goog.exportSymbol('proto.vehicle.VehicleTagType', null, global);
goog.exportSymbol('proto.vehicle.VehicleTermsOfService', null, global);
goog.exportSymbol('proto.vehicle.VehicleUpdated', null, global);
goog.exportSymbol('proto.vehicle.Warranty', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.Custody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.vehicle.Custody.oneofGroups_);
};
goog.inherits(proto.vehicle.Custody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.Custody.displayName = 'proto.vehicle.Custody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.Media = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.Media, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.Media.displayName = 'proto.vehicle.Media';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.Valuation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.Valuation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.Valuation.displayName = 'proto.vehicle.Valuation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.Color = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vehicle.Color.repeatedFields_, null);
};
goog.inherits(proto.vehicle.Color, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.Color.displayName = 'proto.vehicle.Color';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.ColorCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.ColorCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.ColorCode.displayName = 'proto.vehicle.ColorCode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.Profile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vehicle.Profile.repeatedFields_, null);
};
goog.inherits(proto.vehicle.Profile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.Profile.displayName = 'proto.vehicle.Profile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.Property = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.Property, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.Property.displayName = 'proto.vehicle.Property';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.Vehicle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vehicle.Vehicle.repeatedFields_, null);
};
goog.inherits(proto.vehicle.Vehicle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.Vehicle.displayName = 'proto.vehicle.Vehicle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.Coordinates = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.Coordinates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.Coordinates.displayName = 'proto.vehicle.Coordinates';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.Location = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.Location.displayName = 'proto.vehicle.Location';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.Odometer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.Odometer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.Odometer.displayName = 'proto.vehicle.Odometer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.Annotation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.Annotation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.Annotation.displayName = 'proto.vehicle.Annotation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.Inspection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.Inspection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.Inspection.displayName = 'proto.vehicle.Inspection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.InspectionPhoto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.InspectionPhoto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.InspectionPhoto.displayName = 'proto.vehicle.InspectionPhoto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.Registration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.Registration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.Registration.displayName = 'proto.vehicle.Registration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.Title = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.Title, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.Title.displayName = 'proto.vehicle.Title';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.Warranty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.Warranty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.Warranty.displayName = 'proto.vehicle.Warranty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.Maintenance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.Maintenance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.Maintenance.displayName = 'proto.vehicle.Maintenance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.Reg262 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.Reg262, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.Reg262.displayName = 'proto.vehicle.Reg262';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.PurchaseOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.PurchaseOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.PurchaseOrder.displayName = 'proto.vehicle.PurchaseOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.Reg51 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.Reg51, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.Reg51.displayName = 'proto.vehicle.Reg51';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.Reg553 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.Reg553, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.Reg553.displayName = 'proto.vehicle.Reg553';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.BuyersGuide = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.BuyersGuide, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.BuyersGuide.displayName = 'proto.vehicle.BuyersGuide';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.UsedVehicleHistoryDisclosure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.UsedVehicleHistoryDisclosure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.UsedVehicleHistoryDisclosure.displayName = 'proto.vehicle.UsedVehicleHistoryDisclosure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.VehicleTermsOfService = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.VehicleTermsOfService, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.VehicleTermsOfService.displayName = 'proto.vehicle.VehicleTermsOfService';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.VehicleUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vehicle.VehicleUpdated.repeatedFields_, null);
};
goog.inherits(proto.vehicle.VehicleUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.VehicleUpdated.displayName = 'proto.vehicle.VehicleUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.PropertyUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.vehicle.PropertyUpdated.oneofGroups_);
};
goog.inherits(proto.vehicle.PropertyUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.PropertyUpdated.displayName = 'proto.vehicle.PropertyUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.VehicleDeleted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.VehicleDeleted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.VehicleDeleted.displayName = 'proto.vehicle.VehicleDeleted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.Ingestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vehicle.Ingestion.repeatedFields_, null);
};
goog.inherits(proto.vehicle.Ingestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.Ingestion.displayName = 'proto.vehicle.Ingestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.LocationUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.LocationUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.LocationUpdated.displayName = 'proto.vehicle.LocationUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.LocationUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.LocationUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.LocationUpdate.displayName = 'proto.vehicle.LocationUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.OdometerUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.OdometerUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.OdometerUpdate.displayName = 'proto.vehicle.OdometerUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.VehiclePreferencesNotAvailable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vehicle.VehiclePreferencesNotAvailable.repeatedFields_, null);
};
goog.inherits(proto.vehicle.VehiclePreferencesNotAvailable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.VehiclePreferencesNotAvailable.displayName = 'proto.vehicle.VehiclePreferencesNotAvailable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.Preference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.Preference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.Preference.displayName = 'proto.vehicle.Preference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.Infleeting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vehicle.Infleeting.repeatedFields_, null);
};
goog.inherits(proto.vehicle.Infleeting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.Infleeting.displayName = 'proto.vehicle.Infleeting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.VehicleInfleetingUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.VehicleInfleetingUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.VehicleInfleetingUpdated.displayName = 'proto.vehicle.VehicleInfleetingUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.InfleetingVehicle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle.InfleetingVehicle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.InfleetingVehicle.displayName = 'proto.vehicle.InfleetingVehicle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle.Document = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.vehicle.Document.oneofGroups_);
};
goog.inherits(proto.vehicle.Document, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle.Document.displayName = 'proto.vehicle.Document';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.vehicle.Custody.oneofGroups_ = [[1,2,5,6]];

/**
 * @enum {number}
 */
proto.vehicle.Custody.CustodianCase = {
  CUSTODIAN_NOT_SET: 0,
  DEALERSHIP: 1,
  CUSTOMER: 2,
  DEALERSHIP_ID: 5,
  CUSTOMER_ID: 6
};

/**
 * @return {proto.vehicle.Custody.CustodianCase}
 */
proto.vehicle.Custody.prototype.getCustodianCase = function() {
  return /** @type {proto.vehicle.Custody.CustodianCase} */(jspb.Message.computeOneofCase(this, proto.vehicle.Custody.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.Custody.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.Custody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.Custody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Custody.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealership: (f = msg.getDealership()) && domain_pb.Dealership.toObject(includeInstance, f),
    customer: (f = msg.getCustomer()) && domain_pb.Customer.toObject(includeInstance, f),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    date: jspb.Message.getFieldWithDefault(msg, 3, 0),
    stock: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.Custody}
 */
proto.vehicle.Custody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.Custody;
  return proto.vehicle.Custody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.Custody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.Custody}
 */
proto.vehicle.Custody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_pb.Dealership;
      reader.readMessage(value,domain_pb.Dealership.deserializeBinaryFromReader);
      msg.setDealership(value);
      break;
    case 2:
      var value = new domain_pb.Customer;
      reader.readMessage(value,domain_pb.Customer.deserializeBinaryFromReader);
      msg.setCustomer(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStock(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.Custody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.Custody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.Custody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Custody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealership();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      domain_pb.Dealership.serializeBinaryToWriter
    );
  }
  f = message.getCustomer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      domain_pb.Customer.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getStock();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional domain.Dealership dealership = 1;
 * @return {?proto.domain.Dealership}
 */
proto.vehicle.Custody.prototype.getDealership = function() {
  return /** @type{?proto.domain.Dealership} */ (
    jspb.Message.getWrapperField(this, domain_pb.Dealership, 1));
};


/**
 * @param {?proto.domain.Dealership|undefined} value
 * @return {!proto.vehicle.Custody} returns this
*/
proto.vehicle.Custody.prototype.setDealership = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.vehicle.Custody.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.Custody} returns this
 */
proto.vehicle.Custody.prototype.clearDealership = function() {
  return this.setDealership(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Custody.prototype.hasDealership = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional domain.Customer customer = 2;
 * @return {?proto.domain.Customer}
 */
proto.vehicle.Custody.prototype.getCustomer = function() {
  return /** @type{?proto.domain.Customer} */ (
    jspb.Message.getWrapperField(this, domain_pb.Customer, 2));
};


/**
 * @param {?proto.domain.Customer|undefined} value
 * @return {!proto.vehicle.Custody} returns this
*/
proto.vehicle.Custody.prototype.setCustomer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.vehicle.Custody.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.Custody} returns this
 */
proto.vehicle.Custody.prototype.clearCustomer = function() {
  return this.setCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Custody.prototype.hasCustomer = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string dealership_id = 5;
 * @return {string}
 */
proto.vehicle.Custody.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Custody} returns this
 */
proto.vehicle.Custody.prototype.setDealershipId = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.vehicle.Custody.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vehicle.Custody} returns this
 */
proto.vehicle.Custody.prototype.clearDealershipId = function() {
  return jspb.Message.setOneofField(this, 5, proto.vehicle.Custody.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Custody.prototype.hasDealershipId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string customer_id = 6;
 * @return {string}
 */
proto.vehicle.Custody.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Custody} returns this
 */
proto.vehicle.Custody.prototype.setCustomerId = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.vehicle.Custody.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vehicle.Custody} returns this
 */
proto.vehicle.Custody.prototype.clearCustomerId = function() {
  return jspb.Message.setOneofField(this, 6, proto.vehicle.Custody.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Custody.prototype.hasCustomerId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 date = 3;
 * @return {number}
 */
proto.vehicle.Custody.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Custody} returns this
 */
proto.vehicle.Custody.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string stock = 4;
 * @return {string}
 */
proto.vehicle.Custody.prototype.getStock = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Custody} returns this
 */
proto.vehicle.Custody.prototype.setStock = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.Media.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.Media.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.Media} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Media.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    category: jspb.Message.getFieldWithDefault(msg, 2, 0),
    mediaType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    data: jspb.Message.getFieldWithDefault(msg, 4, ""),
    source: jspb.Message.getFieldWithDefault(msg, 5, ""),
    color: jspb.Message.getFieldWithDefault(msg, 6, ""),
    simpleColor: jspb.Message.getFieldWithDefault(msg, 7, ""),
    angle: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.Media}
 */
proto.vehicle.Media.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.Media;
  return proto.vehicle.Media.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.Media} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.Media}
 */
proto.vehicle.Media.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.vehicle.Media.Category} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMediaType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSimpleColor(value);
      break;
    case 8:
      var value = /** @type {!proto.vehicle.Media.Angle} */ (reader.readEnum());
      msg.setAngle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.Media.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.Media.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.Media} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Media.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMediaType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSimpleColor();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAngle();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vehicle.Media.Category = {
  PICKUP: 0,
  DROPOFF: 1,
  STOCK: 3,
  FEED: 4,
  LISTING: 5
};

/**
 * @enum {number}
 */
proto.vehicle.Media.Angle = {
  THREE_QUARTER: 0,
  SIDE: 1
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.vehicle.Media.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Media} returns this
 */
proto.vehicle.Media.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Category category = 2;
 * @return {!proto.vehicle.Media.Category}
 */
proto.vehicle.Media.prototype.getCategory = function() {
  return /** @type {!proto.vehicle.Media.Category} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vehicle.Media.Category} value
 * @return {!proto.vehicle.Media} returns this
 */
proto.vehicle.Media.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string media_type = 3;
 * @return {string}
 */
proto.vehicle.Media.prototype.getMediaType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Media} returns this
 */
proto.vehicle.Media.prototype.setMediaType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string data = 4;
 * @return {string}
 */
proto.vehicle.Media.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Media} returns this
 */
proto.vehicle.Media.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string source = 5;
 * @return {string}
 */
proto.vehicle.Media.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Media} returns this
 */
proto.vehicle.Media.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string color = 6;
 * @return {string}
 */
proto.vehicle.Media.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Media} returns this
 */
proto.vehicle.Media.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string simple_color = 7;
 * @return {string}
 */
proto.vehicle.Media.prototype.getSimpleColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Media} returns this
 */
proto.vehicle.Media.prototype.setSimpleColor = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional Angle angle = 8;
 * @return {!proto.vehicle.Media.Angle}
 */
proto.vehicle.Media.prototype.getAngle = function() {
  return /** @type {!proto.vehicle.Media.Angle} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.vehicle.Media.Angle} value
 * @return {!proto.vehicle.Media} returns this
 */
proto.vehicle.Media.prototype.setAngle = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.Valuation.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.Valuation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.Valuation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Valuation.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    term: jspb.Message.getFieldWithDefault(msg, 2, ""),
    units: jspb.Message.getFieldWithDefault(msg, 3, ""),
    date: jspb.Message.getFieldWithDefault(msg, 4, 0),
    projectedDate: jspb.Message.getFieldWithDefault(msg, 5, 0),
    source: jspb.Message.getFieldWithDefault(msg, 6, ""),
    description: jspb.Message.getFieldWithDefault(msg, 7, ""),
    valuationType: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.Valuation}
 */
proto.vehicle.Valuation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.Valuation;
  return proto.vehicle.Valuation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.Valuation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.Valuation}
 */
proto.vehicle.Valuation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerm(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnits(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProjectedDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = /** @type {!proto.vehicle.Valuation.ValuationType} */ (reader.readEnum());
      msg.setValuationType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.Valuation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.Valuation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.Valuation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Valuation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTerm();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUnits();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getProjectedDate();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getValuationType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vehicle.Valuation.ValuationType = {
  RETAIL: 0,
  FMV: 1,
  OFFER: 2,
  SPECIAL_PRICE: 3,
  SELLING_PRICE: 4,
  RECENT_AVERAGE_RETAIL: 5,
  RECENT_AVERAGE_LISTING_PRICE: 6,
  RECENT_AVERAGE_COST: 7,
  RECENT_AVERAGE_PROFIT: 8,
  RECENT_AVERAGE_DAYS_TO_TURN: 9,
  AVERAGE_CUSTOMER_AGE: 10,
  CUSTOMER_GENDER_AS_PERCENT_MALE: 11,
  GO_PRICE: 12,
  HSV_DEALER_COST: 13,
  AUCTION_VALUE: 14,
  FORECASTED_GO_PRICE: 15,
  FORECASTED_HSV_DEALER_COST: 16,
  FORECASTED_AUCTION: 17,
  TOTAL_PIN_FIN_INCOME: 18,
  FRONT_END_GROSS_PROFIT: 19
};

/**
 * optional int64 amount = 1;
 * @return {number}
 */
proto.vehicle.Valuation.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Valuation} returns this
 */
proto.vehicle.Valuation.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string term = 2;
 * @return {string}
 */
proto.vehicle.Valuation.prototype.getTerm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Valuation} returns this
 */
proto.vehicle.Valuation.prototype.setTerm = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string units = 3;
 * @return {string}
 */
proto.vehicle.Valuation.prototype.getUnits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Valuation} returns this
 */
proto.vehicle.Valuation.prototype.setUnits = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 date = 4;
 * @return {number}
 */
proto.vehicle.Valuation.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Valuation} returns this
 */
proto.vehicle.Valuation.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 projected_date = 5;
 * @return {number}
 */
proto.vehicle.Valuation.prototype.getProjectedDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Valuation} returns this
 */
proto.vehicle.Valuation.prototype.setProjectedDate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string source = 6;
 * @return {string}
 */
proto.vehicle.Valuation.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Valuation} returns this
 */
proto.vehicle.Valuation.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string description = 7;
 * @return {string}
 */
proto.vehicle.Valuation.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Valuation} returns this
 */
proto.vehicle.Valuation.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional ValuationType valuation_type = 8;
 * @return {!proto.vehicle.Valuation.ValuationType}
 */
proto.vehicle.Valuation.prototype.getValuationType = function() {
  return /** @type {!proto.vehicle.Valuation.ValuationType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.vehicle.Valuation.ValuationType} value
 * @return {!proto.vehicle.Valuation} returns this
 */
proto.vehicle.Valuation.prototype.setValuationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vehicle.Color.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.Color.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.Color.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.Color} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Color.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, 0),
    colorCodeList: jspb.Message.toObjectList(msg.getColorCodeList(),
    proto.vehicle.ColorCode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.Color}
 */
proto.vehicle.Color.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.Color;
  return proto.vehicle.Color.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.Color} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.Color}
 */
proto.vehicle.Color.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {!proto.vehicle.Color.Name} */ (reader.readEnum());
      msg.setName(value);
      break;
    case 4:
      var value = new proto.vehicle.ColorCode;
      reader.readMessage(value,proto.vehicle.ColorCode.deserializeBinaryFromReader);
      msg.addColorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.Color.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.Color.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.Color} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Color.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getColorCodeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.vehicle.ColorCode.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vehicle.Color.Name = {
  WHITE: 0,
  BLACK: 1,
  GRAY: 2,
  RED: 3,
  BLUE: 4,
  YELLOW: 5,
  ORANGE: 6,
  GREEN: 7,
  VIOLET: 8
};

/**
 * optional string description = 1;
 * @return {string}
 */
proto.vehicle.Color.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Color} returns this
 */
proto.vehicle.Color.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.vehicle.Color.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Color} returns this
 */
proto.vehicle.Color.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Name name = 3;
 * @return {!proto.vehicle.Color.Name}
 */
proto.vehicle.Color.prototype.getName = function() {
  return /** @type {!proto.vehicle.Color.Name} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vehicle.Color.Name} value
 * @return {!proto.vehicle.Color} returns this
 */
proto.vehicle.Color.prototype.setName = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated ColorCode color_code = 4;
 * @return {!Array<!proto.vehicle.ColorCode>}
 */
proto.vehicle.Color.prototype.getColorCodeList = function() {
  return /** @type{!Array<!proto.vehicle.ColorCode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle.ColorCode, 4));
};


/**
 * @param {!Array<!proto.vehicle.ColorCode>} value
 * @return {!proto.vehicle.Color} returns this
*/
proto.vehicle.Color.prototype.setColorCodeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vehicle.ColorCode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.ColorCode}
 */
proto.vehicle.Color.prototype.addColorCode = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vehicle.ColorCode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.Color} returns this
 */
proto.vehicle.Color.prototype.clearColorCodeList = function() {
  return this.setColorCodeList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.ColorCode.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.ColorCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.ColorCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.ColorCode.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.ColorCode}
 */
proto.vehicle.ColorCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.ColorCode;
  return proto.vehicle.ColorCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.ColorCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.ColorCode}
 */
proto.vehicle.ColorCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.ColorCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.ColorCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.ColorCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.ColorCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vehicle.ColorCode.Type = {
  MANUFACTURER: 0,
  HEX: 1,
  RGB: 2
};

/**
 * optional string code = 1;
 * @return {string}
 */
proto.vehicle.ColorCode.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.ColorCode} returns this
 */
proto.vehicle.ColorCode.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.vehicle.ColorCode.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.ColorCode} returns this
 */
proto.vehicle.ColorCode.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vehicle.Profile.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.Profile.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.Profile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.Profile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Profile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    propertiesList: jspb.Message.toObjectList(msg.getPropertiesList(),
    proto.vehicle.Property.toObject, includeInstance),
    mediaListList: jspb.Message.toObjectList(msg.getMediaListList(),
    proto.vehicle.Media.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.Profile}
 */
proto.vehicle.Profile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.Profile;
  return proto.vehicle.Profile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.Profile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.Profile}
 */
proto.vehicle.Profile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.vehicle.Property;
      reader.readMessage(value,proto.vehicle.Property.deserializeBinaryFromReader);
      msg.addProperties(value);
      break;
    case 3:
      var value = new proto.vehicle.Media;
      reader.readMessage(value,proto.vehicle.Media.deserializeBinaryFromReader);
      msg.addMediaList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.Profile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.Profile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.Profile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Profile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPropertiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vehicle.Property.serializeBinaryToWriter
    );
  }
  f = message.getMediaListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vehicle.Media.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vehicle.Profile.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Profile} returns this
 */
proto.vehicle.Profile.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Property properties = 2;
 * @return {!Array<!proto.vehicle.Property>}
 */
proto.vehicle.Profile.prototype.getPropertiesList = function() {
  return /** @type{!Array<!proto.vehicle.Property>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle.Property, 2));
};


/**
 * @param {!Array<!proto.vehicle.Property>} value
 * @return {!proto.vehicle.Profile} returns this
*/
proto.vehicle.Profile.prototype.setPropertiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vehicle.Property=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Property}
 */
proto.vehicle.Profile.prototype.addProperties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vehicle.Property, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.Profile} returns this
 */
proto.vehicle.Profile.prototype.clearPropertiesList = function() {
  return this.setPropertiesList([]);
};


/**
 * repeated Media media_list = 3;
 * @return {!Array<!proto.vehicle.Media>}
 */
proto.vehicle.Profile.prototype.getMediaListList = function() {
  return /** @type{!Array<!proto.vehicle.Media>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle.Media, 3));
};


/**
 * @param {!Array<!proto.vehicle.Media>} value
 * @return {!proto.vehicle.Profile} returns this
*/
proto.vehicle.Profile.prototype.setMediaListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vehicle.Media=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Media}
 */
proto.vehicle.Profile.prototype.addMediaList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vehicle.Media, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.Profile} returns this
 */
proto.vehicle.Profile.prototype.clearMediaListList = function() {
  return this.setMediaListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.Property.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.Property.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.Property} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Property.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertyName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    propertyString: jspb.Message.getFieldWithDefault(msg, 2, ""),
    propertyInt: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.Property}
 */
proto.vehicle.Property.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.Property;
  return proto.vehicle.Property.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.Property} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.Property}
 */
proto.vehicle.Property.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyString(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPropertyInt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.Property.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.Property.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.Property} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Property.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertyName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPropertyString();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPropertyInt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string property_name = 1;
 * @return {string}
 */
proto.vehicle.Property.prototype.getPropertyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Property} returns this
 */
proto.vehicle.Property.prototype.setPropertyName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string property_string = 2;
 * @return {string}
 */
proto.vehicle.Property.prototype.getPropertyString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Property} returns this
 */
proto.vehicle.Property.prototype.setPropertyString = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 property_int = 3;
 * @return {number}
 */
proto.vehicle.Property.prototype.getPropertyInt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Property} returns this
 */
proto.vehicle.Property.prototype.setPropertyInt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vehicle.Vehicle.repeatedFields_ = [13,14,15,16,17,18,19,20,21,22,23,29,30,31,32,33,44,45];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.Vehicle.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.Vehicle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.Vehicle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Vehicle.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    make: jspb.Message.getFieldWithDefault(msg, 3, ""),
    model: jspb.Message.getFieldWithDefault(msg, 4, ""),
    year: jspb.Message.getFieldWithDefault(msg, 5, ""),
    trim: jspb.Message.getFieldWithDefault(msg, 6, ""),
    drivetrain: jspb.Message.getFieldWithDefault(msg, 7, ""),
    transmission: jspb.Message.getFieldWithDefault(msg, 8, ""),
    bodyType: jspb.Message.getFieldWithDefault(msg, 9, ""),
    engine: jspb.Message.getFieldWithDefault(msg, 10, ""),
    optionalEquipment: jspb.Message.getFieldWithDefault(msg, 11, ""),
    manufactureDate: jspb.Message.getFieldWithDefault(msg, 12, 0),
    profilesList: jspb.Message.toObjectList(msg.getProfilesList(),
    proto.vehicle.Profile.toObject, includeInstance),
    exteriorColorsList: jspb.Message.toObjectList(msg.getExteriorColorsList(),
    proto.vehicle.Color.toObject, includeInstance),
    interiorColorsList: jspb.Message.toObjectList(msg.getInteriorColorsList(),
    proto.vehicle.Color.toObject, includeInstance),
    custodiesList: jspb.Message.toObjectList(msg.getCustodiesList(),
    proto.vehicle.Custody.toObject, includeInstance),
    locationsList: jspb.Message.toObjectList(msg.getLocationsList(),
    proto.vehicle.Location.toObject, includeInstance),
    valuationsList: jspb.Message.toObjectList(msg.getValuationsList(),
    proto.vehicle.Valuation.toObject, includeInstance),
    odometersList: jspb.Message.toObjectList(msg.getOdometersList(),
    proto.vehicle.Odometer.toObject, includeInstance),
    mediaListList: jspb.Message.toObjectList(msg.getMediaListList(),
    proto.vehicle.Media.toObject, includeInstance),
    annotationsList: jspb.Message.toObjectList(msg.getAnnotationsList(),
    proto.vehicle.Annotation.toObject, includeInstance),
    defaultLineItemsList: jspb.Message.toObjectList(msg.getDefaultLineItemsList(),
    domain_pb.LineItem.toObject, includeInstance),
    dynamicLineItemsList: jspb.Message.toObjectList(msg.getDynamicLineItemsList(),
    domain_pb.LineItem.toObject, includeInstance),
    state: jspb.Message.getFieldWithDefault(msg, 24, 0),
    activityFailure: jspb.Message.getFieldWithDefault(msg, 25, 0),
    activity: jspb.Message.getFieldWithDefault(msg, 26, 0),
    activityState: jspb.Message.getFieldWithDefault(msg, 27, 0),
    version: jspb.Message.getFieldWithDefault(msg, 28, 0),
    inspectionsList: jspb.Message.toObjectList(msg.getInspectionsList(),
    proto.vehicle.Inspection.toObject, includeInstance),
    registrationsList: jspb.Message.toObjectList(msg.getRegistrationsList(),
    proto.vehicle.Registration.toObject, includeInstance),
    titlesList: jspb.Message.toObjectList(msg.getTitlesList(),
    proto.vehicle.Title.toObject, includeInstance),
    warrantiesList: jspb.Message.toObjectList(msg.getWarrantiesList(),
    proto.vehicle.Warranty.toObject, includeInstance),
    maintenancesList: jspb.Message.toObjectList(msg.getMaintenancesList(),
    proto.vehicle.Maintenance.toObject, includeInstance),
    reg262: (f = msg.getReg262()) && proto.vehicle.Reg262.toObject(includeInstance, f),
    purchaseOrder: (f = msg.getPurchaseOrder()) && proto.vehicle.PurchaseOrder.toObject(includeInstance, f),
    reg51: (f = msg.getReg51()) && proto.vehicle.Reg51.toObject(includeInstance, f),
    reg553: (f = msg.getReg553()) && proto.vehicle.Reg553.toObject(includeInstance, f),
    buyersGuide: (f = msg.getBuyersGuide()) && proto.vehicle.BuyersGuide.toObject(includeInstance, f),
    usedVehicleHistoryDisclosure: (f = msg.getUsedVehicleHistoryDisclosure()) && proto.vehicle.UsedVehicleHistoryDisclosure.toObject(includeInstance, f),
    inServiceDate: jspb.Message.getFieldWithDefault(msg, 40, 0),
    tos: (f = msg.getTos()) && proto.vehicle.VehicleTermsOfService.toObject(includeInstance, f),
    listingDate: jspb.Message.getFieldWithDefault(msg, 42, 0),
    wheelSize: jspb.Message.getFieldWithDefault(msg, 43, ""),
    genericPropertiesList: jspb.Message.toObjectList(msg.getGenericPropertiesList(),
    proto.vehicle.Property.toObject, includeInstance),
    infleetingsList: jspb.Message.toObjectList(msg.getInfleetingsList(),
    proto.vehicle.Infleeting.toObject, includeInstance),
    financier: jspb.Message.getFieldWithDefault(msg, 46, ""),
    deleted: jspb.Message.getBooleanFieldWithDefault(msg, 99, false),
    programId: jspb.Message.getFieldWithDefault(msg, 100, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.Vehicle}
 */
proto.vehicle.Vehicle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.Vehicle;
  return proto.vehicle.Vehicle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.Vehicle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.Vehicle}
 */
proto.vehicle.Vehicle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrim(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrivetrain(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransmission(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBodyType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setEngine(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalEquipment(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setManufactureDate(value);
      break;
    case 13:
      var value = new proto.vehicle.Profile;
      reader.readMessage(value,proto.vehicle.Profile.deserializeBinaryFromReader);
      msg.addProfiles(value);
      break;
    case 14:
      var value = new proto.vehicle.Color;
      reader.readMessage(value,proto.vehicle.Color.deserializeBinaryFromReader);
      msg.addExteriorColors(value);
      break;
    case 15:
      var value = new proto.vehicle.Color;
      reader.readMessage(value,proto.vehicle.Color.deserializeBinaryFromReader);
      msg.addInteriorColors(value);
      break;
    case 16:
      var value = new proto.vehicle.Custody;
      reader.readMessage(value,proto.vehicle.Custody.deserializeBinaryFromReader);
      msg.addCustodies(value);
      break;
    case 17:
      var value = new proto.vehicle.Location;
      reader.readMessage(value,proto.vehicle.Location.deserializeBinaryFromReader);
      msg.addLocations(value);
      break;
    case 18:
      var value = new proto.vehicle.Valuation;
      reader.readMessage(value,proto.vehicle.Valuation.deserializeBinaryFromReader);
      msg.addValuations(value);
      break;
    case 19:
      var value = new proto.vehicle.Odometer;
      reader.readMessage(value,proto.vehicle.Odometer.deserializeBinaryFromReader);
      msg.addOdometers(value);
      break;
    case 20:
      var value = new proto.vehicle.Media;
      reader.readMessage(value,proto.vehicle.Media.deserializeBinaryFromReader);
      msg.addMediaList(value);
      break;
    case 21:
      var value = new proto.vehicle.Annotation;
      reader.readMessage(value,proto.vehicle.Annotation.deserializeBinaryFromReader);
      msg.addAnnotations(value);
      break;
    case 22:
      var value = new domain_pb.LineItem;
      reader.readMessage(value,domain_pb.LineItem.deserializeBinaryFromReader);
      msg.addDefaultLineItems(value);
      break;
    case 23:
      var value = new domain_pb.LineItem;
      reader.readMessage(value,domain_pb.LineItem.deserializeBinaryFromReader);
      msg.addDynamicLineItems(value);
      break;
    case 24:
      var value = /** @type {!proto.vehicle.State} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 25:
      var value = /** @type {!proto.vehicle.ActivityFailure} */ (reader.readEnum());
      msg.setActivityFailure(value);
      break;
    case 26:
      var value = /** @type {!proto.vehicle.Activity} */ (reader.readEnum());
      msg.setActivity(value);
      break;
    case 27:
      var value = /** @type {!proto.vehicle.ActivityState} */ (reader.readEnum());
      msg.setActivityState(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVersion(value);
      break;
    case 29:
      var value = new proto.vehicle.Inspection;
      reader.readMessage(value,proto.vehicle.Inspection.deserializeBinaryFromReader);
      msg.addInspections(value);
      break;
    case 30:
      var value = new proto.vehicle.Registration;
      reader.readMessage(value,proto.vehicle.Registration.deserializeBinaryFromReader);
      msg.addRegistrations(value);
      break;
    case 31:
      var value = new proto.vehicle.Title;
      reader.readMessage(value,proto.vehicle.Title.deserializeBinaryFromReader);
      msg.addTitles(value);
      break;
    case 32:
      var value = new proto.vehicle.Warranty;
      reader.readMessage(value,proto.vehicle.Warranty.deserializeBinaryFromReader);
      msg.addWarranties(value);
      break;
    case 33:
      var value = new proto.vehicle.Maintenance;
      reader.readMessage(value,proto.vehicle.Maintenance.deserializeBinaryFromReader);
      msg.addMaintenances(value);
      break;
    case 34:
      var value = new proto.vehicle.Reg262;
      reader.readMessage(value,proto.vehicle.Reg262.deserializeBinaryFromReader);
      msg.setReg262(value);
      break;
    case 35:
      var value = new proto.vehicle.PurchaseOrder;
      reader.readMessage(value,proto.vehicle.PurchaseOrder.deserializeBinaryFromReader);
      msg.setPurchaseOrder(value);
      break;
    case 36:
      var value = new proto.vehicle.Reg51;
      reader.readMessage(value,proto.vehicle.Reg51.deserializeBinaryFromReader);
      msg.setReg51(value);
      break;
    case 37:
      var value = new proto.vehicle.Reg553;
      reader.readMessage(value,proto.vehicle.Reg553.deserializeBinaryFromReader);
      msg.setReg553(value);
      break;
    case 38:
      var value = new proto.vehicle.BuyersGuide;
      reader.readMessage(value,proto.vehicle.BuyersGuide.deserializeBinaryFromReader);
      msg.setBuyersGuide(value);
      break;
    case 39:
      var value = new proto.vehicle.UsedVehicleHistoryDisclosure;
      reader.readMessage(value,proto.vehicle.UsedVehicleHistoryDisclosure.deserializeBinaryFromReader);
      msg.setUsedVehicleHistoryDisclosure(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInServiceDate(value);
      break;
    case 41:
      var value = new proto.vehicle.VehicleTermsOfService;
      reader.readMessage(value,proto.vehicle.VehicleTermsOfService.deserializeBinaryFromReader);
      msg.setTos(value);
      break;
    case 42:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setListingDate(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setWheelSize(value);
      break;
    case 44:
      var value = new proto.vehicle.Property;
      reader.readMessage(value,proto.vehicle.Property.deserializeBinaryFromReader);
      msg.addGenericProperties(value);
      break;
    case 45:
      var value = new proto.vehicle.Infleeting;
      reader.readMessage(value,proto.vehicle.Infleeting.deserializeBinaryFromReader);
      msg.addInfleetings(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinancier(value);
      break;
    case 99:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleted(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.Vehicle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.Vehicle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.Vehicle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Vehicle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTrim();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDrivetrain();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTransmission();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBodyType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getEngine();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getOptionalEquipment();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getManufactureDate();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getProfilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.vehicle.Profile.serializeBinaryToWriter
    );
  }
  f = message.getExteriorColorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.vehicle.Color.serializeBinaryToWriter
    );
  }
  f = message.getInteriorColorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.vehicle.Color.serializeBinaryToWriter
    );
  }
  f = message.getCustodiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.vehicle.Custody.serializeBinaryToWriter
    );
  }
  f = message.getLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.vehicle.Location.serializeBinaryToWriter
    );
  }
  f = message.getValuationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.vehicle.Valuation.serializeBinaryToWriter
    );
  }
  f = message.getOdometersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.vehicle.Odometer.serializeBinaryToWriter
    );
  }
  f = message.getMediaListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.vehicle.Media.serializeBinaryToWriter
    );
  }
  f = message.getAnnotationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.vehicle.Annotation.serializeBinaryToWriter
    );
  }
  f = message.getDefaultLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      domain_pb.LineItem.serializeBinaryToWriter
    );
  }
  f = message.getDynamicLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      domain_pb.LineItem.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      24,
      f
    );
  }
  f = message.getActivityFailure();
  if (f !== 0.0) {
    writer.writeEnum(
      25,
      f
    );
  }
  f = message.getActivity();
  if (f !== 0.0) {
    writer.writeEnum(
      26,
      f
    );
  }
  f = message.getActivityState();
  if (f !== 0.0) {
    writer.writeEnum(
      27,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt64(
      28,
      f
    );
  }
  f = message.getInspectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      29,
      f,
      proto.vehicle.Inspection.serializeBinaryToWriter
    );
  }
  f = message.getRegistrationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      30,
      f,
      proto.vehicle.Registration.serializeBinaryToWriter
    );
  }
  f = message.getTitlesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      31,
      f,
      proto.vehicle.Title.serializeBinaryToWriter
    );
  }
  f = message.getWarrantiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      32,
      f,
      proto.vehicle.Warranty.serializeBinaryToWriter
    );
  }
  f = message.getMaintenancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      33,
      f,
      proto.vehicle.Maintenance.serializeBinaryToWriter
    );
  }
  f = message.getReg262();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      proto.vehicle.Reg262.serializeBinaryToWriter
    );
  }
  f = message.getPurchaseOrder();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      proto.vehicle.PurchaseOrder.serializeBinaryToWriter
    );
  }
  f = message.getReg51();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto.vehicle.Reg51.serializeBinaryToWriter
    );
  }
  f = message.getReg553();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      proto.vehicle.Reg553.serializeBinaryToWriter
    );
  }
  f = message.getBuyersGuide();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      proto.vehicle.BuyersGuide.serializeBinaryToWriter
    );
  }
  f = message.getUsedVehicleHistoryDisclosure();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      proto.vehicle.UsedVehicleHistoryDisclosure.serializeBinaryToWriter
    );
  }
  f = message.getInServiceDate();
  if (f !== 0) {
    writer.writeInt64(
      40,
      f
    );
  }
  f = message.getTos();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      proto.vehicle.VehicleTermsOfService.serializeBinaryToWriter
    );
  }
  f = message.getListingDate();
  if (f !== 0) {
    writer.writeInt64(
      42,
      f
    );
  }
  f = message.getWheelSize();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getGenericPropertiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      44,
      f,
      proto.vehicle.Property.serializeBinaryToWriter
    );
  }
  f = message.getInfleetingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      45,
      f,
      proto.vehicle.Infleeting.serializeBinaryToWriter
    );
  }
  f = message.getFinancier();
  if (f.length > 0) {
    writer.writeString(
      46,
      f
    );
  }
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
      99,
      f
    );
  }
  f = message.getProgramId();
  if (f.length > 0) {
    writer.writeString(
      100,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vehicle.Vehicle.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vin = 2;
 * @return {string}
 */
proto.vehicle.Vehicle.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string make = 3;
 * @return {string}
 */
proto.vehicle.Vehicle.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string model = 4;
 * @return {string}
 */
proto.vehicle.Vehicle.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string year = 5;
 * @return {string}
 */
proto.vehicle.Vehicle.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string trim = 6;
 * @return {string}
 */
proto.vehicle.Vehicle.prototype.getTrim = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.setTrim = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string drivetrain = 7;
 * @return {string}
 */
proto.vehicle.Vehicle.prototype.getDrivetrain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.setDrivetrain = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string transmission = 8;
 * @return {string}
 */
proto.vehicle.Vehicle.prototype.getTransmission = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.setTransmission = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string body_type = 9;
 * @return {string}
 */
proto.vehicle.Vehicle.prototype.getBodyType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.setBodyType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string engine = 10;
 * @return {string}
 */
proto.vehicle.Vehicle.prototype.getEngine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.setEngine = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string optional_equipment = 11;
 * @return {string}
 */
proto.vehicle.Vehicle.prototype.getOptionalEquipment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.setOptionalEquipment = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 manufacture_date = 12;
 * @return {number}
 */
proto.vehicle.Vehicle.prototype.getManufactureDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.setManufactureDate = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * repeated Profile profiles = 13;
 * @return {!Array<!proto.vehicle.Profile>}
 */
proto.vehicle.Vehicle.prototype.getProfilesList = function() {
  return /** @type{!Array<!proto.vehicle.Profile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle.Profile, 13));
};


/**
 * @param {!Array<!proto.vehicle.Profile>} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setProfilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.vehicle.Profile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Profile}
 */
proto.vehicle.Vehicle.prototype.addProfiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.vehicle.Profile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearProfilesList = function() {
  return this.setProfilesList([]);
};


/**
 * repeated Color exterior_colors = 14;
 * @return {!Array<!proto.vehicle.Color>}
 */
proto.vehicle.Vehicle.prototype.getExteriorColorsList = function() {
  return /** @type{!Array<!proto.vehicle.Color>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle.Color, 14));
};


/**
 * @param {!Array<!proto.vehicle.Color>} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setExteriorColorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.vehicle.Color=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Color}
 */
proto.vehicle.Vehicle.prototype.addExteriorColors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.vehicle.Color, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearExteriorColorsList = function() {
  return this.setExteriorColorsList([]);
};


/**
 * repeated Color interior_colors = 15;
 * @return {!Array<!proto.vehicle.Color>}
 */
proto.vehicle.Vehicle.prototype.getInteriorColorsList = function() {
  return /** @type{!Array<!proto.vehicle.Color>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle.Color, 15));
};


/**
 * @param {!Array<!proto.vehicle.Color>} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setInteriorColorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.vehicle.Color=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Color}
 */
proto.vehicle.Vehicle.prototype.addInteriorColors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.vehicle.Color, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearInteriorColorsList = function() {
  return this.setInteriorColorsList([]);
};


/**
 * repeated Custody custodies = 16;
 * @return {!Array<!proto.vehicle.Custody>}
 */
proto.vehicle.Vehicle.prototype.getCustodiesList = function() {
  return /** @type{!Array<!proto.vehicle.Custody>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle.Custody, 16));
};


/**
 * @param {!Array<!proto.vehicle.Custody>} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setCustodiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.vehicle.Custody=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Custody}
 */
proto.vehicle.Vehicle.prototype.addCustodies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.vehicle.Custody, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearCustodiesList = function() {
  return this.setCustodiesList([]);
};


/**
 * repeated Location locations = 17;
 * @return {!Array<!proto.vehicle.Location>}
 */
proto.vehicle.Vehicle.prototype.getLocationsList = function() {
  return /** @type{!Array<!proto.vehicle.Location>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle.Location, 17));
};


/**
 * @param {!Array<!proto.vehicle.Location>} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.vehicle.Location=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Location}
 */
proto.vehicle.Vehicle.prototype.addLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.vehicle.Location, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearLocationsList = function() {
  return this.setLocationsList([]);
};


/**
 * repeated Valuation valuations = 18;
 * @return {!Array<!proto.vehicle.Valuation>}
 */
proto.vehicle.Vehicle.prototype.getValuationsList = function() {
  return /** @type{!Array<!proto.vehicle.Valuation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle.Valuation, 18));
};


/**
 * @param {!Array<!proto.vehicle.Valuation>} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setValuationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.vehicle.Valuation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Valuation}
 */
proto.vehicle.Vehicle.prototype.addValuations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.vehicle.Valuation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearValuationsList = function() {
  return this.setValuationsList([]);
};


/**
 * repeated Odometer odometers = 19;
 * @return {!Array<!proto.vehicle.Odometer>}
 */
proto.vehicle.Vehicle.prototype.getOdometersList = function() {
  return /** @type{!Array<!proto.vehicle.Odometer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle.Odometer, 19));
};


/**
 * @param {!Array<!proto.vehicle.Odometer>} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setOdometersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.vehicle.Odometer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Odometer}
 */
proto.vehicle.Vehicle.prototype.addOdometers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.vehicle.Odometer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearOdometersList = function() {
  return this.setOdometersList([]);
};


/**
 * repeated Media media_list = 20;
 * @return {!Array<!proto.vehicle.Media>}
 */
proto.vehicle.Vehicle.prototype.getMediaListList = function() {
  return /** @type{!Array<!proto.vehicle.Media>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle.Media, 20));
};


/**
 * @param {!Array<!proto.vehicle.Media>} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setMediaListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.vehicle.Media=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Media}
 */
proto.vehicle.Vehicle.prototype.addMediaList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.vehicle.Media, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearMediaListList = function() {
  return this.setMediaListList([]);
};


/**
 * repeated Annotation annotations = 21;
 * @return {!Array<!proto.vehicle.Annotation>}
 */
proto.vehicle.Vehicle.prototype.getAnnotationsList = function() {
  return /** @type{!Array<!proto.vehicle.Annotation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle.Annotation, 21));
};


/**
 * @param {!Array<!proto.vehicle.Annotation>} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setAnnotationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.vehicle.Annotation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Annotation}
 */
proto.vehicle.Vehicle.prototype.addAnnotations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.vehicle.Annotation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearAnnotationsList = function() {
  return this.setAnnotationsList([]);
};


/**
 * repeated domain.LineItem default_line_items = 22;
 * @return {!Array<!proto.domain.LineItem>}
 */
proto.vehicle.Vehicle.prototype.getDefaultLineItemsList = function() {
  return /** @type{!Array<!proto.domain.LineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.LineItem, 22));
};


/**
 * @param {!Array<!proto.domain.LineItem>} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setDefaultLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.domain.LineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.LineItem}
 */
proto.vehicle.Vehicle.prototype.addDefaultLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.domain.LineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearDefaultLineItemsList = function() {
  return this.setDefaultLineItemsList([]);
};


/**
 * repeated domain.LineItem dynamic_line_items = 23;
 * @return {!Array<!proto.domain.LineItem>}
 */
proto.vehicle.Vehicle.prototype.getDynamicLineItemsList = function() {
  return /** @type{!Array<!proto.domain.LineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.LineItem, 23));
};


/**
 * @param {!Array<!proto.domain.LineItem>} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setDynamicLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.domain.LineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.LineItem}
 */
proto.vehicle.Vehicle.prototype.addDynamicLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.domain.LineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearDynamicLineItemsList = function() {
  return this.setDynamicLineItemsList([]);
};


/**
 * optional State state = 24;
 * @return {!proto.vehicle.State}
 */
proto.vehicle.Vehicle.prototype.getState = function() {
  return /** @type {!proto.vehicle.State} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {!proto.vehicle.State} value
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 24, value);
};


/**
 * optional ActivityFailure activity_failure = 25;
 * @return {!proto.vehicle.ActivityFailure}
 */
proto.vehicle.Vehicle.prototype.getActivityFailure = function() {
  return /** @type {!proto.vehicle.ActivityFailure} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {!proto.vehicle.ActivityFailure} value
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.setActivityFailure = function(value) {
  return jspb.Message.setProto3EnumField(this, 25, value);
};


/**
 * optional Activity activity = 26;
 * @return {!proto.vehicle.Activity}
 */
proto.vehicle.Vehicle.prototype.getActivity = function() {
  return /** @type {!proto.vehicle.Activity} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {!proto.vehicle.Activity} value
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.setActivity = function(value) {
  return jspb.Message.setProto3EnumField(this, 26, value);
};


/**
 * optional ActivityState activity_state = 27;
 * @return {!proto.vehicle.ActivityState}
 */
proto.vehicle.Vehicle.prototype.getActivityState = function() {
  return /** @type {!proto.vehicle.ActivityState} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {!proto.vehicle.ActivityState} value
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.setActivityState = function(value) {
  return jspb.Message.setProto3EnumField(this, 27, value);
};


/**
 * optional int64 version = 28;
 * @return {number}
 */
proto.vehicle.Vehicle.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * repeated Inspection inspections = 29;
 * @return {!Array<!proto.vehicle.Inspection>}
 */
proto.vehicle.Vehicle.prototype.getInspectionsList = function() {
  return /** @type{!Array<!proto.vehicle.Inspection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle.Inspection, 29));
};


/**
 * @param {!Array<!proto.vehicle.Inspection>} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setInspectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 29, value);
};


/**
 * @param {!proto.vehicle.Inspection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Inspection}
 */
proto.vehicle.Vehicle.prototype.addInspections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 29, opt_value, proto.vehicle.Inspection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearInspectionsList = function() {
  return this.setInspectionsList([]);
};


/**
 * repeated Registration registrations = 30;
 * @return {!Array<!proto.vehicle.Registration>}
 */
proto.vehicle.Vehicle.prototype.getRegistrationsList = function() {
  return /** @type{!Array<!proto.vehicle.Registration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle.Registration, 30));
};


/**
 * @param {!Array<!proto.vehicle.Registration>} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setRegistrationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 30, value);
};


/**
 * @param {!proto.vehicle.Registration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Registration}
 */
proto.vehicle.Vehicle.prototype.addRegistrations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 30, opt_value, proto.vehicle.Registration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearRegistrationsList = function() {
  return this.setRegistrationsList([]);
};


/**
 * repeated Title titles = 31;
 * @return {!Array<!proto.vehicle.Title>}
 */
proto.vehicle.Vehicle.prototype.getTitlesList = function() {
  return /** @type{!Array<!proto.vehicle.Title>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle.Title, 31));
};


/**
 * @param {!Array<!proto.vehicle.Title>} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setTitlesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 31, value);
};


/**
 * @param {!proto.vehicle.Title=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Title}
 */
proto.vehicle.Vehicle.prototype.addTitles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 31, opt_value, proto.vehicle.Title, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearTitlesList = function() {
  return this.setTitlesList([]);
};


/**
 * repeated Warranty warranties = 32;
 * @return {!Array<!proto.vehicle.Warranty>}
 */
proto.vehicle.Vehicle.prototype.getWarrantiesList = function() {
  return /** @type{!Array<!proto.vehicle.Warranty>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle.Warranty, 32));
};


/**
 * @param {!Array<!proto.vehicle.Warranty>} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setWarrantiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 32, value);
};


/**
 * @param {!proto.vehicle.Warranty=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Warranty}
 */
proto.vehicle.Vehicle.prototype.addWarranties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 32, opt_value, proto.vehicle.Warranty, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearWarrantiesList = function() {
  return this.setWarrantiesList([]);
};


/**
 * repeated Maintenance maintenances = 33;
 * @return {!Array<!proto.vehicle.Maintenance>}
 */
proto.vehicle.Vehicle.prototype.getMaintenancesList = function() {
  return /** @type{!Array<!proto.vehicle.Maintenance>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle.Maintenance, 33));
};


/**
 * @param {!Array<!proto.vehicle.Maintenance>} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setMaintenancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 33, value);
};


/**
 * @param {!proto.vehicle.Maintenance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Maintenance}
 */
proto.vehicle.Vehicle.prototype.addMaintenances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 33, opt_value, proto.vehicle.Maintenance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearMaintenancesList = function() {
  return this.setMaintenancesList([]);
};


/**
 * optional Reg262 reg262 = 34;
 * @return {?proto.vehicle.Reg262}
 */
proto.vehicle.Vehicle.prototype.getReg262 = function() {
  return /** @type{?proto.vehicle.Reg262} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.Reg262, 34));
};


/**
 * @param {?proto.vehicle.Reg262|undefined} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setReg262 = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearReg262 = function() {
  return this.setReg262(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Vehicle.prototype.hasReg262 = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional PurchaseOrder purchase_order = 35;
 * @return {?proto.vehicle.PurchaseOrder}
 */
proto.vehicle.Vehicle.prototype.getPurchaseOrder = function() {
  return /** @type{?proto.vehicle.PurchaseOrder} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.PurchaseOrder, 35));
};


/**
 * @param {?proto.vehicle.PurchaseOrder|undefined} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setPurchaseOrder = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearPurchaseOrder = function() {
  return this.setPurchaseOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Vehicle.prototype.hasPurchaseOrder = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional Reg51 reg51 = 36;
 * @return {?proto.vehicle.Reg51}
 */
proto.vehicle.Vehicle.prototype.getReg51 = function() {
  return /** @type{?proto.vehicle.Reg51} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.Reg51, 36));
};


/**
 * @param {?proto.vehicle.Reg51|undefined} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setReg51 = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearReg51 = function() {
  return this.setReg51(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Vehicle.prototype.hasReg51 = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional Reg553 reg553 = 37;
 * @return {?proto.vehicle.Reg553}
 */
proto.vehicle.Vehicle.prototype.getReg553 = function() {
  return /** @type{?proto.vehicle.Reg553} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.Reg553, 37));
};


/**
 * @param {?proto.vehicle.Reg553|undefined} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setReg553 = function(value) {
  return jspb.Message.setWrapperField(this, 37, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearReg553 = function() {
  return this.setReg553(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Vehicle.prototype.hasReg553 = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional BuyersGuide buyers_guide = 38;
 * @return {?proto.vehicle.BuyersGuide}
 */
proto.vehicle.Vehicle.prototype.getBuyersGuide = function() {
  return /** @type{?proto.vehicle.BuyersGuide} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.BuyersGuide, 38));
};


/**
 * @param {?proto.vehicle.BuyersGuide|undefined} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setBuyersGuide = function(value) {
  return jspb.Message.setWrapperField(this, 38, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearBuyersGuide = function() {
  return this.setBuyersGuide(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Vehicle.prototype.hasBuyersGuide = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional UsedVehicleHistoryDisclosure used_vehicle_history_disclosure = 39;
 * @return {?proto.vehicle.UsedVehicleHistoryDisclosure}
 */
proto.vehicle.Vehicle.prototype.getUsedVehicleHistoryDisclosure = function() {
  return /** @type{?proto.vehicle.UsedVehicleHistoryDisclosure} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.UsedVehicleHistoryDisclosure, 39));
};


/**
 * @param {?proto.vehicle.UsedVehicleHistoryDisclosure|undefined} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setUsedVehicleHistoryDisclosure = function(value) {
  return jspb.Message.setWrapperField(this, 39, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearUsedVehicleHistoryDisclosure = function() {
  return this.setUsedVehicleHistoryDisclosure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Vehicle.prototype.hasUsedVehicleHistoryDisclosure = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional int64 in_service_date = 40;
 * @return {number}
 */
proto.vehicle.Vehicle.prototype.getInServiceDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.setInServiceDate = function(value) {
  return jspb.Message.setProto3IntField(this, 40, value);
};


/**
 * optional VehicleTermsOfService tos = 41;
 * @return {?proto.vehicle.VehicleTermsOfService}
 */
proto.vehicle.Vehicle.prototype.getTos = function() {
  return /** @type{?proto.vehicle.VehicleTermsOfService} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.VehicleTermsOfService, 41));
};


/**
 * @param {?proto.vehicle.VehicleTermsOfService|undefined} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setTos = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearTos = function() {
  return this.setTos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Vehicle.prototype.hasTos = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional int64 listing_date = 42;
 * @return {number}
 */
proto.vehicle.Vehicle.prototype.getListingDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 42, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.setListingDate = function(value) {
  return jspb.Message.setProto3IntField(this, 42, value);
};


/**
 * optional string wheel_size = 43;
 * @return {string}
 */
proto.vehicle.Vehicle.prototype.getWheelSize = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.setWheelSize = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * repeated Property generic_properties = 44;
 * @return {!Array<!proto.vehicle.Property>}
 */
proto.vehicle.Vehicle.prototype.getGenericPropertiesList = function() {
  return /** @type{!Array<!proto.vehicle.Property>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle.Property, 44));
};


/**
 * @param {!Array<!proto.vehicle.Property>} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setGenericPropertiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 44, value);
};


/**
 * @param {!proto.vehicle.Property=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Property}
 */
proto.vehicle.Vehicle.prototype.addGenericProperties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 44, opt_value, proto.vehicle.Property, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearGenericPropertiesList = function() {
  return this.setGenericPropertiesList([]);
};


/**
 * repeated Infleeting infleetings = 45;
 * @return {!Array<!proto.vehicle.Infleeting>}
 */
proto.vehicle.Vehicle.prototype.getInfleetingsList = function() {
  return /** @type{!Array<!proto.vehicle.Infleeting>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle.Infleeting, 45));
};


/**
 * @param {!Array<!proto.vehicle.Infleeting>} value
 * @return {!proto.vehicle.Vehicle} returns this
*/
proto.vehicle.Vehicle.prototype.setInfleetingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 45, value);
};


/**
 * @param {!proto.vehicle.Infleeting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Infleeting}
 */
proto.vehicle.Vehicle.prototype.addInfleetings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 45, opt_value, proto.vehicle.Infleeting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.clearInfleetingsList = function() {
  return this.setInfleetingsList([]);
};


/**
 * optional string financier = 46;
 * @return {string}
 */
proto.vehicle.Vehicle.prototype.getFinancier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 46, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.setFinancier = function(value) {
  return jspb.Message.setProto3StringField(this, 46, value);
};


/**
 * optional bool deleted = 99;
 * @return {boolean}
 */
proto.vehicle.Vehicle.prototype.getDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 99, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 99, value);
};


/**
 * optional string program_id = 100;
 * @return {string}
 */
proto.vehicle.Vehicle.prototype.getProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 100, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Vehicle} returns this
 */
proto.vehicle.Vehicle.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 100, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.Coordinates.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.Coordinates.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.Coordinates} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Coordinates.toObject = function(includeInstance, msg) {
  var f, obj = {
    latitude: jspb.Message.getFieldWithDefault(msg, 1, ""),
    longitude: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.Coordinates}
 */
proto.vehicle.Coordinates.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.Coordinates;
  return proto.vehicle.Coordinates.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.Coordinates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.Coordinates}
 */
proto.vehicle.Coordinates.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatitude(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.Coordinates.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.Coordinates.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.Coordinates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Coordinates.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatitude();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string latitude = 1;
 * @return {string}
 */
proto.vehicle.Coordinates.prototype.getLatitude = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Coordinates} returns this
 */
proto.vehicle.Coordinates.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string longitude = 2;
 * @return {string}
 */
proto.vehicle.Coordinates.prototype.getLongitude = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Coordinates} returns this
 */
proto.vehicle.Coordinates.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.Location.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.Location.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.Location} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Location.toObject = function(includeInstance, msg) {
  var f, obj = {
    coordinates: (f = msg.getCoordinates()) && proto.vehicle.Coordinates.toObject(includeInstance, f),
    date: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.Location}
 */
proto.vehicle.Location.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.Location;
  return proto.vehicle.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.Location}
 */
proto.vehicle.Location.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vehicle.Coordinates;
      reader.readMessage(value,proto.vehicle.Coordinates.deserializeBinaryFromReader);
      msg.setCoordinates(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.Location.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Location.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoordinates();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vehicle.Coordinates.serializeBinaryToWriter
    );
  }
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional Coordinates coordinates = 1;
 * @return {?proto.vehicle.Coordinates}
 */
proto.vehicle.Location.prototype.getCoordinates = function() {
  return /** @type{?proto.vehicle.Coordinates} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.Coordinates, 1));
};


/**
 * @param {?proto.vehicle.Coordinates|undefined} value
 * @return {!proto.vehicle.Location} returns this
*/
proto.vehicle.Location.prototype.setCoordinates = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.Location} returns this
 */
proto.vehicle.Location.prototype.clearCoordinates = function() {
  return this.setCoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Location.prototype.hasCoordinates = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 date = 2;
 * @return {number}
 */
proto.vehicle.Location.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Location} returns this
 */
proto.vehicle.Location.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.Odometer.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.Odometer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.Odometer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Odometer.toObject = function(includeInstance, msg) {
  var f, obj = {
    reading: jspb.Message.getFieldWithDefault(msg, 1, 0),
    units: jspb.Message.getFieldWithDefault(msg, 2, 0),
    readingType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    date: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.Odometer}
 */
proto.vehicle.Odometer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.Odometer;
  return proto.vehicle.Odometer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.Odometer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.Odometer}
 */
proto.vehicle.Odometer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReading(value);
      break;
    case 2:
      var value = /** @type {!proto.vehicle.Odometer.Units} */ (reader.readEnum());
      msg.setUnits(value);
      break;
    case 3:
      var value = /** @type {!proto.vehicle.Odometer.ReadingType} */ (reader.readEnum());
      msg.setReadingType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.Odometer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.Odometer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.Odometer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Odometer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReading();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUnits();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getReadingType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vehicle.Odometer.Units = {
  MILES: 0,
  KILOMETERS: 1
};

/**
 * @enum {number}
 */
proto.vehicle.Odometer.ReadingType = {
  ADVERTISED: 0,
  PICKUP: 1,
  DROPOFF: 2,
  IN_USE: 3
};

/**
 * optional int32 reading = 1;
 * @return {number}
 */
proto.vehicle.Odometer.prototype.getReading = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Odometer} returns this
 */
proto.vehicle.Odometer.prototype.setReading = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Units units = 2;
 * @return {!proto.vehicle.Odometer.Units}
 */
proto.vehicle.Odometer.prototype.getUnits = function() {
  return /** @type {!proto.vehicle.Odometer.Units} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vehicle.Odometer.Units} value
 * @return {!proto.vehicle.Odometer} returns this
 */
proto.vehicle.Odometer.prototype.setUnits = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional ReadingType reading_type = 3;
 * @return {!proto.vehicle.Odometer.ReadingType}
 */
proto.vehicle.Odometer.prototype.getReadingType = function() {
  return /** @type {!proto.vehicle.Odometer.ReadingType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vehicle.Odometer.ReadingType} value
 * @return {!proto.vehicle.Odometer} returns this
 */
proto.vehicle.Odometer.prototype.setReadingType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 date = 4;
 * @return {number}
 */
proto.vehicle.Odometer.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Odometer} returns this
 */
proto.vehicle.Odometer.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.Annotation.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.Annotation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.Annotation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Annotation.toObject = function(includeInstance, msg) {
  var f, obj = {
    notes: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    date: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.Annotation}
 */
proto.vehicle.Annotation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.Annotation;
  return proto.vehicle.Annotation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.Annotation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.Annotation}
 */
proto.vehicle.Annotation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.Annotation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.Annotation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.Annotation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Annotation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string notes = 1;
 * @return {string}
 */
proto.vehicle.Annotation.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Annotation} returns this
 */
proto.vehicle.Annotation.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.vehicle.Annotation.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Annotation} returns this
 */
proto.vehicle.Annotation.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 date = 3;
 * @return {number}
 */
proto.vehicle.Annotation.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Annotation} returns this
 */
proto.vehicle.Annotation.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.Inspection.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.Inspection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.Inspection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Inspection.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    created: jspb.Message.getFieldWithDefault(msg, 3, 0),
    report: jspb.Message.getFieldWithDefault(msg, 4, ""),
    generatedReport: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.Inspection}
 */
proto.vehicle.Inspection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.Inspection;
  return proto.vehicle.Inspection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.Inspection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.Inspection}
 */
proto.vehicle.Inspection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReport(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGeneratedReport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.Inspection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.Inspection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.Inspection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Inspection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getReport();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGeneratedReport();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vehicle.Inspection.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Inspection} returns this
 */
proto.vehicle.Inspection.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.vehicle.Inspection.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Inspection} returns this
 */
proto.vehicle.Inspection.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 created = 3;
 * @return {number}
 */
proto.vehicle.Inspection.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Inspection} returns this
 */
proto.vehicle.Inspection.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string report = 4;
 * @return {string}
 */
proto.vehicle.Inspection.prototype.getReport = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Inspection} returns this
 */
proto.vehicle.Inspection.prototype.setReport = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string generated_report = 5;
 * @return {string}
 */
proto.vehicle.Inspection.prototype.getGeneratedReport = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Inspection} returns this
 */
proto.vehicle.Inspection.prototype.setGeneratedReport = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.InspectionPhoto.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.InspectionPhoto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.InspectionPhoto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.InspectionPhoto.toObject = function(includeInstance, msg) {
  var f, obj = {
    inspectionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.InspectionPhoto}
 */
proto.vehicle.InspectionPhoto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.InspectionPhoto;
  return proto.vehicle.InspectionPhoto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.InspectionPhoto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.InspectionPhoto}
 */
proto.vehicle.InspectionPhoto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInspectionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.InspectionPhoto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.InspectionPhoto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.InspectionPhoto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.InspectionPhoto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInspectionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string inspection_id = 1;
 * @return {string}
 */
proto.vehicle.InspectionPhoto.prototype.getInspectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.InspectionPhoto} returns this
 */
proto.vehicle.InspectionPhoto.prototype.setInspectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vehicle.InspectionPhoto.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.InspectionPhoto} returns this
 */
proto.vehicle.InspectionPhoto.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string file_name = 3;
 * @return {string}
 */
proto.vehicle.InspectionPhoto.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.InspectionPhoto} returns this
 */
proto.vehicle.InspectionPhoto.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.Registration.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.Registration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.Registration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Registration.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    created: jspb.Message.getFieldWithDefault(msg, 2, 0),
    expiration: jspb.Message.getFieldWithDefault(msg, 3, 0),
    licensePlate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.Registration}
 */
proto.vehicle.Registration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.Registration;
  return proto.vehicle.Registration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.Registration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.Registration}
 */
proto.vehicle.Registration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiration(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicensePlate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.Registration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.Registration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.Registration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Registration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getExpiration();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getLicensePlate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vehicle.Registration.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Registration} returns this
 */
proto.vehicle.Registration.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 created = 2;
 * @return {number}
 */
proto.vehicle.Registration.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Registration} returns this
 */
proto.vehicle.Registration.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 expiration = 3;
 * @return {number}
 */
proto.vehicle.Registration.prototype.getExpiration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Registration} returns this
 */
proto.vehicle.Registration.prototype.setExpiration = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string license_plate = 4;
 * @return {string}
 */
proto.vehicle.Registration.prototype.getLicensePlate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Registration} returns this
 */
proto.vehicle.Registration.prototype.setLicensePlate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string file_name = 5;
 * @return {string}
 */
proto.vehicle.Registration.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Registration} returns this
 */
proto.vehicle.Registration.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.Title.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.Title.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.Title} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Title.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    created: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.Title}
 */
proto.vehicle.Title.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.Title;
  return proto.vehicle.Title.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.Title} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.Title}
 */
proto.vehicle.Title.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.Title.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.Title.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.Title} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Title.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vehicle.Title.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Title} returns this
 */
proto.vehicle.Title.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string file_name = 2;
 * @return {string}
 */
proto.vehicle.Title.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Title} returns this
 */
proto.vehicle.Title.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 created = 3;
 * @return {number}
 */
proto.vehicle.Title.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Title} returns this
 */
proto.vehicle.Title.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.Warranty.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.Warranty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.Warranty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Warranty.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    created: jspb.Message.getFieldWithDefault(msg, 3, 0),
    expiration: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.Warranty}
 */
proto.vehicle.Warranty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.Warranty;
  return proto.vehicle.Warranty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.Warranty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.Warranty}
 */
proto.vehicle.Warranty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.Warranty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.Warranty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.Warranty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Warranty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getExpiration();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vehicle.Warranty.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Warranty} returns this
 */
proto.vehicle.Warranty.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string file_name = 2;
 * @return {string}
 */
proto.vehicle.Warranty.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Warranty} returns this
 */
proto.vehicle.Warranty.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 created = 3;
 * @return {number}
 */
proto.vehicle.Warranty.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Warranty} returns this
 */
proto.vehicle.Warranty.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 expiration = 4;
 * @return {number}
 */
proto.vehicle.Warranty.prototype.getExpiration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Warranty} returns this
 */
proto.vehicle.Warranty.prototype.setExpiration = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.Maintenance.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.Maintenance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.Maintenance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Maintenance.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    created: jspb.Message.getFieldWithDefault(msg, 3, 0),
    description: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.Maintenance}
 */
proto.vehicle.Maintenance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.Maintenance;
  return proto.vehicle.Maintenance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.Maintenance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.Maintenance}
 */
proto.vehicle.Maintenance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.Maintenance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.Maintenance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.Maintenance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Maintenance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vehicle.Maintenance.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Maintenance} returns this
 */
proto.vehicle.Maintenance.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string file_name = 2;
 * @return {string}
 */
proto.vehicle.Maintenance.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Maintenance} returns this
 */
proto.vehicle.Maintenance.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 created = 3;
 * @return {number}
 */
proto.vehicle.Maintenance.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Maintenance} returns this
 */
proto.vehicle.Maintenance.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.vehicle.Maintenance.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Maintenance} returns this
 */
proto.vehicle.Maintenance.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.Reg262.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.Reg262.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.Reg262} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Reg262.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    created: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.Reg262}
 */
proto.vehicle.Reg262.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.Reg262;
  return proto.vehicle.Reg262.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.Reg262} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.Reg262}
 */
proto.vehicle.Reg262.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.Reg262.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.Reg262.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.Reg262} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Reg262.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vehicle.Reg262.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Reg262} returns this
 */
proto.vehicle.Reg262.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string file_name = 2;
 * @return {string}
 */
proto.vehicle.Reg262.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Reg262} returns this
 */
proto.vehicle.Reg262.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 created = 3;
 * @return {number}
 */
proto.vehicle.Reg262.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Reg262} returns this
 */
proto.vehicle.Reg262.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.PurchaseOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.PurchaseOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.PurchaseOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.PurchaseOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    created: jspb.Message.getFieldWithDefault(msg, 3, 0),
    purchasePrice: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.PurchaseOrder}
 */
proto.vehicle.PurchaseOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.PurchaseOrder;
  return proto.vehicle.PurchaseOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.PurchaseOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.PurchaseOrder}
 */
proto.vehicle.PurchaseOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPurchasePrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.PurchaseOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.PurchaseOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.PurchaseOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.PurchaseOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPurchasePrice();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vehicle.PurchaseOrder.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.PurchaseOrder} returns this
 */
proto.vehicle.PurchaseOrder.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string file_name = 2;
 * @return {string}
 */
proto.vehicle.PurchaseOrder.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.PurchaseOrder} returns this
 */
proto.vehicle.PurchaseOrder.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 created = 3;
 * @return {number}
 */
proto.vehicle.PurchaseOrder.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.PurchaseOrder} returns this
 */
proto.vehicle.PurchaseOrder.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 purchase_price = 4;
 * @return {number}
 */
proto.vehicle.PurchaseOrder.prototype.getPurchasePrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.PurchaseOrder} returns this
 */
proto.vehicle.PurchaseOrder.prototype.setPurchasePrice = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.Reg51.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.Reg51.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.Reg51} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Reg51.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    created: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.Reg51}
 */
proto.vehicle.Reg51.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.Reg51;
  return proto.vehicle.Reg51.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.Reg51} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.Reg51}
 */
proto.vehicle.Reg51.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.Reg51.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.Reg51.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.Reg51} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Reg51.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vehicle.Reg51.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Reg51} returns this
 */
proto.vehicle.Reg51.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string file_name = 2;
 * @return {string}
 */
proto.vehicle.Reg51.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Reg51} returns this
 */
proto.vehicle.Reg51.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 created = 3;
 * @return {number}
 */
proto.vehicle.Reg51.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Reg51} returns this
 */
proto.vehicle.Reg51.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.Reg553.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.Reg553.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.Reg553} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Reg553.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    created: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.Reg553}
 */
proto.vehicle.Reg553.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.Reg553;
  return proto.vehicle.Reg553.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.Reg553} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.Reg553}
 */
proto.vehicle.Reg553.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.Reg553.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.Reg553.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.Reg553} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Reg553.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vehicle.Reg553.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Reg553} returns this
 */
proto.vehicle.Reg553.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string file_name = 2;
 * @return {string}
 */
proto.vehicle.Reg553.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Reg553} returns this
 */
proto.vehicle.Reg553.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 created = 3;
 * @return {number}
 */
proto.vehicle.Reg553.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Reg553} returns this
 */
proto.vehicle.Reg553.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.BuyersGuide.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.BuyersGuide.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.BuyersGuide} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.BuyersGuide.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    created: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.BuyersGuide}
 */
proto.vehicle.BuyersGuide.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.BuyersGuide;
  return proto.vehicle.BuyersGuide.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.BuyersGuide} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.BuyersGuide}
 */
proto.vehicle.BuyersGuide.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.BuyersGuide.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.BuyersGuide.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.BuyersGuide} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.BuyersGuide.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vehicle.BuyersGuide.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.BuyersGuide} returns this
 */
proto.vehicle.BuyersGuide.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string file_name = 2;
 * @return {string}
 */
proto.vehicle.BuyersGuide.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.BuyersGuide} returns this
 */
proto.vehicle.BuyersGuide.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 created = 3;
 * @return {number}
 */
proto.vehicle.BuyersGuide.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.BuyersGuide} returns this
 */
proto.vehicle.BuyersGuide.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.UsedVehicleHistoryDisclosure.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.UsedVehicleHistoryDisclosure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.UsedVehicleHistoryDisclosure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.UsedVehicleHistoryDisclosure.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    created: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.UsedVehicleHistoryDisclosure}
 */
proto.vehicle.UsedVehicleHistoryDisclosure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.UsedVehicleHistoryDisclosure;
  return proto.vehicle.UsedVehicleHistoryDisclosure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.UsedVehicleHistoryDisclosure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.UsedVehicleHistoryDisclosure}
 */
proto.vehicle.UsedVehicleHistoryDisclosure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.UsedVehicleHistoryDisclosure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.UsedVehicleHistoryDisclosure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.UsedVehicleHistoryDisclosure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.UsedVehicleHistoryDisclosure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vehicle.UsedVehicleHistoryDisclosure.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.UsedVehicleHistoryDisclosure} returns this
 */
proto.vehicle.UsedVehicleHistoryDisclosure.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string file_name = 2;
 * @return {string}
 */
proto.vehicle.UsedVehicleHistoryDisclosure.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.UsedVehicleHistoryDisclosure} returns this
 */
proto.vehicle.UsedVehicleHistoryDisclosure.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 created = 3;
 * @return {number}
 */
proto.vehicle.UsedVehicleHistoryDisclosure.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.UsedVehicleHistoryDisclosure} returns this
 */
proto.vehicle.UsedVehicleHistoryDisclosure.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.VehicleTermsOfService.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.VehicleTermsOfService.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.VehicleTermsOfService} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.VehicleTermsOfService.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.VehicleTermsOfService}
 */
proto.vehicle.VehicleTermsOfService.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.VehicleTermsOfService;
  return proto.vehicle.VehicleTermsOfService.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.VehicleTermsOfService} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.VehicleTermsOfService}
 */
proto.vehicle.VehicleTermsOfService.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.VehicleTermsOfService.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.VehicleTermsOfService.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.VehicleTermsOfService} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.VehicleTermsOfService.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vehicle.VehicleTermsOfService.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.VehicleTermsOfService} returns this
 */
proto.vehicle.VehicleTermsOfService.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.vehicle.VehicleTermsOfService.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.VehicleTermsOfService} returns this
 */
proto.vehicle.VehicleTermsOfService.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 timestamp = 3;
 * @return {number}
 */
proto.vehicle.VehicleTermsOfService.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.VehicleTermsOfService} returns this
 */
proto.vehicle.VehicleTermsOfService.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vehicle.VehicleUpdated.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.VehicleUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.VehicleUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.VehicleUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.VehicleUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && proto.vehicle.Vehicle.toObject(includeInstance, f),
    propertiesList: jspb.Message.toObjectList(msg.getPropertiesList(),
    proto.vehicle.PropertyUpdated.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.VehicleUpdated}
 */
proto.vehicle.VehicleUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.VehicleUpdated;
  return proto.vehicle.VehicleUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.VehicleUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.VehicleUpdated}
 */
proto.vehicle.VehicleUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vehicle.Vehicle;
      reader.readMessage(value,proto.vehicle.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    case 2:
      var value = new proto.vehicle.PropertyUpdated;
      reader.readMessage(value,proto.vehicle.PropertyUpdated.deserializeBinaryFromReader);
      msg.addProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.VehicleUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.VehicleUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.VehicleUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.VehicleUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vehicle.Vehicle.serializeBinaryToWriter
    );
  }
  f = message.getPropertiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vehicle.PropertyUpdated.serializeBinaryToWriter
    );
  }
};


/**
 * optional Vehicle vehicle = 1;
 * @return {?proto.vehicle.Vehicle}
 */
proto.vehicle.VehicleUpdated.prototype.getVehicle = function() {
  return /** @type{?proto.vehicle.Vehicle} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.Vehicle, 1));
};


/**
 * @param {?proto.vehicle.Vehicle|undefined} value
 * @return {!proto.vehicle.VehicleUpdated} returns this
*/
proto.vehicle.VehicleUpdated.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.VehicleUpdated} returns this
 */
proto.vehicle.VehicleUpdated.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.VehicleUpdated.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated PropertyUpdated properties = 2;
 * @return {!Array<!proto.vehicle.PropertyUpdated>}
 */
proto.vehicle.VehicleUpdated.prototype.getPropertiesList = function() {
  return /** @type{!Array<!proto.vehicle.PropertyUpdated>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle.PropertyUpdated, 2));
};


/**
 * @param {!Array<!proto.vehicle.PropertyUpdated>} value
 * @return {!proto.vehicle.VehicleUpdated} returns this
*/
proto.vehicle.VehicleUpdated.prototype.setPropertiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vehicle.PropertyUpdated=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.PropertyUpdated}
 */
proto.vehicle.VehicleUpdated.prototype.addProperties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vehicle.PropertyUpdated, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.VehicleUpdated} returns this
 */
proto.vehicle.VehicleUpdated.prototype.clearPropertiesList = function() {
  return this.setPropertiesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.vehicle.PropertyUpdated.oneofGroups_ = [[2,3,4,5,6,7]];

/**
 * @enum {number}
 */
proto.vehicle.PropertyUpdated.UpdatedPropertyCase = {
  UPDATED_PROPERTY_NOT_SET: 0,
  GENERIC_PROPERTY: 2,
  VIN: 3,
  CUSTODY: 4,
  WHEEL_SIZE: 5,
  EXTERIOR_COLOR: 6,
  INTERIOR_COLOR: 7
};

/**
 * @return {proto.vehicle.PropertyUpdated.UpdatedPropertyCase}
 */
proto.vehicle.PropertyUpdated.prototype.getUpdatedPropertyCase = function() {
  return /** @type {proto.vehicle.PropertyUpdated.UpdatedPropertyCase} */(jspb.Message.computeOneofCase(this, proto.vehicle.PropertyUpdated.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.PropertyUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.PropertyUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.PropertyUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.PropertyUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    genericProperty: (f = msg.getGenericProperty()) && proto.vehicle.Property.toObject(includeInstance, f),
    vin: jspb.Message.getFieldWithDefault(msg, 3, ""),
    custody: (f = msg.getCustody()) && proto.vehicle.Custody.toObject(includeInstance, f),
    wheelSize: jspb.Message.getFieldWithDefault(msg, 5, ""),
    exteriorColor: (f = msg.getExteriorColor()) && proto.vehicle.Color.toObject(includeInstance, f),
    interiorColor: (f = msg.getInteriorColor()) && proto.vehicle.Color.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.PropertyUpdated}
 */
proto.vehicle.PropertyUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.PropertyUpdated;
  return proto.vehicle.PropertyUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.PropertyUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.PropertyUpdated}
 */
proto.vehicle.PropertyUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.vehicle.Property;
      reader.readMessage(value,proto.vehicle.Property.deserializeBinaryFromReader);
      msg.setGenericProperty(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 4:
      var value = new proto.vehicle.Custody;
      reader.readMessage(value,proto.vehicle.Custody.deserializeBinaryFromReader);
      msg.setCustody(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setWheelSize(value);
      break;
    case 6:
      var value = new proto.vehicle.Color;
      reader.readMessage(value,proto.vehicle.Color.deserializeBinaryFromReader);
      msg.setExteriorColor(value);
      break;
    case 7:
      var value = new proto.vehicle.Color;
      reader.readMessage(value,proto.vehicle.Color.deserializeBinaryFromReader);
      msg.setInteriorColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.PropertyUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.PropertyUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.PropertyUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.PropertyUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGenericProperty();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vehicle.Property.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCustody();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vehicle.Custody.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExteriorColor();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.vehicle.Color.serializeBinaryToWriter
    );
  }
  f = message.getInteriorColor();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.vehicle.Color.serializeBinaryToWriter
    );
  }
};


/**
 * optional Property generic_property = 2;
 * @return {?proto.vehicle.Property}
 */
proto.vehicle.PropertyUpdated.prototype.getGenericProperty = function() {
  return /** @type{?proto.vehicle.Property} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.Property, 2));
};


/**
 * @param {?proto.vehicle.Property|undefined} value
 * @return {!proto.vehicle.PropertyUpdated} returns this
*/
proto.vehicle.PropertyUpdated.prototype.setGenericProperty = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.vehicle.PropertyUpdated.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.PropertyUpdated} returns this
 */
proto.vehicle.PropertyUpdated.prototype.clearGenericProperty = function() {
  return this.setGenericProperty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.PropertyUpdated.prototype.hasGenericProperty = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string vin = 3;
 * @return {string}
 */
proto.vehicle.PropertyUpdated.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.PropertyUpdated} returns this
 */
proto.vehicle.PropertyUpdated.prototype.setVin = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.vehicle.PropertyUpdated.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vehicle.PropertyUpdated} returns this
 */
proto.vehicle.PropertyUpdated.prototype.clearVin = function() {
  return jspb.Message.setOneofField(this, 3, proto.vehicle.PropertyUpdated.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.PropertyUpdated.prototype.hasVin = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Custody custody = 4;
 * @return {?proto.vehicle.Custody}
 */
proto.vehicle.PropertyUpdated.prototype.getCustody = function() {
  return /** @type{?proto.vehicle.Custody} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.Custody, 4));
};


/**
 * @param {?proto.vehicle.Custody|undefined} value
 * @return {!proto.vehicle.PropertyUpdated} returns this
*/
proto.vehicle.PropertyUpdated.prototype.setCustody = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.vehicle.PropertyUpdated.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.PropertyUpdated} returns this
 */
proto.vehicle.PropertyUpdated.prototype.clearCustody = function() {
  return this.setCustody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.PropertyUpdated.prototype.hasCustody = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string wheel_size = 5;
 * @return {string}
 */
proto.vehicle.PropertyUpdated.prototype.getWheelSize = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.PropertyUpdated} returns this
 */
proto.vehicle.PropertyUpdated.prototype.setWheelSize = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.vehicle.PropertyUpdated.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vehicle.PropertyUpdated} returns this
 */
proto.vehicle.PropertyUpdated.prototype.clearWheelSize = function() {
  return jspb.Message.setOneofField(this, 5, proto.vehicle.PropertyUpdated.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.PropertyUpdated.prototype.hasWheelSize = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Color exterior_color = 6;
 * @return {?proto.vehicle.Color}
 */
proto.vehicle.PropertyUpdated.prototype.getExteriorColor = function() {
  return /** @type{?proto.vehicle.Color} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.Color, 6));
};


/**
 * @param {?proto.vehicle.Color|undefined} value
 * @return {!proto.vehicle.PropertyUpdated} returns this
*/
proto.vehicle.PropertyUpdated.prototype.setExteriorColor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.vehicle.PropertyUpdated.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.PropertyUpdated} returns this
 */
proto.vehicle.PropertyUpdated.prototype.clearExteriorColor = function() {
  return this.setExteriorColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.PropertyUpdated.prototype.hasExteriorColor = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Color interior_color = 7;
 * @return {?proto.vehicle.Color}
 */
proto.vehicle.PropertyUpdated.prototype.getInteriorColor = function() {
  return /** @type{?proto.vehicle.Color} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.Color, 7));
};


/**
 * @param {?proto.vehicle.Color|undefined} value
 * @return {!proto.vehicle.PropertyUpdated} returns this
*/
proto.vehicle.PropertyUpdated.prototype.setInteriorColor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.vehicle.PropertyUpdated.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.PropertyUpdated} returns this
 */
proto.vehicle.PropertyUpdated.prototype.clearInteriorColor = function() {
  return this.setInteriorColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.PropertyUpdated.prototype.hasInteriorColor = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.VehicleDeleted.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.VehicleDeleted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.VehicleDeleted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.VehicleDeleted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reason: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.VehicleDeleted}
 */
proto.vehicle.VehicleDeleted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.VehicleDeleted;
  return proto.vehicle.VehicleDeleted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.VehicleDeleted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.VehicleDeleted}
 */
proto.vehicle.VehicleDeleted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 3:
      var value = /** @type {!proto.vehicle.VehicleDeleted.Reason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.VehicleDeleted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.VehicleDeleted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.VehicleDeleted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.VehicleDeleted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vehicle.VehicleDeleted.Reason = {
  SOLD: 0,
  DUPLICATE: 1
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.vehicle.VehicleDeleted.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.VehicleDeleted} returns this
 */
proto.vehicle.VehicleDeleted.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vin = 2;
 * @return {string}
 */
proto.vehicle.VehicleDeleted.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.VehicleDeleted} returns this
 */
proto.vehicle.VehicleDeleted.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Reason reason = 3;
 * @return {!proto.vehicle.VehicleDeleted.Reason}
 */
proto.vehicle.VehicleDeleted.prototype.getReason = function() {
  return /** @type {!proto.vehicle.VehicleDeleted.Reason} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vehicle.VehicleDeleted.Reason} value
 * @return {!proto.vehicle.VehicleDeleted} returns this
 */
proto.vehicle.VehicleDeleted.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vehicle.Ingestion.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.Ingestion.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.Ingestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.Ingestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Ingestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && proto.vehicle.Vehicle.toObject(includeInstance, f),
    changeType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    vehiclesList: jspb.Message.toObjectList(msg.getVehiclesList(),
    proto.vehicle.Vehicle.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.Ingestion}
 */
proto.vehicle.Ingestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.Ingestion;
  return proto.vehicle.Ingestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.Ingestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.Ingestion}
 */
proto.vehicle.Ingestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vehicle.Vehicle;
      reader.readMessage(value,proto.vehicle.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    case 2:
      var value = /** @type {!proto.vehicle.Ingestion.ChangeType} */ (reader.readEnum());
      msg.setChangeType(value);
      break;
    case 3:
      var value = new proto.vehicle.Vehicle;
      reader.readMessage(value,proto.vehicle.Vehicle.deserializeBinaryFromReader);
      msg.addVehicles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.Ingestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.Ingestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.Ingestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Ingestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vehicle.Vehicle.serializeBinaryToWriter
    );
  }
  f = message.getChangeType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getVehiclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vehicle.Vehicle.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vehicle.Ingestion.ChangeType = {
  CREATE: 0,
  UPDATE: 1,
  DELETE: 2
};

/**
 * optional Vehicle vehicle = 1;
 * @return {?proto.vehicle.Vehicle}
 */
proto.vehicle.Ingestion.prototype.getVehicle = function() {
  return /** @type{?proto.vehicle.Vehicle} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.Vehicle, 1));
};


/**
 * @param {?proto.vehicle.Vehicle|undefined} value
 * @return {!proto.vehicle.Ingestion} returns this
*/
proto.vehicle.Ingestion.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.Ingestion} returns this
 */
proto.vehicle.Ingestion.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Ingestion.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ChangeType change_type = 2;
 * @return {!proto.vehicle.Ingestion.ChangeType}
 */
proto.vehicle.Ingestion.prototype.getChangeType = function() {
  return /** @type {!proto.vehicle.Ingestion.ChangeType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vehicle.Ingestion.ChangeType} value
 * @return {!proto.vehicle.Ingestion} returns this
 */
proto.vehicle.Ingestion.prototype.setChangeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated Vehicle vehicles = 3;
 * @return {!Array<!proto.vehicle.Vehicle>}
 */
proto.vehicle.Ingestion.prototype.getVehiclesList = function() {
  return /** @type{!Array<!proto.vehicle.Vehicle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle.Vehicle, 3));
};


/**
 * @param {!Array<!proto.vehicle.Vehicle>} value
 * @return {!proto.vehicle.Ingestion} returns this
*/
proto.vehicle.Ingestion.prototype.setVehiclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vehicle.Vehicle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Vehicle}
 */
proto.vehicle.Ingestion.prototype.addVehicles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vehicle.Vehicle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.Ingestion} returns this
 */
proto.vehicle.Ingestion.prototype.clearVehiclesList = function() {
  return this.setVehiclesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.LocationUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.LocationUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.LocationUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.LocationUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    location: (f = msg.getLocation()) && proto.vehicle.Location.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.LocationUpdated}
 */
proto.vehicle.LocationUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.LocationUpdated;
  return proto.vehicle.LocationUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.LocationUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.LocationUpdated}
 */
proto.vehicle.LocationUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = new proto.vehicle.Location;
      reader.readMessage(value,proto.vehicle.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.LocationUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.LocationUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.LocationUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.LocationUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vehicle.Location.serializeBinaryToWriter
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.vehicle.LocationUpdated.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.LocationUpdated} returns this
 */
proto.vehicle.LocationUpdated.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Location location = 2;
 * @return {?proto.vehicle.Location}
 */
proto.vehicle.LocationUpdated.prototype.getLocation = function() {
  return /** @type{?proto.vehicle.Location} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.Location, 2));
};


/**
 * @param {?proto.vehicle.Location|undefined} value
 * @return {!proto.vehicle.LocationUpdated} returns this
*/
proto.vehicle.LocationUpdated.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.LocationUpdated} returns this
 */
proto.vehicle.LocationUpdated.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.LocationUpdated.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.LocationUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.LocationUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.LocationUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.LocationUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    location: (f = msg.getLocation()) && proto.vehicle.Location.toObject(includeInstance, f),
    custodianId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    custodianType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.LocationUpdate}
 */
proto.vehicle.LocationUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.LocationUpdate;
  return proto.vehicle.LocationUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.LocationUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.LocationUpdate}
 */
proto.vehicle.LocationUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = new proto.vehicle.Location;
      reader.readMessage(value,proto.vehicle.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustodianId(value);
      break;
    case 4:
      var value = /** @type {!proto.vehicle.CustodianType} */ (reader.readEnum());
      msg.setCustodianType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.LocationUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.LocationUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.LocationUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.LocationUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vehicle.Location.serializeBinaryToWriter
    );
  }
  f = message.getCustodianId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCustodianType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.vehicle.LocationUpdate.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.LocationUpdate} returns this
 */
proto.vehicle.LocationUpdate.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Location location = 2;
 * @return {?proto.vehicle.Location}
 */
proto.vehicle.LocationUpdate.prototype.getLocation = function() {
  return /** @type{?proto.vehicle.Location} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.Location, 2));
};


/**
 * @param {?proto.vehicle.Location|undefined} value
 * @return {!proto.vehicle.LocationUpdate} returns this
*/
proto.vehicle.LocationUpdate.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.LocationUpdate} returns this
 */
proto.vehicle.LocationUpdate.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.LocationUpdate.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string custodian_id = 3;
 * @return {string}
 */
proto.vehicle.LocationUpdate.prototype.getCustodianId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.LocationUpdate} returns this
 */
proto.vehicle.LocationUpdate.prototype.setCustodianId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional CustodianType custodian_type = 4;
 * @return {!proto.vehicle.CustodianType}
 */
proto.vehicle.LocationUpdate.prototype.getCustodianType = function() {
  return /** @type {!proto.vehicle.CustodianType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.vehicle.CustodianType} value
 * @return {!proto.vehicle.LocationUpdate} returns this
 */
proto.vehicle.LocationUpdate.prototype.setCustodianType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.OdometerUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.OdometerUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.OdometerUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.OdometerUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reading: jspb.Message.getFieldWithDefault(msg, 2, 0),
    units: jspb.Message.getFieldWithDefault(msg, 3, 0),
    custodianId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    custodianType: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.OdometerUpdate}
 */
proto.vehicle.OdometerUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.OdometerUpdate;
  return proto.vehicle.OdometerUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.OdometerUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.OdometerUpdate}
 */
proto.vehicle.OdometerUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReading(value);
      break;
    case 3:
      var value = /** @type {!proto.vehicle.OdometerUpdate.Units} */ (reader.readEnum());
      msg.setUnits(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustodianId(value);
      break;
    case 5:
      var value = /** @type {!proto.vehicle.CustodianType} */ (reader.readEnum());
      msg.setCustodianType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.OdometerUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.OdometerUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.OdometerUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.OdometerUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReading();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getUnits();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCustodianId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCustodianType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vehicle.OdometerUpdate.Units = {
  MILES: 0,
  KILOMETERS: 1
};

/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.vehicle.OdometerUpdate.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.OdometerUpdate} returns this
 */
proto.vehicle.OdometerUpdate.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 reading = 2;
 * @return {number}
 */
proto.vehicle.OdometerUpdate.prototype.getReading = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.OdometerUpdate} returns this
 */
proto.vehicle.OdometerUpdate.prototype.setReading = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Units units = 3;
 * @return {!proto.vehicle.OdometerUpdate.Units}
 */
proto.vehicle.OdometerUpdate.prototype.getUnits = function() {
  return /** @type {!proto.vehicle.OdometerUpdate.Units} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vehicle.OdometerUpdate.Units} value
 * @return {!proto.vehicle.OdometerUpdate} returns this
 */
proto.vehicle.OdometerUpdate.prototype.setUnits = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string custodian_id = 4;
 * @return {string}
 */
proto.vehicle.OdometerUpdate.prototype.getCustodianId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.OdometerUpdate} returns this
 */
proto.vehicle.OdometerUpdate.prototype.setCustodianId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional CustodianType custodian_type = 5;
 * @return {!proto.vehicle.CustodianType}
 */
proto.vehicle.OdometerUpdate.prototype.getCustodianType = function() {
  return /** @type {!proto.vehicle.CustodianType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.vehicle.CustodianType} value
 * @return {!proto.vehicle.OdometerUpdate} returns this
 */
proto.vehicle.OdometerUpdate.prototype.setCustodianType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vehicle.VehiclePreferencesNotAvailable.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.VehiclePreferencesNotAvailable.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.VehiclePreferencesNotAvailable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.VehiclePreferencesNotAvailable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.VehiclePreferencesNotAvailable.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    preferencesList: jspb.Message.toObjectList(msg.getPreferencesList(),
    proto.vehicle.Preference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.VehiclePreferencesNotAvailable}
 */
proto.vehicle.VehiclePreferencesNotAvailable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.VehiclePreferencesNotAvailable;
  return proto.vehicle.VehiclePreferencesNotAvailable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.VehiclePreferencesNotAvailable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.VehiclePreferencesNotAvailable}
 */
proto.vehicle.VehiclePreferencesNotAvailable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = new proto.vehicle.Preference;
      reader.readMessage(value,proto.vehicle.Preference.deserializeBinaryFromReader);
      msg.addPreferences(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.VehiclePreferencesNotAvailable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.VehiclePreferencesNotAvailable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.VehiclePreferencesNotAvailable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.VehiclePreferencesNotAvailable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPreferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vehicle.Preference.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.vehicle.VehiclePreferencesNotAvailable.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.VehiclePreferencesNotAvailable} returns this
 */
proto.vehicle.VehiclePreferencesNotAvailable.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Preference preferences = 2;
 * @return {!Array<!proto.vehicle.Preference>}
 */
proto.vehicle.VehiclePreferencesNotAvailable.prototype.getPreferencesList = function() {
  return /** @type{!Array<!proto.vehicle.Preference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle.Preference, 2));
};


/**
 * @param {!Array<!proto.vehicle.Preference>} value
 * @return {!proto.vehicle.VehiclePreferencesNotAvailable} returns this
*/
proto.vehicle.VehiclePreferencesNotAvailable.prototype.setPreferencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vehicle.Preference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Preference}
 */
proto.vehicle.VehiclePreferencesNotAvailable.prototype.addPreferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vehicle.Preference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.VehiclePreferencesNotAvailable} returns this
 */
proto.vehicle.VehiclePreferencesNotAvailable.prototype.clearPreferencesList = function() {
  return this.setPreferencesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.Preference.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.Preference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.Preference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Preference.toObject = function(includeInstance, msg) {
  var f, obj = {
    priority: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vehicleModel: jspb.Message.getFieldWithDefault(msg, 2, 0),
    preferredColor: jspb.Message.getFieldWithDefault(msg, 3, 0),
    year: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.Preference}
 */
proto.vehicle.Preference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.Preference;
  return proto.vehicle.Preference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.Preference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.Preference}
 */
proto.vehicle.Preference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vehicle.Priority} */ (reader.readEnum());
      msg.setPriority(value);
      break;
    case 2:
      var value = /** @type {!proto.vehicle.Model} */ (reader.readEnum());
      msg.setVehicleModel(value);
      break;
    case 3:
      var value = /** @type {!proto.vehicle.Preference.Color} */ (reader.readEnum());
      msg.setPreferredColor(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.Preference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.Preference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.Preference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Preference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPriority();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getVehicleModel();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPreferredColor();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vehicle.Preference.Color = {
  WHITE: 0,
  BLACK: 1,
  GRAY: 2,
  RED: 3,
  BLUE: 4,
  YELLOW: 5,
  ORANGE: 6,
  GREEN: 7,
  VIOLET: 8
};

/**
 * optional Priority priority = 1;
 * @return {!proto.vehicle.Priority}
 */
proto.vehicle.Preference.prototype.getPriority = function() {
  return /** @type {!proto.vehicle.Priority} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vehicle.Priority} value
 * @return {!proto.vehicle.Preference} returns this
 */
proto.vehicle.Preference.prototype.setPriority = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Model vehicle_model = 2;
 * @return {!proto.vehicle.Model}
 */
proto.vehicle.Preference.prototype.getVehicleModel = function() {
  return /** @type {!proto.vehicle.Model} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vehicle.Model} value
 * @return {!proto.vehicle.Preference} returns this
 */
proto.vehicle.Preference.prototype.setVehicleModel = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Color preferred_color = 3;
 * @return {!proto.vehicle.Preference.Color}
 */
proto.vehicle.Preference.prototype.getPreferredColor = function() {
  return /** @type {!proto.vehicle.Preference.Color} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vehicle.Preference.Color} value
 * @return {!proto.vehicle.Preference} returns this
 */
proto.vehicle.Preference.prototype.setPreferredColor = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string year = 4;
 * @return {string}
 */
proto.vehicle.Preference.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Preference} returns this
 */
proto.vehicle.Preference.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vehicle.Infleeting.repeatedFields_ = [13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.Infleeting.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.Infleeting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.Infleeting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Infleeting.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startDate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    completionDate: jspb.Message.getFieldWithDefault(msg, 5, 0),
    step: jspb.Message.getFieldWithDefault(msg, 6, 0),
    infleetingStatus: jspb.Message.getFieldWithDefault(msg, 7, 0),
    registration: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    keyCards: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    charger: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    mats: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    plateMounting: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    adaptersList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    tagType: jspb.Message.getFieldWithDefault(msg, 14, 0),
    tagPictureUri: jspb.Message.getFieldWithDefault(msg, 15, ""),
    visibleDamages: jspb.Message.getBooleanFieldWithDefault(msg, 16, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.Infleeting}
 */
proto.vehicle.Infleeting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.Infleeting;
  return proto.vehicle.Infleeting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.Infleeting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.Infleeting}
 */
proto.vehicle.Infleeting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartDate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompletionDate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStep(value);
      break;
    case 7:
      var value = /** @type {!proto.vehicle.Infleeting.InfleetingStatus} */ (reader.readEnum());
      msg.setInfleetingStatus(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRegistration(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setKeyCards(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCharger(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMats(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPlateMounting(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addAdapters(value);
      break;
    case 14:
      var value = /** @type {!proto.vehicle.VehicleTagType} */ (reader.readEnum());
      msg.setTagType(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagPictureUri(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisibleDamages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.Infleeting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.Infleeting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.Infleeting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Infleeting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCompletionDate();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getStep();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getInfleetingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getRegistration();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getKeyCards();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getCharger();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getMats();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getPlateMounting();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getAdaptersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
  f = message.getTagType();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getTagPictureUri();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getVisibleDamages();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vehicle.Infleeting.InfleetingStatus = {
  IN_WORK: 0,
  COMPLETE: 1
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.vehicle.Infleeting.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Infleeting} returns this
 */
proto.vehicle.Infleeting.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.vehicle.Infleeting.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Infleeting} returns this
 */
proto.vehicle.Infleeting.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.vehicle.Infleeting.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Infleeting} returns this
 */
proto.vehicle.Infleeting.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 start_date = 4;
 * @return {number}
 */
proto.vehicle.Infleeting.prototype.getStartDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Infleeting} returns this
 */
proto.vehicle.Infleeting.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 completion_date = 5;
 * @return {number}
 */
proto.vehicle.Infleeting.prototype.getCompletionDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Infleeting} returns this
 */
proto.vehicle.Infleeting.prototype.setCompletionDate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 step = 6;
 * @return {number}
 */
proto.vehicle.Infleeting.prototype.getStep = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle.Infleeting} returns this
 */
proto.vehicle.Infleeting.prototype.setStep = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional InfleetingStatus infleeting_status = 7;
 * @return {!proto.vehicle.Infleeting.InfleetingStatus}
 */
proto.vehicle.Infleeting.prototype.getInfleetingStatus = function() {
  return /** @type {!proto.vehicle.Infleeting.InfleetingStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.vehicle.Infleeting.InfleetingStatus} value
 * @return {!proto.vehicle.Infleeting} returns this
 */
proto.vehicle.Infleeting.prototype.setInfleetingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool registration = 8;
 * @return {boolean}
 */
proto.vehicle.Infleeting.prototype.getRegistration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vehicle.Infleeting} returns this
 */
proto.vehicle.Infleeting.prototype.setRegistration = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool key_cards = 9;
 * @return {boolean}
 */
proto.vehicle.Infleeting.prototype.getKeyCards = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vehicle.Infleeting} returns this
 */
proto.vehicle.Infleeting.prototype.setKeyCards = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool charger = 10;
 * @return {boolean}
 */
proto.vehicle.Infleeting.prototype.getCharger = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vehicle.Infleeting} returns this
 */
proto.vehicle.Infleeting.prototype.setCharger = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool mats = 11;
 * @return {boolean}
 */
proto.vehicle.Infleeting.prototype.getMats = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vehicle.Infleeting} returns this
 */
proto.vehicle.Infleeting.prototype.setMats = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool plate_mounting = 12;
 * @return {boolean}
 */
proto.vehicle.Infleeting.prototype.getPlateMounting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vehicle.Infleeting} returns this
 */
proto.vehicle.Infleeting.prototype.setPlateMounting = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * repeated string adapters = 13;
 * @return {!Array<string>}
 */
proto.vehicle.Infleeting.prototype.getAdaptersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vehicle.Infleeting} returns this
 */
proto.vehicle.Infleeting.prototype.setAdaptersList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Infleeting} returns this
 */
proto.vehicle.Infleeting.prototype.addAdapters = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle.Infleeting} returns this
 */
proto.vehicle.Infleeting.prototype.clearAdaptersList = function() {
  return this.setAdaptersList([]);
};


/**
 * optional VehicleTagType tag_type = 14;
 * @return {!proto.vehicle.VehicleTagType}
 */
proto.vehicle.Infleeting.prototype.getTagType = function() {
  return /** @type {!proto.vehicle.VehicleTagType} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.vehicle.VehicleTagType} value
 * @return {!proto.vehicle.Infleeting} returns this
 */
proto.vehicle.Infleeting.prototype.setTagType = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional string tag_picture_uri = 15;
 * @return {string}
 */
proto.vehicle.Infleeting.prototype.getTagPictureUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.Infleeting} returns this
 */
proto.vehicle.Infleeting.prototype.setTagPictureUri = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional bool visible_damages = 16;
 * @return {boolean}
 */
proto.vehicle.Infleeting.prototype.getVisibleDamages = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vehicle.Infleeting} returns this
 */
proto.vehicle.Infleeting.prototype.setVisibleDamages = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.VehicleInfleetingUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.VehicleInfleetingUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.VehicleInfleetingUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.VehicleInfleetingUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    infleeting: (f = msg.getInfleeting()) && proto.vehicle.Infleeting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.VehicleInfleetingUpdated}
 */
proto.vehicle.VehicleInfleetingUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.VehicleInfleetingUpdated;
  return proto.vehicle.VehicleInfleetingUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.VehicleInfleetingUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.VehicleInfleetingUpdated}
 */
proto.vehicle.VehicleInfleetingUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = new proto.vehicle.Infleeting;
      reader.readMessage(value,proto.vehicle.Infleeting.deserializeBinaryFromReader);
      msg.setInfleeting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.VehicleInfleetingUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.VehicleInfleetingUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.VehicleInfleetingUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.VehicleInfleetingUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInfleeting();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vehicle.Infleeting.serializeBinaryToWriter
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.vehicle.VehicleInfleetingUpdated.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.VehicleInfleetingUpdated} returns this
 */
proto.vehicle.VehicleInfleetingUpdated.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Infleeting infleeting = 2;
 * @return {?proto.vehicle.Infleeting}
 */
proto.vehicle.VehicleInfleetingUpdated.prototype.getInfleeting = function() {
  return /** @type{?proto.vehicle.Infleeting} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.Infleeting, 2));
};


/**
 * @param {?proto.vehicle.Infleeting|undefined} value
 * @return {!proto.vehicle.VehicleInfleetingUpdated} returns this
*/
proto.vehicle.VehicleInfleetingUpdated.prototype.setInfleeting = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.VehicleInfleetingUpdated} returns this
 */
proto.vehicle.VehicleInfleetingUpdated.prototype.clearInfleeting = function() {
  return this.setInfleeting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.VehicleInfleetingUpdated.prototype.hasInfleeting = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.InfleetingVehicle.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.InfleetingVehicle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.InfleetingVehicle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.InfleetingVehicle.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && proto.vehicle.Vehicle.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.InfleetingVehicle}
 */
proto.vehicle.InfleetingVehicle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.InfleetingVehicle;
  return proto.vehicle.InfleetingVehicle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.InfleetingVehicle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.InfleetingVehicle}
 */
proto.vehicle.InfleetingVehicle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vehicle.Vehicle;
      reader.readMessage(value,proto.vehicle.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.InfleetingVehicle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.InfleetingVehicle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.InfleetingVehicle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.InfleetingVehicle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vehicle.Vehicle.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Vehicle vehicle = 1;
 * @return {?proto.vehicle.Vehicle}
 */
proto.vehicle.InfleetingVehicle.prototype.getVehicle = function() {
  return /** @type{?proto.vehicle.Vehicle} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.Vehicle, 1));
};


/**
 * @param {?proto.vehicle.Vehicle|undefined} value
 * @return {!proto.vehicle.InfleetingVehicle} returns this
*/
proto.vehicle.InfleetingVehicle.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.InfleetingVehicle} returns this
 */
proto.vehicle.InfleetingVehicle.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.InfleetingVehicle.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.vehicle.InfleetingVehicle.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle.InfleetingVehicle} returns this
 */
proto.vehicle.InfleetingVehicle.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.vehicle.Document.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11,12]];

/**
 * @enum {number}
 */
proto.vehicle.Document.TypeCase = {
  TYPE_NOT_SET: 0,
  REG262: 1,
  PURCHASE_ORDER: 2,
  REG51: 3,
  REG553: 4,
  BUYERS_GUIDE: 5,
  USED_VEHICLE_HISTORY_DISCLOSURE: 6,
  TOS: 7,
  INSPECTIONS: 8,
  REGISTRATIONS: 9,
  TITLES: 10,
  WARRANTIES: 11,
  MAINTENANCES: 12
};

/**
 * @return {proto.vehicle.Document.TypeCase}
 */
proto.vehicle.Document.prototype.getTypeCase = function() {
  return /** @type {proto.vehicle.Document.TypeCase} */(jspb.Message.computeOneofCase(this, proto.vehicle.Document.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle.Document.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle.Document.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle.Document} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Document.toObject = function(includeInstance, msg) {
  var f, obj = {
    reg262: (f = msg.getReg262()) && proto.vehicle.Reg262.toObject(includeInstance, f),
    purchaseOrder: (f = msg.getPurchaseOrder()) && proto.vehicle.PurchaseOrder.toObject(includeInstance, f),
    reg51: (f = msg.getReg51()) && proto.vehicle.Reg51.toObject(includeInstance, f),
    reg553: (f = msg.getReg553()) && proto.vehicle.Reg553.toObject(includeInstance, f),
    buyersGuide: (f = msg.getBuyersGuide()) && proto.vehicle.BuyersGuide.toObject(includeInstance, f),
    usedVehicleHistoryDisclosure: (f = msg.getUsedVehicleHistoryDisclosure()) && proto.vehicle.UsedVehicleHistoryDisclosure.toObject(includeInstance, f),
    tos: (f = msg.getTos()) && proto.vehicle.VehicleTermsOfService.toObject(includeInstance, f),
    inspections: (f = msg.getInspections()) && proto.vehicle.Inspection.toObject(includeInstance, f),
    registrations: (f = msg.getRegistrations()) && proto.vehicle.Registration.toObject(includeInstance, f),
    titles: (f = msg.getTitles()) && proto.vehicle.Title.toObject(includeInstance, f),
    warranties: (f = msg.getWarranties()) && proto.vehicle.Warranty.toObject(includeInstance, f),
    maintenances: (f = msg.getMaintenances()) && proto.vehicle.Maintenance.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle.Document}
 */
proto.vehicle.Document.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle.Document;
  return proto.vehicle.Document.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle.Document} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle.Document}
 */
proto.vehicle.Document.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vehicle.Reg262;
      reader.readMessage(value,proto.vehicle.Reg262.deserializeBinaryFromReader);
      msg.setReg262(value);
      break;
    case 2:
      var value = new proto.vehicle.PurchaseOrder;
      reader.readMessage(value,proto.vehicle.PurchaseOrder.deserializeBinaryFromReader);
      msg.setPurchaseOrder(value);
      break;
    case 3:
      var value = new proto.vehicle.Reg51;
      reader.readMessage(value,proto.vehicle.Reg51.deserializeBinaryFromReader);
      msg.setReg51(value);
      break;
    case 4:
      var value = new proto.vehicle.Reg553;
      reader.readMessage(value,proto.vehicle.Reg553.deserializeBinaryFromReader);
      msg.setReg553(value);
      break;
    case 5:
      var value = new proto.vehicle.BuyersGuide;
      reader.readMessage(value,proto.vehicle.BuyersGuide.deserializeBinaryFromReader);
      msg.setBuyersGuide(value);
      break;
    case 6:
      var value = new proto.vehicle.UsedVehicleHistoryDisclosure;
      reader.readMessage(value,proto.vehicle.UsedVehicleHistoryDisclosure.deserializeBinaryFromReader);
      msg.setUsedVehicleHistoryDisclosure(value);
      break;
    case 7:
      var value = new proto.vehicle.VehicleTermsOfService;
      reader.readMessage(value,proto.vehicle.VehicleTermsOfService.deserializeBinaryFromReader);
      msg.setTos(value);
      break;
    case 8:
      var value = new proto.vehicle.Inspection;
      reader.readMessage(value,proto.vehicle.Inspection.deserializeBinaryFromReader);
      msg.setInspections(value);
      break;
    case 9:
      var value = new proto.vehicle.Registration;
      reader.readMessage(value,proto.vehicle.Registration.deserializeBinaryFromReader);
      msg.setRegistrations(value);
      break;
    case 10:
      var value = new proto.vehicle.Title;
      reader.readMessage(value,proto.vehicle.Title.deserializeBinaryFromReader);
      msg.setTitles(value);
      break;
    case 11:
      var value = new proto.vehicle.Warranty;
      reader.readMessage(value,proto.vehicle.Warranty.deserializeBinaryFromReader);
      msg.setWarranties(value);
      break;
    case 12:
      var value = new proto.vehicle.Maintenance;
      reader.readMessage(value,proto.vehicle.Maintenance.deserializeBinaryFromReader);
      msg.setMaintenances(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle.Document.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle.Document.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle.Document} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle.Document.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReg262();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vehicle.Reg262.serializeBinaryToWriter
    );
  }
  f = message.getPurchaseOrder();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vehicle.PurchaseOrder.serializeBinaryToWriter
    );
  }
  f = message.getReg51();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vehicle.Reg51.serializeBinaryToWriter
    );
  }
  f = message.getReg553();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vehicle.Reg553.serializeBinaryToWriter
    );
  }
  f = message.getBuyersGuide();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.vehicle.BuyersGuide.serializeBinaryToWriter
    );
  }
  f = message.getUsedVehicleHistoryDisclosure();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.vehicle.UsedVehicleHistoryDisclosure.serializeBinaryToWriter
    );
  }
  f = message.getTos();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.vehicle.VehicleTermsOfService.serializeBinaryToWriter
    );
  }
  f = message.getInspections();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.vehicle.Inspection.serializeBinaryToWriter
    );
  }
  f = message.getRegistrations();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.vehicle.Registration.serializeBinaryToWriter
    );
  }
  f = message.getTitles();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.vehicle.Title.serializeBinaryToWriter
    );
  }
  f = message.getWarranties();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.vehicle.Warranty.serializeBinaryToWriter
    );
  }
  f = message.getMaintenances();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.vehicle.Maintenance.serializeBinaryToWriter
    );
  }
};


/**
 * optional Reg262 reg262 = 1;
 * @return {?proto.vehicle.Reg262}
 */
proto.vehicle.Document.prototype.getReg262 = function() {
  return /** @type{?proto.vehicle.Reg262} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.Reg262, 1));
};


/**
 * @param {?proto.vehicle.Reg262|undefined} value
 * @return {!proto.vehicle.Document} returns this
*/
proto.vehicle.Document.prototype.setReg262 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.vehicle.Document.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.Document} returns this
 */
proto.vehicle.Document.prototype.clearReg262 = function() {
  return this.setReg262(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Document.prototype.hasReg262 = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PurchaseOrder purchase_order = 2;
 * @return {?proto.vehicle.PurchaseOrder}
 */
proto.vehicle.Document.prototype.getPurchaseOrder = function() {
  return /** @type{?proto.vehicle.PurchaseOrder} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.PurchaseOrder, 2));
};


/**
 * @param {?proto.vehicle.PurchaseOrder|undefined} value
 * @return {!proto.vehicle.Document} returns this
*/
proto.vehicle.Document.prototype.setPurchaseOrder = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.vehicle.Document.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.Document} returns this
 */
proto.vehicle.Document.prototype.clearPurchaseOrder = function() {
  return this.setPurchaseOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Document.prototype.hasPurchaseOrder = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Reg51 reg51 = 3;
 * @return {?proto.vehicle.Reg51}
 */
proto.vehicle.Document.prototype.getReg51 = function() {
  return /** @type{?proto.vehicle.Reg51} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.Reg51, 3));
};


/**
 * @param {?proto.vehicle.Reg51|undefined} value
 * @return {!proto.vehicle.Document} returns this
*/
proto.vehicle.Document.prototype.setReg51 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.vehicle.Document.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.Document} returns this
 */
proto.vehicle.Document.prototype.clearReg51 = function() {
  return this.setReg51(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Document.prototype.hasReg51 = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Reg553 reg553 = 4;
 * @return {?proto.vehicle.Reg553}
 */
proto.vehicle.Document.prototype.getReg553 = function() {
  return /** @type{?proto.vehicle.Reg553} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.Reg553, 4));
};


/**
 * @param {?proto.vehicle.Reg553|undefined} value
 * @return {!proto.vehicle.Document} returns this
*/
proto.vehicle.Document.prototype.setReg553 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.vehicle.Document.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.Document} returns this
 */
proto.vehicle.Document.prototype.clearReg553 = function() {
  return this.setReg553(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Document.prototype.hasReg553 = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional BuyersGuide buyers_guide = 5;
 * @return {?proto.vehicle.BuyersGuide}
 */
proto.vehicle.Document.prototype.getBuyersGuide = function() {
  return /** @type{?proto.vehicle.BuyersGuide} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.BuyersGuide, 5));
};


/**
 * @param {?proto.vehicle.BuyersGuide|undefined} value
 * @return {!proto.vehicle.Document} returns this
*/
proto.vehicle.Document.prototype.setBuyersGuide = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.vehicle.Document.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.Document} returns this
 */
proto.vehicle.Document.prototype.clearBuyersGuide = function() {
  return this.setBuyersGuide(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Document.prototype.hasBuyersGuide = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional UsedVehicleHistoryDisclosure used_vehicle_history_disclosure = 6;
 * @return {?proto.vehicle.UsedVehicleHistoryDisclosure}
 */
proto.vehicle.Document.prototype.getUsedVehicleHistoryDisclosure = function() {
  return /** @type{?proto.vehicle.UsedVehicleHistoryDisclosure} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.UsedVehicleHistoryDisclosure, 6));
};


/**
 * @param {?proto.vehicle.UsedVehicleHistoryDisclosure|undefined} value
 * @return {!proto.vehicle.Document} returns this
*/
proto.vehicle.Document.prototype.setUsedVehicleHistoryDisclosure = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.vehicle.Document.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.Document} returns this
 */
proto.vehicle.Document.prototype.clearUsedVehicleHistoryDisclosure = function() {
  return this.setUsedVehicleHistoryDisclosure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Document.prototype.hasUsedVehicleHistoryDisclosure = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional VehicleTermsOfService tos = 7;
 * @return {?proto.vehicle.VehicleTermsOfService}
 */
proto.vehicle.Document.prototype.getTos = function() {
  return /** @type{?proto.vehicle.VehicleTermsOfService} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.VehicleTermsOfService, 7));
};


/**
 * @param {?proto.vehicle.VehicleTermsOfService|undefined} value
 * @return {!proto.vehicle.Document} returns this
*/
proto.vehicle.Document.prototype.setTos = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.vehicle.Document.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.Document} returns this
 */
proto.vehicle.Document.prototype.clearTos = function() {
  return this.setTos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Document.prototype.hasTos = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Inspection inspections = 8;
 * @return {?proto.vehicle.Inspection}
 */
proto.vehicle.Document.prototype.getInspections = function() {
  return /** @type{?proto.vehicle.Inspection} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.Inspection, 8));
};


/**
 * @param {?proto.vehicle.Inspection|undefined} value
 * @return {!proto.vehicle.Document} returns this
*/
proto.vehicle.Document.prototype.setInspections = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.vehicle.Document.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.Document} returns this
 */
proto.vehicle.Document.prototype.clearInspections = function() {
  return this.setInspections(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Document.prototype.hasInspections = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Registration registrations = 9;
 * @return {?proto.vehicle.Registration}
 */
proto.vehicle.Document.prototype.getRegistrations = function() {
  return /** @type{?proto.vehicle.Registration} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.Registration, 9));
};


/**
 * @param {?proto.vehicle.Registration|undefined} value
 * @return {!proto.vehicle.Document} returns this
*/
proto.vehicle.Document.prototype.setRegistrations = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.vehicle.Document.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.Document} returns this
 */
proto.vehicle.Document.prototype.clearRegistrations = function() {
  return this.setRegistrations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Document.prototype.hasRegistrations = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Title titles = 10;
 * @return {?proto.vehicle.Title}
 */
proto.vehicle.Document.prototype.getTitles = function() {
  return /** @type{?proto.vehicle.Title} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.Title, 10));
};


/**
 * @param {?proto.vehicle.Title|undefined} value
 * @return {!proto.vehicle.Document} returns this
*/
proto.vehicle.Document.prototype.setTitles = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.vehicle.Document.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.Document} returns this
 */
proto.vehicle.Document.prototype.clearTitles = function() {
  return this.setTitles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Document.prototype.hasTitles = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Warranty warranties = 11;
 * @return {?proto.vehicle.Warranty}
 */
proto.vehicle.Document.prototype.getWarranties = function() {
  return /** @type{?proto.vehicle.Warranty} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.Warranty, 11));
};


/**
 * @param {?proto.vehicle.Warranty|undefined} value
 * @return {!proto.vehicle.Document} returns this
*/
proto.vehicle.Document.prototype.setWarranties = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.vehicle.Document.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.Document} returns this
 */
proto.vehicle.Document.prototype.clearWarranties = function() {
  return this.setWarranties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Document.prototype.hasWarranties = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional Maintenance maintenances = 12;
 * @return {?proto.vehicle.Maintenance}
 */
proto.vehicle.Document.prototype.getMaintenances = function() {
  return /** @type{?proto.vehicle.Maintenance} */ (
    jspb.Message.getWrapperField(this, proto.vehicle.Maintenance, 12));
};


/**
 * @param {?proto.vehicle.Maintenance|undefined} value
 * @return {!proto.vehicle.Document} returns this
*/
proto.vehicle.Document.prototype.setMaintenances = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.vehicle.Document.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle.Document} returns this
 */
proto.vehicle.Document.prototype.clearMaintenances = function() {
  return this.setMaintenances(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle.Document.prototype.hasMaintenances = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * @enum {number}
 */
proto.vehicle.CustodianType = {
  CUSTODIAN_TYPE_UNSPECIFIED: 0,
  CUSTODIAN_TYPE_UNKNOWN: 1,
  CUSTODIAN_TYPE_OEM: 2,
  CUSTODIAN_TYPE_DEALER: 3,
  CUSTODIAN_TYPE_CUSTOMER: 4
};

/**
 * @enum {number}
 */
proto.vehicle.State = {
  IN_PROCESS: 0,
  IN_LISTING: 1,
  IN_ORDER: 2,
  ON_SUBSCRIPTION: 3,
  CHARGE_OFF_SKIP: 4,
  PENDING_RETURN: 5,
  RETURN_SCHEDULED: 6,
  RETURN_CANCELED: 7,
  OFF_SUBSCRIPTION: 8,
  VEHICLE_RECYCLED: 9,
  VEHICLE_SOLD: 10,
  REPO_ELIGIBLE: 11,
  OUT_FOR_REPO: 12,
  CHARGE_OFF_ELIGIBLE: 13,
  SOLD: 14,
  REPO: 15,
  RETURNED: 16,
  OUT_OF_SERVICE: 17,
  TOTAL_LOSS: 18
};

/**
 * @enum {number}
 */
proto.vehicle.ActivityFailure = {
  NONE: 0,
  UNAVAILABLE: 1,
  REJECT_SELLING_PRICE: 2,
  NOT_QUALIFIED: 3,
  INSPECTION_FAILURE: 4,
  CONTRACT_FAILURE: 5,
  VEHICLE_TRANSFER_FAILURE: 6,
  UPLOAD_FAILURE: 7,
  CUSTOM_INSURANCE_FAILURE: 8,
  TELEMATICS_FAILURE: 9,
  REGISTRATION_UPDATE_FAILURE: 10,
  UNWIND_FAILURE: 11,
  RETURN_NOT_SCHEDULED: 12,
  UNWIND_INELIGIBLE: 13,
  NO_LONGER_ELIGIBLE: 14,
  FRAUDULENT_PHONE: 15,
  RETURN_DATE_NOT_CONFIRMED: 16,
  RECYCLE_INELIGIBLE: 17,
  RETURN_CANCEL_FAILED: 18,
  FINAL_INSPECTION_FAILED: 19,
  ADDITIONAL_MILAGE_CHARGE_FAILED: 20,
  FINAL_BILL_ERROR: 21,
  UNABLE_TO_SUBMIT_TO_AUCTION: 22,
  REPAIRS_DENIED: 23,
  RECONDITIONING_CANCELED: 24,
  BUYER_DOCUMENT_FAILED: 25,
  PAYMENT_FAILED: 26
};

/**
 * @enum {number}
 */
proto.vehicle.Activity = {
  AVAILABILITY_CHECK: 0
};

/**
 * @enum {number}
 */
proto.vehicle.ActivityState = {
  AVAILABLE: 0,
  AGREED_SELLING_PRICE: 1,
  QUALIFIED: 2,
  REPRICE: 3,
  INSPECTION_IN_PROGRESS: 4,
  INSPECTION_COMPLETE: 5,
  CONTRACTS_SIGNED: 6,
  VEHICLE_IN_FLEET: 7,
  UPLOAD_REGISTRATION: 8,
  UPLOAD_TITLE: 9,
  INPUT_REGISTRATION_EXPIRATION: 10,
  ADD_INSURANCE: 11,
  VERIFY_CUSTOM_INSURANCE: 12,
  EMAIL_ENTERED: 13,
  ELIGIBLE_USER: 14,
  ACTIVATE_TELEMATICS: 15,
  VIEW_LOCATION: 16,
  MONTH_ADDED: 17,
  YEAR_ADDED: 18,
  REGISTRATION_PENDING: 19,
  REGISTRATION_RECEIVED: 20,
  REGISTRATION_NOT_REGISTERED: 21,
  SUBSCRIPTION_UNWOUND: 22,
  UNWIND_ELIGIBLE: 23,
  REPO_PENDING: 24,
  RETURN_DATE_SELECTED: 25,
  RETURN_DATE_CONFIRMED: 26,
  ELIGIBLE_FOR_FLEET: 27,
  RESCHEDULE_RETURN: 28,
  RECYCLE_ELIGIBLE: 29,
  RETURN_CANCEL_REQUESTED: 30,
  RETURN_CANCEL_CONFIRMED: 31,
  PENDING_FINAL_INSPECTION: 32,
  FINAL_INSPECTION_COMPLETE: 33,
  EXCESS_WEAR_CHARGE_SUBMITTED: 34,
  ENDING_MILAGE_SUBMITTED: 35,
  FINAL_BILL_SENT: 36,
  FINAL_BILL_PAID: 37,
  SUBMIT_TO_AUCTION: 38,
  VENDOR_ASSIGNED: 39,
  RECONDITIONING_IN_PROGRESS: 40,
  REPAIRS_APPROVED: 41,
  REPAIRS_COMPLETE: 42,
  RELOCATION_NEEDED: 43,
  RELOCATION_NOT_NEEDED: 44,
  IN_APP: 45,
  SUBMITTED_TO_AUCTION: 46,
  AUCTION_LISTED: 47,
  BUYER_CONFIRMED: 48,
  BUYER_DOCUMENTS_RECEIVED: 49,
  PAYMENT_PROCESSING: 50,
  PAYMENT_RECEIVED: 51
};

/**
 * @enum {number}
 */
proto.vehicle.Model = {
  TESLA_MODEL_3: 0,
  TESLA_MODEL_Y: 1
};

/**
 * @enum {number}
 */
proto.vehicle.Priority = {
  PRIMARY: 0,
  SECONDARY: 1
};

/**
 * @enum {number}
 */
proto.vehicle.VehicleTagType = {
  TEMP: 0,
  PERMANENT: 1
};

goog.object.extend(exports, proto.vehicle);
