import { find } from 'lodash'
import {useContext, useEffect, useState} from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { NexusService } from 'global-apis/nexus-service'
import { getPricingOptionsForProgram } from '../ProgramPricingBlock/useProgramPricingBlock'
import { Reservation } from '@nxcr-org/web-api-interface/lib/billing_service_pb'
import {ClientContext} from '../../../../../../utils/clientContext'

export function useChangeProgramDialog({
  programId,
  onClose,
  reservation: {
    color,
    secondaryColor,
    reservationNumber,
    id: reservationId,
    startFee,
    monthlyRent,
  },
  postal,
}: {
  customerId: string
  programId: string
  onClose: () => void
  reservation: Reservation.AsObject
  postal: string
}) {
  const DEFAULT_PROGRAM_ID = '8c91f7f3-84db-4ee9-99af-4e7af3a09b6f'
  const queryClient = useQueryClient()
  const [program, setSelectedProgram] = useState(null)
  const [pricingId, setPricingId] = useState<string>('')
  const { clientId } = useContext(ClientContext)

  const {
    data: programOptions,
    error: programOptionsError,
    isLoading,
  } = useQuery(
    ['/programOptions'],
    () => {
      return NexusService.listProgramPricing(clientId)
    },
    {
      placeholderData: {
        programsList: [],
      },
      onError() {
        console.log('Error: ', programOptionsError)
      },
    }
  )

  const setProgram = (newProgramId: string) => {
    const newProgram = find(programOptions.programsList, {
      id: newProgramId,
    })

    setSelectedProgram(newProgram)
    setPricingId(newProgram.pricingOptionsList?.[0]?.id)
  }

  const { mutate: saveProgramSelection } = useMutation(
    () => {
      const pricingOption = find(
        getPricingOptionsForProgram(programOptions?.programsList, program.id),
        {
          id: pricingId,
        }
      )

      return NexusService.UpdateProgramReservation({
        reservationId,
        deposit: pricingOption.deposit,
        startFee: pricingOption.startFee,
        monthlyRent: pricingOption.monthlyRent,
        color: color,
        secondaryColor: secondaryColor,
        make: program.make,
        model: program.model,
        postal,
        trackingId: reservationNumber,
        programId: program.id,
      })
        .then((res) => {
          console.log('update program reservation', res)

          onClose()
        })
        .catch(onClose)
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['/reservation', reservationId])
      },
    }
  )

  // TEMP - needed since program options might be cached initially
  useEffect(() => {
    if (programOptions) {
      const selectedProgram = find(programOptions.programsList, {
        id: program?.id || programId || DEFAULT_PROGRAM_ID,
      })
      const pricingList = selectedProgram?.pricingOptionsList[0]

      const findPricing = find(pricingList?.pricingOptionsList, {
        monthlyRent: monthlyRent,
        startFee: startFee,
      })
      if (findPricing) {
        setPricingId(findPricing.id)
      }
      setSelectedProgram(selectedProgram)
    }
  }, [programOptions])

  return {
    programs: programOptions,
    pricingId,
    isLoading,
    program,
    setProgram,
    setPricingId,
    saveProgramSelection,
    programPricingOptions: getPricingOptionsForProgram(
      programOptions?.programsList,
      program?.id || programId || DEFAULT_PROGRAM_ID
    ),
    DEFAULT_PROGRAM_ID,
  }
}
