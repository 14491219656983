// source: fleet_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var vehicle_pb = require('./vehicle_pb.js');
goog.object.extend(proto, vehicle_pb);
var domain_pb = require('./domain_pb.js');
goog.object.extend(proto, domain_pb);
var events_pb = require('./events_pb.js');
goog.object.extend(proto, events_pb);
var inspection_pb = require('./inspection_pb.js');
goog.object.extend(proto, inspection_pb);
var dealer_pb = require('./dealer_pb.js');
goog.object.extend(proto, dealer_pb);
goog.exportSymbol('proto.fleet_service.AddInspectionPhotosRequest', null, global);
goog.exportSymbol('proto.fleet_service.AddInspectionPhotosResponse', null, global);
goog.exportSymbol('proto.fleet_service.AddVehicleRequest', null, global);
goog.exportSymbol('proto.fleet_service.AddVehicleResponse', null, global);
goog.exportSymbol('proto.fleet_service.AssignVehicleRequest', null, global);
goog.exportSymbol('proto.fleet_service.AssignVehicleResponse', null, global);
goog.exportSymbol('proto.fleet_service.ConfirmVehicleRequest', null, global);
goog.exportSymbol('proto.fleet_service.ConfirmVehicleRequest.ColorName', null, global);
goog.exportSymbol('proto.fleet_service.ConfirmVehicleRequest.ColorOptions', null, global);
goog.exportSymbol('proto.fleet_service.ConfirmVehicleRequest.Type', null, global);
goog.exportSymbol('proto.fleet_service.ConfirmVehicleRequest.VehicleModel', null, global);
goog.exportSymbol('proto.fleet_service.ConfirmVehicleResponse', null, global);
goog.exportSymbol('proto.fleet_service.ConfirmVehicleResponse.ColorAssigned', null, global);
goog.exportSymbol('proto.fleet_service.CreateInspectionReportRequest', null, global);
goog.exportSymbol('proto.fleet_service.CreateInspectionReportResponse', null, global);
goog.exportSymbol('proto.fleet_service.DealershipCriteria', null, global);
goog.exportSymbol('proto.fleet_service.DeleteDocumentRequest', null, global);
goog.exportSymbol('proto.fleet_service.DeleteDocumentResponse', null, global);
goog.exportSymbol('proto.fleet_service.DeleteMilesPurchasedLogRequest', null, global);
goog.exportSymbol('proto.fleet_service.DeleteMilesPurchasedLogResponse', null, global);
goog.exportSymbol('proto.fleet_service.GetAvailableModelsRequest', null, global);
goog.exportSymbol('proto.fleet_service.GetAvailableModelsResponse', null, global);
goog.exportSymbol('proto.fleet_service.GetAvailableTrimsRequest', null, global);
goog.exportSymbol('proto.fleet_service.GetAvailableTrimsResponse', null, global);
goog.exportSymbol('proto.fleet_service.GetDealershipRequest', null, global);
goog.exportSymbol('proto.fleet_service.GetDealershipResponse', null, global);
goog.exportSymbol('proto.fleet_service.GetInspectionPhotosRequest', null, global);
goog.exportSymbol('proto.fleet_service.GetInspectionPhotosResponse', null, global);
goog.exportSymbol('proto.fleet_service.GetInspectionSignatureWriteURLRequest', null, global);
goog.exportSymbol('proto.fleet_service.GetInspectionSignatureWriteURLResponse', null, global);
goog.exportSymbol('proto.fleet_service.GetLatestCustodyRequest', null, global);
goog.exportSymbol('proto.fleet_service.GetLatestCustodyRequest.CustodyType', null, global);
goog.exportSymbol('proto.fleet_service.GetLatestCustodyResponse', null, global);
goog.exportSymbol('proto.fleet_service.GetLatestCustodyResponse.CustodianCase', null, global);
goog.exportSymbol('proto.fleet_service.GetMileageRatesRequest', null, global);
goog.exportSymbol('proto.fleet_service.GetMileageRatesResponse', null, global);
goog.exportSymbol('proto.fleet_service.GetOwnBuyItNowDetailsRequest', null, global);
goog.exportSymbol('proto.fleet_service.GetOwnBuyItNowDetailsResponse', null, global);
goog.exportSymbol('proto.fleet_service.GetPrePaidMileageRateRequest', null, global);
goog.exportSymbol('proto.fleet_service.GetPrePaidMileageRateResponse', null, global);
goog.exportSymbol('proto.fleet_service.GetPresignedWritePhotoURLRequest', null, global);
goog.exportSymbol('proto.fleet_service.GetPresignedWritePhotoURLResponse', null, global);
goog.exportSymbol('proto.fleet_service.GetSubscriptionMileagePurchasesRequest', null, global);
goog.exportSymbol('proto.fleet_service.GetSubscriptionMileagePurchasesResponse', null, global);
goog.exportSymbol('proto.fleet_service.GetSubscriptionMileageRequest', null, global);
goog.exportSymbol('proto.fleet_service.GetSubscriptionMileageResponse', null, global);
goog.exportSymbol('proto.fleet_service.GetVehicleByIdRequest', null, global);
goog.exportSymbol('proto.fleet_service.GetVehicleByIdResponse', null, global);
goog.exportSymbol('proto.fleet_service.GetVehicleByVinRequest', null, global);
goog.exportSymbol('proto.fleet_service.GetVehicleByVinResponse', null, global);
goog.exportSymbol('proto.fleet_service.GetVehicleInfleetingRequest', null, global);
goog.exportSymbol('proto.fleet_service.GetVehicleInfleetingRequest.InfleetingRequestType', null, global);
goog.exportSymbol('proto.fleet_service.GetVehicleInfleetingResponse', null, global);
goog.exportSymbol('proto.fleet_service.GetVehicleInspectionResultsRequest', null, global);
goog.exportSymbol('proto.fleet_service.GetVehicleRequest', null, global);
goog.exportSymbol('proto.fleet_service.GetVehicleResponse', null, global);
goog.exportSymbol('proto.fleet_service.HandleTelemetricEventResponse', null, global);
goog.exportSymbol('proto.fleet_service.InfleetVehicleRequest', null, global);
goog.exportSymbol('proto.fleet_service.IngestVehiclesRequest', null, global);
goog.exportSymbol('proto.fleet_service.IngestVehiclesResponse', null, global);
goog.exportSymbol('proto.fleet_service.InitiateVehicleReturnRequest', null, global);
goog.exportSymbol('proto.fleet_service.InitiateVehicleReturnResponse', null, global);
goog.exportSymbol('proto.fleet_service.InspectionPhoto', null, global);
goog.exportSymbol('proto.fleet_service.InspectionReport', null, global);
goog.exportSymbol('proto.fleet_service.ListDealershipsRequest', null, global);
goog.exportSymbol('proto.fleet_service.ListDealershipsResponse', null, global);
goog.exportSymbol('proto.fleet_service.ListInspectionReportsQuery', null, global);
goog.exportSymbol('proto.fleet_service.ListInspectionReportsRequest', null, global);
goog.exportSymbol('proto.fleet_service.ListInspectionReportsResponse', null, global);
goog.exportSymbol('proto.fleet_service.ListInspectionReportsToken', null, global);
goog.exportSymbol('proto.fleet_service.ListOrdersRequest', null, global);
goog.exportSymbol('proto.fleet_service.ListOrdersRequest.OrderState', null, global);
goog.exportSymbol('proto.fleet_service.ListOrdersResponse', null, global);
goog.exportSymbol('proto.fleet_service.ListProfilesRequest', null, global);
goog.exportSymbol('proto.fleet_service.ListProfilesResponse', null, global);
goog.exportSymbol('proto.fleet_service.ListVehiclePickupsQuery', null, global);
goog.exportSymbol('proto.fleet_service.ListVehiclePickupsRequest', null, global);
goog.exportSymbol('proto.fleet_service.ListVehiclePickupsResponse', null, global);
goog.exportSymbol('proto.fleet_service.ListVehiclePickupsToken', null, global);
goog.exportSymbol('proto.fleet_service.ListVehiclesRequest', null, global);
goog.exportSymbol('proto.fleet_service.ListVehiclesResponse', null, global);
goog.exportSymbol('proto.fleet_service.LogMilesPurchasedRequest', null, global);
goog.exportSymbol('proto.fleet_service.LogMilesPurchasedResponse', null, global);
goog.exportSymbol('proto.fleet_service.MatsStatus', null, global);
goog.exportSymbol('proto.fleet_service.MileageDrivenInMonth', null, global);
goog.exportSymbol('proto.fleet_service.MileagePurchase', null, global);
goog.exportSymbol('proto.fleet_service.NexusVehicle', null, global);
goog.exportSymbol('proto.fleet_service.NexusVehicle.Status', null, global);
goog.exportSymbol('proto.fleet_service.OrderCriteria', null, global);
goog.exportSymbol('proto.fleet_service.OrderCriteria.Direction', null, global);
goog.exportSymbol('proto.fleet_service.PhotoArea', null, global);
goog.exportSymbol('proto.fleet_service.PickupDetails', null, global);
goog.exportSymbol('proto.fleet_service.PrepaidExtraMileage', null, global);
goog.exportSymbol('proto.fleet_service.Priority', null, global);
goog.exportSymbol('proto.fleet_service.RecordMileageDrivenLogRequest', null, global);
goog.exportSymbol('proto.fleet_service.RecordMileageDrivenLogResponse', null, global);
goog.exportSymbol('proto.fleet_service.ReturnAndPickupVehicleRequest', null, global);
goog.exportSymbol('proto.fleet_service.ReturnAndPickupVehicleResponse', null, global);
goog.exportSymbol('proto.fleet_service.ReturnDetails', null, global);
goog.exportSymbol('proto.fleet_service.ReturnVehicleRequest', null, global);
goog.exportSymbol('proto.fleet_service.ReturnVehicleResponse', null, global);
goog.exportSymbol('proto.fleet_service.Row', null, global);
goog.exportSymbol('proto.fleet_service.SignInspectionReportRequest', null, global);
goog.exportSymbol('proto.fleet_service.SignInspectionReportResponse', null, global);
goog.exportSymbol('proto.fleet_service.SignaturePhoto', null, global);
goog.exportSymbol('proto.fleet_service.StartVehicleInspectionRequest', null, global);
goog.exportSymbol('proto.fleet_service.StartVehicleInspectionResponse', null, global);
goog.exportSymbol('proto.fleet_service.StatusGroup', null, global);
goog.exportSymbol('proto.fleet_service.StatusGroup.GroupStatus', null, global);
goog.exportSymbol('proto.fleet_service.Table', null, global);
goog.exportSymbol('proto.fleet_service.TelemetricEvent', null, global);
goog.exportSymbol('proto.fleet_service.UnassignVehicleRequest', null, global);
goog.exportSymbol('proto.fleet_service.UnassignVehicleResponse', null, global);
goog.exportSymbol('proto.fleet_service.UpdateStateToInListingRequest', null, global);
goog.exportSymbol('proto.fleet_service.UpdateStateToInListingResponse', null, global);
goog.exportSymbol('proto.fleet_service.UpdateStateToInProcessRequest', null, global);
goog.exportSymbol('proto.fleet_service.UpdateStateToInProcessResponse', null, global);
goog.exportSymbol('proto.fleet_service.UpdateStateToSoldRequest', null, global);
goog.exportSymbol('proto.fleet_service.UpdateStateToSoldResponse', null, global);
goog.exportSymbol('proto.fleet_service.UpdateStateToTotalLossRequest', null, global);
goog.exportSymbol('proto.fleet_service.UpdateStateToTotalLossResponse', null, global);
goog.exportSymbol('proto.fleet_service.UpdateVehicleCustodyRequest', null, global);
goog.exportSymbol('proto.fleet_service.UpdateVehicleCustodyResponse', null, global);
goog.exportSymbol('proto.fleet_service.UpdateVehicleInfleetingRequest', null, global);
goog.exportSymbol('proto.fleet_service.UpdateVehicleInfleetingResponse', null, global);
goog.exportSymbol('proto.fleet_service.UpdateVehicleRequest', null, global);
goog.exportSymbol('proto.fleet_service.UpdateVehicleResponse', null, global);
goog.exportSymbol('proto.fleet_service.ValidateVinRequest', null, global);
goog.exportSymbol('proto.fleet_service.ValidateVinResponse', null, global);
goog.exportSymbol('proto.fleet_service.Vehicle', null, global);
goog.exportSymbol('proto.fleet_service.VehicleCriteria', null, global);
goog.exportSymbol('proto.fleet_service.VehicleOrder', null, global);
goog.exportSymbol('proto.fleet_service.VehiclePickup', null, global);
goog.exportSymbol('proto.fleet_service.VehicleProperty', null, global);
goog.exportSymbol('proto.fleet_service.VehicleProperty.PropertyCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.Vehicle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.Vehicle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.Vehicle.displayName = 'proto.fleet_service.Vehicle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.IngestVehiclesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.IngestVehiclesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.IngestVehiclesRequest.displayName = 'proto.fleet_service.IngestVehiclesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.IngestVehiclesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.IngestVehiclesResponse.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.IngestVehiclesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.IngestVehiclesResponse.displayName = 'proto.fleet_service.IngestVehiclesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.Row = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.Row.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.Row, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.Row.displayName = 'proto.fleet_service.Row';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.Table.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.Table.displayName = 'proto.fleet_service.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.InitiateVehicleReturnRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.InitiateVehicleReturnRequest.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.InitiateVehicleReturnRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.InitiateVehicleReturnRequest.displayName = 'proto.fleet_service.InitiateVehicleReturnRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.InitiateVehicleReturnResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.InitiateVehicleReturnResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.InitiateVehicleReturnResponse.displayName = 'proto.fleet_service.InitiateVehicleReturnResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ReturnVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.ReturnVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ReturnVehicleRequest.displayName = 'proto.fleet_service.ReturnVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ReturnVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.ReturnVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ReturnVehicleResponse.displayName = 'proto.fleet_service.ReturnVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ReturnAndPickupVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.ReturnAndPickupVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ReturnAndPickupVehicleRequest.displayName = 'proto.fleet_service.ReturnAndPickupVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ReturnAndPickupVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.ReturnAndPickupVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ReturnAndPickupVehicleResponse.displayName = 'proto.fleet_service.ReturnAndPickupVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ReturnDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.ReturnDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ReturnDetails.displayName = 'proto.fleet_service.ReturnDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.PickupDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.PickupDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.PickupDetails.displayName = 'proto.fleet_service.PickupDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.InspectionPhoto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.InspectionPhoto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.InspectionPhoto.displayName = 'proto.fleet_service.InspectionPhoto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.SignaturePhoto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.SignaturePhoto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.SignaturePhoto.displayName = 'proto.fleet_service.SignaturePhoto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.InspectionReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.InspectionReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.InspectionReport.displayName = 'proto.fleet_service.InspectionReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.CreateInspectionReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.CreateInspectionReportRequest.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.CreateInspectionReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.CreateInspectionReportRequest.displayName = 'proto.fleet_service.CreateInspectionReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.CreateInspectionReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.CreateInspectionReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.CreateInspectionReportResponse.displayName = 'proto.fleet_service.CreateInspectionReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.SignInspectionReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.SignInspectionReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.SignInspectionReportRequest.displayName = 'proto.fleet_service.SignInspectionReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.SignInspectionReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.SignInspectionReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.SignInspectionReportResponse.displayName = 'proto.fleet_service.SignInspectionReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.AddInspectionPhotosRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.AddInspectionPhotosRequest.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.AddInspectionPhotosRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.AddInspectionPhotosRequest.displayName = 'proto.fleet_service.AddInspectionPhotosRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.AddInspectionPhotosResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.AddInspectionPhotosResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.AddInspectionPhotosResponse.displayName = 'proto.fleet_service.AddInspectionPhotosResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetInspectionPhotosRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetInspectionPhotosRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetInspectionPhotosRequest.displayName = 'proto.fleet_service.GetInspectionPhotosRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetInspectionPhotosResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.GetInspectionPhotosResponse.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.GetInspectionPhotosResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetInspectionPhotosResponse.displayName = 'proto.fleet_service.GetInspectionPhotosResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetPresignedWritePhotoURLRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetPresignedWritePhotoURLRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetPresignedWritePhotoURLRequest.displayName = 'proto.fleet_service.GetPresignedWritePhotoURLRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetPresignedWritePhotoURLResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetPresignedWritePhotoURLResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetPresignedWritePhotoURLResponse.displayName = 'proto.fleet_service.GetPresignedWritePhotoURLResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetInspectionSignatureWriteURLRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetInspectionSignatureWriteURLRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetInspectionSignatureWriteURLRequest.displayName = 'proto.fleet_service.GetInspectionSignatureWriteURLRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetInspectionSignatureWriteURLResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetInspectionSignatureWriteURLResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetInspectionSignatureWriteURLResponse.displayName = 'proto.fleet_service.GetInspectionSignatureWriteURLResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetSubscriptionMileageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetSubscriptionMileageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetSubscriptionMileageRequest.displayName = 'proto.fleet_service.GetSubscriptionMileageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.MileageDrivenInMonth = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.MileageDrivenInMonth, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.MileageDrivenInMonth.displayName = 'proto.fleet_service.MileageDrivenInMonth';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.PrepaidExtraMileage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.PrepaidExtraMileage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.PrepaidExtraMileage.displayName = 'proto.fleet_service.PrepaidExtraMileage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetSubscriptionMileageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.GetSubscriptionMileageResponse.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.GetSubscriptionMileageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetSubscriptionMileageResponse.displayName = 'proto.fleet_service.GetSubscriptionMileageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetSubscriptionMileagePurchasesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetSubscriptionMileagePurchasesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetSubscriptionMileagePurchasesRequest.displayName = 'proto.fleet_service.GetSubscriptionMileagePurchasesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.MileagePurchase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.MileagePurchase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.MileagePurchase.displayName = 'proto.fleet_service.MileagePurchase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetSubscriptionMileagePurchasesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.GetSubscriptionMileagePurchasesResponse.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.GetSubscriptionMileagePurchasesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetSubscriptionMileagePurchasesResponse.displayName = 'proto.fleet_service.GetSubscriptionMileagePurchasesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetPrePaidMileageRateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetPrePaidMileageRateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetPrePaidMileageRateRequest.displayName = 'proto.fleet_service.GetPrePaidMileageRateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetPrePaidMileageRateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetPrePaidMileageRateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetPrePaidMileageRateResponse.displayName = 'proto.fleet_service.GetPrePaidMileageRateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetMileageRatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetMileageRatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetMileageRatesRequest.displayName = 'proto.fleet_service.GetMileageRatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetMileageRatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetMileageRatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetMileageRatesResponse.displayName = 'proto.fleet_service.GetMileageRatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.StatusGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.StatusGroup.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.StatusGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.StatusGroup.displayName = 'proto.fleet_service.StatusGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.AssignVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.AssignVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.AssignVehicleRequest.displayName = 'proto.fleet_service.AssignVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.AssignVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.AssignVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.AssignVehicleResponse.displayName = 'proto.fleet_service.AssignVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.UnassignVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.UnassignVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.UnassignVehicleRequest.displayName = 'proto.fleet_service.UnassignVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.UnassignVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.UnassignVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.UnassignVehicleResponse.displayName = 'proto.fleet_service.UnassignVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ListOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.ListOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ListOrdersRequest.displayName = 'proto.fleet_service.ListOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ListOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.ListOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.ListOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ListOrdersResponse.displayName = 'proto.fleet_service.ListOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.VehicleOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.VehicleOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.VehicleOrder.displayName = 'proto.fleet_service.VehicleOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.AddVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.AddVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.AddVehicleRequest.displayName = 'proto.fleet_service.AddVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.AddVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.AddVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.AddVehicleResponse.displayName = 'proto.fleet_service.AddVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.VehicleCriteria = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.VehicleCriteria, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.VehicleCriteria.displayName = 'proto.fleet_service.VehicleCriteria';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ListVehiclesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.ListVehiclesRequest.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.ListVehiclesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ListVehiclesRequest.displayName = 'proto.fleet_service.ListVehiclesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.OrderCriteria = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.OrderCriteria, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.OrderCriteria.displayName = 'proto.fleet_service.OrderCriteria';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.NexusVehicle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.NexusVehicle.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.NexusVehicle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.NexusVehicle.displayName = 'proto.fleet_service.NexusVehicle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ListVehiclesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.ListVehiclesResponse.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.ListVehiclesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ListVehiclesResponse.displayName = 'proto.fleet_service.ListVehiclesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetOwnBuyItNowDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetOwnBuyItNowDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetOwnBuyItNowDetailsRequest.displayName = 'proto.fleet_service.GetOwnBuyItNowDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetOwnBuyItNowDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetOwnBuyItNowDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetOwnBuyItNowDetailsResponse.displayName = 'proto.fleet_service.GetOwnBuyItNowDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetVehicleRequest.displayName = 'proto.fleet_service.GetVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetVehicleResponse.displayName = 'proto.fleet_service.GetVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetVehicleByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetVehicleByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetVehicleByIdRequest.displayName = 'proto.fleet_service.GetVehicleByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetVehicleByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetVehicleByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetVehicleByIdResponse.displayName = 'proto.fleet_service.GetVehicleByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetVehicleByVinRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetVehicleByVinRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetVehicleByVinRequest.displayName = 'proto.fleet_service.GetVehicleByVinRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetVehicleByVinResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetVehicleByVinResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetVehicleByVinResponse.displayName = 'proto.fleet_service.GetVehicleByVinResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.TelemetricEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.TelemetricEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.TelemetricEvent.displayName = 'proto.fleet_service.TelemetricEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.HandleTelemetricEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.HandleTelemetricEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.HandleTelemetricEventResponse.displayName = 'proto.fleet_service.HandleTelemetricEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.DeleteDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.DeleteDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.DeleteDocumentRequest.displayName = 'proto.fleet_service.DeleteDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.DeleteDocumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.DeleteDocumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.DeleteDocumentResponse.displayName = 'proto.fleet_service.DeleteDocumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ConfirmVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.ConfirmVehicleRequest.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.ConfirmVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ConfirmVehicleRequest.displayName = 'proto.fleet_service.ConfirmVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ConfirmVehicleRequest.ColorOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.ConfirmVehicleRequest.ColorOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ConfirmVehicleRequest.ColorOptions.displayName = 'proto.fleet_service.ConfirmVehicleRequest.ColorOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ConfirmVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.ConfirmVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ConfirmVehicleResponse.displayName = 'proto.fleet_service.ConfirmVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ListProfilesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.ListProfilesRequest.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.ListProfilesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ListProfilesRequest.displayName = 'proto.fleet_service.ListProfilesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ListProfilesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.ListProfilesResponse.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.ListProfilesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ListProfilesResponse.displayName = 'proto.fleet_service.ListProfilesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.VehicleProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.fleet_service.VehicleProperty.oneofGroups_);
};
goog.inherits(proto.fleet_service.VehicleProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.VehicleProperty.displayName = 'proto.fleet_service.VehicleProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.UpdateVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.UpdateVehicleRequest.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.UpdateVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.UpdateVehicleRequest.displayName = 'proto.fleet_service.UpdateVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.UpdateVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.UpdateVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.UpdateVehicleResponse.displayName = 'proto.fleet_service.UpdateVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ListDealershipsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.ListDealershipsRequest.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.ListDealershipsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ListDealershipsRequest.displayName = 'proto.fleet_service.ListDealershipsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ListDealershipsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.ListDealershipsResponse.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.ListDealershipsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ListDealershipsResponse.displayName = 'proto.fleet_service.ListDealershipsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetDealershipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetDealershipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetDealershipRequest.displayName = 'proto.fleet_service.GetDealershipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetDealershipResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetDealershipResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetDealershipResponse.displayName = 'proto.fleet_service.GetDealershipResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.DealershipCriteria = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.DealershipCriteria, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.DealershipCriteria.displayName = 'proto.fleet_service.DealershipCriteria';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.StartVehicleInspectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.StartVehicleInspectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.StartVehicleInspectionRequest.displayName = 'proto.fleet_service.StartVehicleInspectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.StartVehicleInspectionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.StartVehicleInspectionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.StartVehicleInspectionResponse.displayName = 'proto.fleet_service.StartVehicleInspectionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetVehicleInspectionResultsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetVehicleInspectionResultsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetVehicleInspectionResultsRequest.displayName = 'proto.fleet_service.GetVehicleInspectionResultsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetAvailableModelsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetAvailableModelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetAvailableModelsRequest.displayName = 'proto.fleet_service.GetAvailableModelsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetAvailableModelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.GetAvailableModelsResponse.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.GetAvailableModelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetAvailableModelsResponse.displayName = 'proto.fleet_service.GetAvailableModelsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetAvailableTrimsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetAvailableTrimsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetAvailableTrimsRequest.displayName = 'proto.fleet_service.GetAvailableTrimsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetAvailableTrimsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.GetAvailableTrimsResponse.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.GetAvailableTrimsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetAvailableTrimsResponse.displayName = 'proto.fleet_service.GetAvailableTrimsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ValidateVinRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.ValidateVinRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ValidateVinRequest.displayName = 'proto.fleet_service.ValidateVinRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ValidateVinResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.ValidateVinResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ValidateVinResponse.displayName = 'proto.fleet_service.ValidateVinResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.UpdateVehicleCustodyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.UpdateVehicleCustodyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.UpdateVehicleCustodyRequest.displayName = 'proto.fleet_service.UpdateVehicleCustodyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.UpdateVehicleCustodyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.UpdateVehicleCustodyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.UpdateVehicleCustodyResponse.displayName = 'proto.fleet_service.UpdateVehicleCustodyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetLatestCustodyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetLatestCustodyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetLatestCustodyRequest.displayName = 'proto.fleet_service.GetLatestCustodyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetLatestCustodyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.fleet_service.GetLatestCustodyResponse.oneofGroups_);
};
goog.inherits(proto.fleet_service.GetLatestCustodyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetLatestCustodyResponse.displayName = 'proto.fleet_service.GetLatestCustodyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetVehicleInfleetingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.GetVehicleInfleetingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetVehicleInfleetingRequest.displayName = 'proto.fleet_service.GetVehicleInfleetingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.GetVehicleInfleetingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.GetVehicleInfleetingResponse.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.GetVehicleInfleetingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.GetVehicleInfleetingResponse.displayName = 'proto.fleet_service.GetVehicleInfleetingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.UpdateVehicleInfleetingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.UpdateVehicleInfleetingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.UpdateVehicleInfleetingRequest.displayName = 'proto.fleet_service.UpdateVehicleInfleetingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.UpdateVehicleInfleetingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.UpdateVehicleInfleetingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.UpdateVehicleInfleetingResponse.displayName = 'proto.fleet_service.UpdateVehicleInfleetingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.RecordMileageDrivenLogRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.RecordMileageDrivenLogRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.RecordMileageDrivenLogRequest.displayName = 'proto.fleet_service.RecordMileageDrivenLogRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.RecordMileageDrivenLogResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.RecordMileageDrivenLogResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.RecordMileageDrivenLogResponse.displayName = 'proto.fleet_service.RecordMileageDrivenLogResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.VehiclePickup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.VehiclePickup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.VehiclePickup.displayName = 'proto.fleet_service.VehiclePickup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ListVehiclePickupsQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.ListVehiclePickupsQuery.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.ListVehiclePickupsQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ListVehiclePickupsQuery.displayName = 'proto.fleet_service.ListVehiclePickupsQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ListVehiclePickupsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.ListVehiclePickupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ListVehiclePickupsRequest.displayName = 'proto.fleet_service.ListVehiclePickupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ListVehiclePickupsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.ListVehiclePickupsResponse.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.ListVehiclePickupsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ListVehiclePickupsResponse.displayName = 'proto.fleet_service.ListVehiclePickupsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ListVehiclePickupsToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.ListVehiclePickupsToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ListVehiclePickupsToken.displayName = 'proto.fleet_service.ListVehiclePickupsToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ListInspectionReportsQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.ListInspectionReportsQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ListInspectionReportsQuery.displayName = 'proto.fleet_service.ListInspectionReportsQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ListInspectionReportsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.ListInspectionReportsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ListInspectionReportsRequest.displayName = 'proto.fleet_service.ListInspectionReportsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ListInspectionReportsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fleet_service.ListInspectionReportsResponse.repeatedFields_, null);
};
goog.inherits(proto.fleet_service.ListInspectionReportsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ListInspectionReportsResponse.displayName = 'proto.fleet_service.ListInspectionReportsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.ListInspectionReportsToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.ListInspectionReportsToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.ListInspectionReportsToken.displayName = 'proto.fleet_service.ListInspectionReportsToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.UpdateStateToInListingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.UpdateStateToInListingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.UpdateStateToInListingRequest.displayName = 'proto.fleet_service.UpdateStateToInListingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.UpdateStateToInListingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.UpdateStateToInListingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.UpdateStateToInListingResponse.displayName = 'proto.fleet_service.UpdateStateToInListingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.UpdateStateToInProcessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.UpdateStateToInProcessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.UpdateStateToInProcessRequest.displayName = 'proto.fleet_service.UpdateStateToInProcessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.UpdateStateToInProcessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.UpdateStateToInProcessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.UpdateStateToInProcessResponse.displayName = 'proto.fleet_service.UpdateStateToInProcessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.UpdateStateToSoldRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.UpdateStateToSoldRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.UpdateStateToSoldRequest.displayName = 'proto.fleet_service.UpdateStateToSoldRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.UpdateStateToSoldResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.UpdateStateToSoldResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.UpdateStateToSoldResponse.displayName = 'proto.fleet_service.UpdateStateToSoldResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.UpdateStateToTotalLossRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.UpdateStateToTotalLossRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.UpdateStateToTotalLossRequest.displayName = 'proto.fleet_service.UpdateStateToTotalLossRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.UpdateStateToTotalLossResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.UpdateStateToTotalLossResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.UpdateStateToTotalLossResponse.displayName = 'proto.fleet_service.UpdateStateToTotalLossResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.LogMilesPurchasedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.LogMilesPurchasedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.LogMilesPurchasedRequest.displayName = 'proto.fleet_service.LogMilesPurchasedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.LogMilesPurchasedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.LogMilesPurchasedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.LogMilesPurchasedResponse.displayName = 'proto.fleet_service.LogMilesPurchasedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.DeleteMilesPurchasedLogRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.DeleteMilesPurchasedLogRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.DeleteMilesPurchasedLogRequest.displayName = 'proto.fleet_service.DeleteMilesPurchasedLogRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.DeleteMilesPurchasedLogResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.DeleteMilesPurchasedLogResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.DeleteMilesPurchasedLogResponse.displayName = 'proto.fleet_service.DeleteMilesPurchasedLogResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fleet_service.InfleetVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fleet_service.InfleetVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fleet_service.InfleetVehicleRequest.displayName = 'proto.fleet_service.InfleetVehicleRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.Vehicle.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.Vehicle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.Vehicle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.Vehicle.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    make: jspb.Message.getFieldWithDefault(msg, 3, ""),
    model: jspb.Message.getFieldWithDefault(msg, 4, ""),
    year: jspb.Message.getFieldWithDefault(msg, 5, ""),
    trim: jspb.Message.getFieldWithDefault(msg, 6, ""),
    financier: jspb.Message.getFieldWithDefault(msg, 7, ""),
    interiorColor: jspb.Message.getFieldWithDefault(msg, 8, ""),
    exteriorColor: jspb.Message.getFieldWithDefault(msg, 9, ""),
    state: jspb.Message.getFieldWithDefault(msg, 10, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    programId: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.Vehicle}
 */
proto.fleet_service.Vehicle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.Vehicle;
  return proto.fleet_service.Vehicle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.Vehicle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.Vehicle}
 */
proto.fleet_service.Vehicle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrim(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinancier(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setInteriorColor(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setExteriorColor(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.Vehicle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.Vehicle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.Vehicle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.Vehicle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTrim();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFinancier();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getInteriorColor();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getExteriorColor();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getProgramId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fleet_service.Vehicle.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.Vehicle} returns this
 */
proto.fleet_service.Vehicle.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vin = 2;
 * @return {string}
 */
proto.fleet_service.Vehicle.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.Vehicle} returns this
 */
proto.fleet_service.Vehicle.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string make = 3;
 * @return {string}
 */
proto.fleet_service.Vehicle.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.Vehicle} returns this
 */
proto.fleet_service.Vehicle.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string model = 4;
 * @return {string}
 */
proto.fleet_service.Vehicle.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.Vehicle} returns this
 */
proto.fleet_service.Vehicle.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string year = 5;
 * @return {string}
 */
proto.fleet_service.Vehicle.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.Vehicle} returns this
 */
proto.fleet_service.Vehicle.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string trim = 6;
 * @return {string}
 */
proto.fleet_service.Vehicle.prototype.getTrim = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.Vehicle} returns this
 */
proto.fleet_service.Vehicle.prototype.setTrim = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string financier = 7;
 * @return {string}
 */
proto.fleet_service.Vehicle.prototype.getFinancier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.Vehicle} returns this
 */
proto.fleet_service.Vehicle.prototype.setFinancier = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string interior_color = 8;
 * @return {string}
 */
proto.fleet_service.Vehicle.prototype.getInteriorColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.Vehicle} returns this
 */
proto.fleet_service.Vehicle.prototype.setInteriorColor = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string exterior_color = 9;
 * @return {string}
 */
proto.fleet_service.Vehicle.prototype.getExteriorColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.Vehicle} returns this
 */
proto.fleet_service.Vehicle.prototype.setExteriorColor = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string state = 10;
 * @return {string}
 */
proto.fleet_service.Vehicle.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.Vehicle} returns this
 */
proto.fleet_service.Vehicle.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string customer_id = 11;
 * @return {string}
 */
proto.fleet_service.Vehicle.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.Vehicle} returns this
 */
proto.fleet_service.Vehicle.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string program_id = 12;
 * @return {string}
 */
proto.fleet_service.Vehicle.prototype.getProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.Vehicle} returns this
 */
proto.fleet_service.Vehicle.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.IngestVehiclesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.IngestVehiclesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.IngestVehiclesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.IngestVehiclesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    table: (f = msg.getTable()) && proto.fleet_service.Table.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.IngestVehiclesRequest}
 */
proto.fleet_service.IngestVehiclesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.IngestVehiclesRequest;
  return proto.fleet_service.IngestVehiclesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.IngestVehiclesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.IngestVehiclesRequest}
 */
proto.fleet_service.IngestVehiclesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fleet_service.Table;
      reader.readMessage(value,proto.fleet_service.Table.deserializeBinaryFromReader);
      msg.setTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.IngestVehiclesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.IngestVehiclesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.IngestVehiclesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.IngestVehiclesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTable();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fleet_service.Table.serializeBinaryToWriter
    );
  }
};


/**
 * optional Table table = 1;
 * @return {?proto.fleet_service.Table}
 */
proto.fleet_service.IngestVehiclesRequest.prototype.getTable = function() {
  return /** @type{?proto.fleet_service.Table} */ (
    jspb.Message.getWrapperField(this, proto.fleet_service.Table, 1));
};


/**
 * @param {?proto.fleet_service.Table|undefined} value
 * @return {!proto.fleet_service.IngestVehiclesRequest} returns this
*/
proto.fleet_service.IngestVehiclesRequest.prototype.setTable = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.IngestVehiclesRequest} returns this
 */
proto.fleet_service.IngestVehiclesRequest.prototype.clearTable = function() {
  return this.setTable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.IngestVehiclesRequest.prototype.hasTable = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.IngestVehiclesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.IngestVehiclesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.IngestVehiclesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.IngestVehiclesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.IngestVehiclesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    successfulVinsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.IngestVehiclesResponse}
 */
proto.fleet_service.IngestVehiclesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.IngestVehiclesResponse;
  return proto.fleet_service.IngestVehiclesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.IngestVehiclesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.IngestVehiclesResponse}
 */
proto.fleet_service.IngestVehiclesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSuccessfulVins(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.IngestVehiclesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.IngestVehiclesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.IngestVehiclesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.IngestVehiclesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccessfulVinsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string successful_vins = 1;
 * @return {!Array<string>}
 */
proto.fleet_service.IngestVehiclesResponse.prototype.getSuccessfulVinsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fleet_service.IngestVehiclesResponse} returns this
 */
proto.fleet_service.IngestVehiclesResponse.prototype.setSuccessfulVinsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.IngestVehiclesResponse} returns this
 */
proto.fleet_service.IngestVehiclesResponse.prototype.addSuccessfulVins = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.IngestVehiclesResponse} returns this
 */
proto.fleet_service.IngestVehiclesResponse.prototype.clearSuccessfulVinsList = function() {
  return this.setSuccessfulVinsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.Row.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.Row.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.Row.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.Row} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.Row.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    valuesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.Row}
 */
proto.fleet_service.Row.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.Row;
  return proto.fleet_service.Row.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.Row} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.Row}
 */
proto.fleet_service.Row.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.Row.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.Row.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.Row} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.Row.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fleet_service.Row.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.Row} returns this
 */
proto.fleet_service.Row.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string values = 2;
 * @return {!Array<string>}
 */
proto.fleet_service.Row.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fleet_service.Row} returns this
 */
proto.fleet_service.Row.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.Row} returns this
 */
proto.fleet_service.Row.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.Row} returns this
 */
proto.fleet_service.Row.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.Table.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    columnsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.fleet_service.Row.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.Table}
 */
proto.fleet_service.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.Table;
  return proto.fleet_service.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.Table}
 */
proto.fleet_service.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addColumns(value);
      break;
    case 2:
      var value = new proto.fleet_service.Row;
      reader.readMessage(value,proto.fleet_service.Row.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColumnsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.fleet_service.Row.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string columns = 1;
 * @return {!Array<string>}
 */
proto.fleet_service.Table.prototype.getColumnsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fleet_service.Table} returns this
 */
proto.fleet_service.Table.prototype.setColumnsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.Table} returns this
 */
proto.fleet_service.Table.prototype.addColumns = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.Table} returns this
 */
proto.fleet_service.Table.prototype.clearColumnsList = function() {
  return this.setColumnsList([]);
};


/**
 * repeated Row rows = 2;
 * @return {!Array<!proto.fleet_service.Row>}
 */
proto.fleet_service.Table.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.fleet_service.Row>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fleet_service.Row, 2));
};


/**
 * @param {!Array<!proto.fleet_service.Row>} value
 * @return {!proto.fleet_service.Table} returns this
*/
proto.fleet_service.Table.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fleet_service.Row=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.Row}
 */
proto.fleet_service.Table.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fleet_service.Row, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.Table} returns this
 */
proto.fleet_service.Table.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.InitiateVehicleReturnRequest.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.InitiateVehicleReturnRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.InitiateVehicleReturnRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.InitiateVehicleReturnRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    odometer: (f = msg.getOdometer()) && vehicle_pb.Odometer.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && vehicle_pb.Location.toObject(includeInstance, f),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    domain_pb.Image.toObject, includeInstance),
    lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    events_pb.ReturnLineItem.toObject, includeInstance),
    miscellaneousMap: (f = msg.getMiscellaneousMap()) ? f.toObject(includeInstance, undefined) : [],
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    returnTime: jspb.Message.getFieldWithDefault(msg, 10, ""),
    missingAssetsAmount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    initialDamagesAmount: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.InitiateVehicleReturnRequest}
 */
proto.fleet_service.InitiateVehicleReturnRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.InitiateVehicleReturnRequest;
  return proto.fleet_service.InitiateVehicleReturnRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.InitiateVehicleReturnRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.InitiateVehicleReturnRequest}
 */
proto.fleet_service.InitiateVehicleReturnRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 4:
      var value = new vehicle_pb.Odometer;
      reader.readMessage(value,vehicle_pb.Odometer.deserializeBinaryFromReader);
      msg.setOdometer(value);
      break;
    case 5:
      var value = new vehicle_pb.Location;
      reader.readMessage(value,vehicle_pb.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 6:
      var value = new domain_pb.Image;
      reader.readMessage(value,domain_pb.Image.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 7:
      var value = new events_pb.ReturnLineItem;
      reader.readMessage(value,events_pb.ReturnLineItem.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    case 8:
      var value = msg.getMiscellaneousMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setReturnTime(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMissingAssetsAmount(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInitialDamagesAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.InitiateVehicleReturnRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.InitiateVehicleReturnRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.InitiateVehicleReturnRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOdometer();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vehicle_pb.Odometer.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vehicle_pb.Location.serializeBinaryToWriter
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      domain_pb.Image.serializeBinaryToWriter
    );
  }
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      events_pb.ReturnLineItem.serializeBinaryToWriter
    );
  }
  f = message.getMiscellaneousMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getReturnTime();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMissingAssetsAmount();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getInitialDamagesAmount();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.InitiateVehicleReturnRequest} returns this
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vin = 2;
 * @return {string}
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.InitiateVehicleReturnRequest} returns this
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_id = 3;
 * @return {string}
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.InitiateVehicleReturnRequest} returns this
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional vehicle.Odometer odometer = 4;
 * @return {?proto.vehicle.Odometer}
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.getOdometer = function() {
  return /** @type{?proto.vehicle.Odometer} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Odometer, 4));
};


/**
 * @param {?proto.vehicle.Odometer|undefined} value
 * @return {!proto.fleet_service.InitiateVehicleReturnRequest} returns this
*/
proto.fleet_service.InitiateVehicleReturnRequest.prototype.setOdometer = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.InitiateVehicleReturnRequest} returns this
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.clearOdometer = function() {
  return this.setOdometer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.hasOdometer = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional vehicle.Location location = 5;
 * @return {?proto.vehicle.Location}
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.getLocation = function() {
  return /** @type{?proto.vehicle.Location} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Location, 5));
};


/**
 * @param {?proto.vehicle.Location|undefined} value
 * @return {!proto.fleet_service.InitiateVehicleReturnRequest} returns this
*/
proto.fleet_service.InitiateVehicleReturnRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.InitiateVehicleReturnRequest} returns this
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated domain.Image images = 6;
 * @return {!Array<!proto.domain.Image>}
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.domain.Image>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.Image, 6));
};


/**
 * @param {!Array<!proto.domain.Image>} value
 * @return {!proto.fleet_service.InitiateVehicleReturnRequest} returns this
*/
proto.fleet_service.InitiateVehicleReturnRequest.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.domain.Image=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Image}
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.domain.Image, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.InitiateVehicleReturnRequest} returns this
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * repeated events.ReturnLineItem line_items = 7;
 * @return {!Array<!proto.events.ReturnLineItem>}
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.getLineItemsList = function() {
  return /** @type{!Array<!proto.events.ReturnLineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, events_pb.ReturnLineItem, 7));
};


/**
 * @param {!Array<!proto.events.ReturnLineItem>} value
 * @return {!proto.fleet_service.InitiateVehicleReturnRequest} returns this
*/
proto.fleet_service.InitiateVehicleReturnRequest.prototype.setLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.events.ReturnLineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.events.ReturnLineItem}
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.events.ReturnLineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.InitiateVehicleReturnRequest} returns this
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.clearLineItemsList = function() {
  return this.setLineItemsList([]);
};


/**
 * map<string, string> miscellaneous = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.getMiscellaneousMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 8, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.fleet_service.InitiateVehicleReturnRequest} returns this
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.clearMiscellaneousMap = function() {
  this.getMiscellaneousMap().clear();
  return this;};


/**
 * optional string subscription_id = 9;
 * @return {string}
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.InitiateVehicleReturnRequest} returns this
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string return_time = 10;
 * @return {string}
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.getReturnTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.InitiateVehicleReturnRequest} returns this
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.setReturnTime = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 missing_assets_amount = 11;
 * @return {number}
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.getMissingAssetsAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.InitiateVehicleReturnRequest} returns this
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.setMissingAssetsAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 initial_damages_amount = 12;
 * @return {number}
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.getInitialDamagesAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.InitiateVehicleReturnRequest} returns this
 */
proto.fleet_service.InitiateVehicleReturnRequest.prototype.setInitialDamagesAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.InitiateVehicleReturnResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.InitiateVehicleReturnResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.InitiateVehicleReturnResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.InitiateVehicleReturnResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    initiated: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.InitiateVehicleReturnResponse}
 */
proto.fleet_service.InitiateVehicleReturnResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.InitiateVehicleReturnResponse;
  return proto.fleet_service.InitiateVehicleReturnResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.InitiateVehicleReturnResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.InitiateVehicleReturnResponse}
 */
proto.fleet_service.InitiateVehicleReturnResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInitiated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.InitiateVehicleReturnResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.InitiateVehicleReturnResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.InitiateVehicleReturnResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.InitiateVehicleReturnResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInitiated();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool initiated = 1;
 * @return {boolean}
 */
proto.fleet_service.InitiateVehicleReturnResponse.prototype.getInitiated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.InitiateVehicleReturnResponse} returns this
 */
proto.fleet_service.InitiateVehicleReturnResponse.prototype.setInitiated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ReturnVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ReturnVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ReturnVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ReturnVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    returnDetails: (f = msg.getReturnDetails()) && proto.fleet_service.ReturnDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ReturnVehicleRequest}
 */
proto.fleet_service.ReturnVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ReturnVehicleRequest;
  return proto.fleet_service.ReturnVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ReturnVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ReturnVehicleRequest}
 */
proto.fleet_service.ReturnVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = new proto.fleet_service.ReturnDetails;
      reader.readMessage(value,proto.fleet_service.ReturnDetails.deserializeBinaryFromReader);
      msg.setReturnDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ReturnVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ReturnVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ReturnVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ReturnVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReturnDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fleet_service.ReturnDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.fleet_service.ReturnVehicleRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ReturnVehicleRequest} returns this
 */
proto.fleet_service.ReturnVehicleRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ReturnDetails return_details = 2;
 * @return {?proto.fleet_service.ReturnDetails}
 */
proto.fleet_service.ReturnVehicleRequest.prototype.getReturnDetails = function() {
  return /** @type{?proto.fleet_service.ReturnDetails} */ (
    jspb.Message.getWrapperField(this, proto.fleet_service.ReturnDetails, 2));
};


/**
 * @param {?proto.fleet_service.ReturnDetails|undefined} value
 * @return {!proto.fleet_service.ReturnVehicleRequest} returns this
*/
proto.fleet_service.ReturnVehicleRequest.prototype.setReturnDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.ReturnVehicleRequest} returns this
 */
proto.fleet_service.ReturnVehicleRequest.prototype.clearReturnDetails = function() {
  return this.setReturnDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.ReturnVehicleRequest.prototype.hasReturnDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ReturnVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ReturnVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ReturnVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ReturnVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ReturnVehicleResponse}
 */
proto.fleet_service.ReturnVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ReturnVehicleResponse;
  return proto.fleet_service.ReturnVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ReturnVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ReturnVehicleResponse}
 */
proto.fleet_service.ReturnVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ReturnVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ReturnVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ReturnVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ReturnVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ReturnAndPickupVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ReturnAndPickupVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ReturnAndPickupVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ReturnAndPickupVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    returnDetails: (f = msg.getReturnDetails()) && proto.fleet_service.ReturnDetails.toObject(includeInstance, f),
    pickupDetails: (f = msg.getPickupDetails()) && proto.fleet_service.PickupDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ReturnAndPickupVehicleRequest}
 */
proto.fleet_service.ReturnAndPickupVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ReturnAndPickupVehicleRequest;
  return proto.fleet_service.ReturnAndPickupVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ReturnAndPickupVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ReturnAndPickupVehicleRequest}
 */
proto.fleet_service.ReturnAndPickupVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = new proto.fleet_service.ReturnDetails;
      reader.readMessage(value,proto.fleet_service.ReturnDetails.deserializeBinaryFromReader);
      msg.setReturnDetails(value);
      break;
    case 3:
      var value = new proto.fleet_service.PickupDetails;
      reader.readMessage(value,proto.fleet_service.PickupDetails.deserializeBinaryFromReader);
      msg.setPickupDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ReturnAndPickupVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ReturnAndPickupVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ReturnAndPickupVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ReturnAndPickupVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReturnDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fleet_service.ReturnDetails.serializeBinaryToWriter
    );
  }
  f = message.getPickupDetails();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fleet_service.PickupDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.fleet_service.ReturnAndPickupVehicleRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ReturnAndPickupVehicleRequest} returns this
 */
proto.fleet_service.ReturnAndPickupVehicleRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ReturnDetails return_details = 2;
 * @return {?proto.fleet_service.ReturnDetails}
 */
proto.fleet_service.ReturnAndPickupVehicleRequest.prototype.getReturnDetails = function() {
  return /** @type{?proto.fleet_service.ReturnDetails} */ (
    jspb.Message.getWrapperField(this, proto.fleet_service.ReturnDetails, 2));
};


/**
 * @param {?proto.fleet_service.ReturnDetails|undefined} value
 * @return {!proto.fleet_service.ReturnAndPickupVehicleRequest} returns this
*/
proto.fleet_service.ReturnAndPickupVehicleRequest.prototype.setReturnDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.ReturnAndPickupVehicleRequest} returns this
 */
proto.fleet_service.ReturnAndPickupVehicleRequest.prototype.clearReturnDetails = function() {
  return this.setReturnDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.ReturnAndPickupVehicleRequest.prototype.hasReturnDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PickupDetails pickup_details = 3;
 * @return {?proto.fleet_service.PickupDetails}
 */
proto.fleet_service.ReturnAndPickupVehicleRequest.prototype.getPickupDetails = function() {
  return /** @type{?proto.fleet_service.PickupDetails} */ (
    jspb.Message.getWrapperField(this, proto.fleet_service.PickupDetails, 3));
};


/**
 * @param {?proto.fleet_service.PickupDetails|undefined} value
 * @return {!proto.fleet_service.ReturnAndPickupVehicleRequest} returns this
*/
proto.fleet_service.ReturnAndPickupVehicleRequest.prototype.setPickupDetails = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.ReturnAndPickupVehicleRequest} returns this
 */
proto.fleet_service.ReturnAndPickupVehicleRequest.prototype.clearPickupDetails = function() {
  return this.setPickupDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.ReturnAndPickupVehicleRequest.prototype.hasPickupDetails = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ReturnAndPickupVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ReturnAndPickupVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ReturnAndPickupVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ReturnAndPickupVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ReturnAndPickupVehicleResponse}
 */
proto.fleet_service.ReturnAndPickupVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ReturnAndPickupVehicleResponse;
  return proto.fleet_service.ReturnAndPickupVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ReturnAndPickupVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ReturnAndPickupVehicleResponse}
 */
proto.fleet_service.ReturnAndPickupVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ReturnAndPickupVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ReturnAndPickupVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ReturnAndPickupVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ReturnAndPickupVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ReturnDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ReturnDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ReturnDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ReturnDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    odometer: jspb.Message.getFieldWithDefault(msg, 2, 0),
    time: jspb.Message.getFieldWithDefault(msg, 3, ""),
    inspectionReportId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ReturnDetails}
 */
proto.fleet_service.ReturnDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ReturnDetails;
  return proto.fleet_service.ReturnDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ReturnDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ReturnDetails}
 */
proto.fleet_service.ReturnDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOdometer(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInspectionReportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ReturnDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ReturnDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ReturnDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ReturnDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOdometer();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInspectionReportId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.fleet_service.ReturnDetails.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ReturnDetails} returns this
 */
proto.fleet_service.ReturnDetails.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 odometer = 2;
 * @return {number}
 */
proto.fleet_service.ReturnDetails.prototype.getOdometer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.ReturnDetails} returns this
 */
proto.fleet_service.ReturnDetails.prototype.setOdometer = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string time = 3;
 * @return {string}
 */
proto.fleet_service.ReturnDetails.prototype.getTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ReturnDetails} returns this
 */
proto.fleet_service.ReturnDetails.prototype.setTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 inspection_report_id = 4;
 * @return {number}
 */
proto.fleet_service.ReturnDetails.prototype.getInspectionReportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.ReturnDetails} returns this
 */
proto.fleet_service.ReturnDetails.prototype.setInspectionReportId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.PickupDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.PickupDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.PickupDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.PickupDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    odometer: jspb.Message.getFieldWithDefault(msg, 2, 0),
    time: jspb.Message.getFieldWithDefault(msg, 3, ""),
    inspectionReportId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.PickupDetails}
 */
proto.fleet_service.PickupDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.PickupDetails;
  return proto.fleet_service.PickupDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.PickupDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.PickupDetails}
 */
proto.fleet_service.PickupDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOdometer(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInspectionReportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.PickupDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.PickupDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.PickupDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.PickupDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOdometer();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInspectionReportId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.fleet_service.PickupDetails.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.PickupDetails} returns this
 */
proto.fleet_service.PickupDetails.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 odometer = 2;
 * @return {number}
 */
proto.fleet_service.PickupDetails.prototype.getOdometer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.PickupDetails} returns this
 */
proto.fleet_service.PickupDetails.prototype.setOdometer = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string time = 3;
 * @return {string}
 */
proto.fleet_service.PickupDetails.prototype.getTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.PickupDetails} returns this
 */
proto.fleet_service.PickupDetails.prototype.setTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 inspection_report_id = 4;
 * @return {number}
 */
proto.fleet_service.PickupDetails.prototype.getInspectionReportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.PickupDetails} returns this
 */
proto.fleet_service.PickupDetails.prototype.setInspectionReportId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.InspectionPhoto.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.InspectionPhoto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.InspectionPhoto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.InspectionPhoto.toObject = function(includeInstance, msg) {
  var f, obj = {
    path: jspb.Message.getFieldWithDefault(msg, 1, ""),
    area: jspb.Message.getFieldWithDefault(msg, 2, 0),
    presignedUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.InspectionPhoto}
 */
proto.fleet_service.InspectionPhoto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.InspectionPhoto;
  return proto.fleet_service.InspectionPhoto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.InspectionPhoto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.InspectionPhoto}
 */
proto.fleet_service.InspectionPhoto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 2:
      var value = /** @type {!proto.fleet_service.PhotoArea} */ (reader.readEnum());
      msg.setArea(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPresignedUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.InspectionPhoto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.InspectionPhoto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.InspectionPhoto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.InspectionPhoto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getArea();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPresignedUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string path = 1;
 * @return {string}
 */
proto.fleet_service.InspectionPhoto.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.InspectionPhoto} returns this
 */
proto.fleet_service.InspectionPhoto.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PhotoArea area = 2;
 * @return {!proto.fleet_service.PhotoArea}
 */
proto.fleet_service.InspectionPhoto.prototype.getArea = function() {
  return /** @type {!proto.fleet_service.PhotoArea} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fleet_service.PhotoArea} value
 * @return {!proto.fleet_service.InspectionPhoto} returns this
 */
proto.fleet_service.InspectionPhoto.prototype.setArea = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string presigned_url = 3;
 * @return {string}
 */
proto.fleet_service.InspectionPhoto.prototype.getPresignedUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.InspectionPhoto} returns this
 */
proto.fleet_service.InspectionPhoto.prototype.setPresignedUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.SignaturePhoto.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.SignaturePhoto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.SignaturePhoto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.SignaturePhoto.toObject = function(includeInstance, msg) {
  var f, obj = {
    path: jspb.Message.getFieldWithDefault(msg, 1, ""),
    presignedUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.SignaturePhoto}
 */
proto.fleet_service.SignaturePhoto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.SignaturePhoto;
  return proto.fleet_service.SignaturePhoto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.SignaturePhoto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.SignaturePhoto}
 */
proto.fleet_service.SignaturePhoto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPresignedUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.SignaturePhoto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.SignaturePhoto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.SignaturePhoto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.SignaturePhoto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPresignedUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string path = 1;
 * @return {string}
 */
proto.fleet_service.SignaturePhoto.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.SignaturePhoto} returns this
 */
proto.fleet_service.SignaturePhoto.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string presigned_url = 2;
 * @return {string}
 */
proto.fleet_service.SignaturePhoto.prototype.getPresignedUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.SignaturePhoto} returns this
 */
proto.fleet_service.SignaturePhoto.prototype.setPresignedUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.InspectionReport.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.InspectionReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.InspectionReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.InspectionReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    keyCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    matsStatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
    chargeLevel: jspb.Message.getFieldWithDefault(msg, 7, 0),
    chargingCable: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    plugAdapter120v: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    plugAdapter240v: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    saeChargingAdapter: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    ccsChargingAdapter: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    frontPlate: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    rearPlate: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    plateScrews: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    frontPlateMount: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    registrationInVehicle: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    registrationExpired: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    hovStickersInstalled: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    tiresAndWheelsMatch: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    treadDepthRightFront: jspb.Message.getFieldWithDefault(msg, 21, 0),
    treadDepthLeftFront: jspb.Message.getFieldWithDefault(msg, 22, 0),
    treadDepthRightRear: jspb.Message.getFieldWithDefault(msg, 23, 0),
    treadDepthLeftRear: jspb.Message.getFieldWithDefault(msg, 24, 0),
    personalItemsRemoved: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    vehicleSystemReset: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    signaturePhoto: (f = msg.getSignaturePhoto()) && proto.fleet_service.SignaturePhoto.toObject(includeInstance, f),
    signedCustomerId: jspb.Message.getFieldWithDefault(msg, 28, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.InspectionReport}
 */
proto.fleet_service.InspectionReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.InspectionReport;
  return proto.fleet_service.InspectionReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.InspectionReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.InspectionReport}
 */
proto.fleet_service.InspectionReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKeyCount(value);
      break;
    case 6:
      var value = /** @type {!proto.fleet_service.MatsStatus} */ (reader.readEnum());
      msg.setMatsStatus(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChargeLevel(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChargingCable(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPlugAdapter120v(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPlugAdapter240v(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSaeChargingAdapter(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCcsChargingAdapter(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFrontPlate(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRearPlate(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPlateScrews(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFrontPlateMount(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRegistrationInVehicle(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRegistrationExpired(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHovStickersInstalled(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTiresAndWheelsMatch(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTreadDepthRightFront(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTreadDepthLeftFront(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTreadDepthRightRear(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTreadDepthLeftRear(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPersonalItemsRemoved(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVehicleSystemReset(value);
      break;
    case 27:
      var value = new proto.fleet_service.SignaturePhoto;
      reader.readMessage(value,proto.fleet_service.SignaturePhoto.deserializeBinaryFromReader);
      msg.setSignaturePhoto(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignedCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.InspectionReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.InspectionReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.InspectionReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.InspectionReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getKeyCount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMatsStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getChargeLevel();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getChargingCable();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getPlugAdapter120v();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getPlugAdapter240v();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getSaeChargingAdapter();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getCcsChargingAdapter();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getFrontPlate();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getRearPlate();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getPlateScrews();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getFrontPlateMount();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getRegistrationInVehicle();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getRegistrationExpired();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getHovStickersInstalled();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getTiresAndWheelsMatch();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getTreadDepthRightFront();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getTreadDepthLeftFront();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getTreadDepthRightRear();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getTreadDepthLeftRear();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getPersonalItemsRemoved();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getVehicleSystemReset();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getSignaturePhoto();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.fleet_service.SignaturePhoto.serializeBinaryToWriter
    );
  }
  f = message.getSignedCustomerId();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.fleet_service.InspectionReport.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.fleet_service.InspectionReport.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string dealership_id = 3;
 * @return {string}
 */
proto.fleet_service.InspectionReport.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string dealer_user_id = 4;
 * @return {string}
 */
proto.fleet_service.InspectionReport.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 key_count = 5;
 * @return {number}
 */
proto.fleet_service.InspectionReport.prototype.getKeyCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setKeyCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional MatsStatus mats_status = 6;
 * @return {!proto.fleet_service.MatsStatus}
 */
proto.fleet_service.InspectionReport.prototype.getMatsStatus = function() {
  return /** @type {!proto.fleet_service.MatsStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.fleet_service.MatsStatus} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setMatsStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int32 charge_level = 7;
 * @return {number}
 */
proto.fleet_service.InspectionReport.prototype.getChargeLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setChargeLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool charging_cable = 8;
 * @return {boolean}
 */
proto.fleet_service.InspectionReport.prototype.getChargingCable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setChargingCable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool plug_adapter_120v = 9;
 * @return {boolean}
 */
proto.fleet_service.InspectionReport.prototype.getPlugAdapter120v = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setPlugAdapter120v = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool plug_adapter_240v = 10;
 * @return {boolean}
 */
proto.fleet_service.InspectionReport.prototype.getPlugAdapter240v = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setPlugAdapter240v = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool sae_charging_adapter = 11;
 * @return {boolean}
 */
proto.fleet_service.InspectionReport.prototype.getSaeChargingAdapter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setSaeChargingAdapter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool ccs_charging_adapter = 12;
 * @return {boolean}
 */
proto.fleet_service.InspectionReport.prototype.getCcsChargingAdapter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setCcsChargingAdapter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool front_plate = 13;
 * @return {boolean}
 */
proto.fleet_service.InspectionReport.prototype.getFrontPlate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setFrontPlate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool rear_plate = 14;
 * @return {boolean}
 */
proto.fleet_service.InspectionReport.prototype.getRearPlate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setRearPlate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool plate_screws = 15;
 * @return {boolean}
 */
proto.fleet_service.InspectionReport.prototype.getPlateScrews = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setPlateScrews = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool front_plate_mount = 16;
 * @return {boolean}
 */
proto.fleet_service.InspectionReport.prototype.getFrontPlateMount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setFrontPlateMount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool registration_in_vehicle = 17;
 * @return {boolean}
 */
proto.fleet_service.InspectionReport.prototype.getRegistrationInVehicle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setRegistrationInVehicle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool registration_expired = 18;
 * @return {boolean}
 */
proto.fleet_service.InspectionReport.prototype.getRegistrationExpired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setRegistrationExpired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool hov_stickers_installed = 19;
 * @return {boolean}
 */
proto.fleet_service.InspectionReport.prototype.getHovStickersInstalled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setHovStickersInstalled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool tires_and_wheels_match = 20;
 * @return {boolean}
 */
proto.fleet_service.InspectionReport.prototype.getTiresAndWheelsMatch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setTiresAndWheelsMatch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional int32 tread_depth_right_front = 21;
 * @return {number}
 */
proto.fleet_service.InspectionReport.prototype.getTreadDepthRightFront = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setTreadDepthRightFront = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional int32 tread_depth_left_front = 22;
 * @return {number}
 */
proto.fleet_service.InspectionReport.prototype.getTreadDepthLeftFront = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setTreadDepthLeftFront = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional int32 tread_depth_right_rear = 23;
 * @return {number}
 */
proto.fleet_service.InspectionReport.prototype.getTreadDepthRightRear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setTreadDepthRightRear = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int32 tread_depth_left_rear = 24;
 * @return {number}
 */
proto.fleet_service.InspectionReport.prototype.getTreadDepthLeftRear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setTreadDepthLeftRear = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional bool personal_items_removed = 25;
 * @return {boolean}
 */
proto.fleet_service.InspectionReport.prototype.getPersonalItemsRemoved = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setPersonalItemsRemoved = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional bool vehicle_system_reset = 26;
 * @return {boolean}
 */
proto.fleet_service.InspectionReport.prototype.getVehicleSystemReset = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setVehicleSystemReset = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional SignaturePhoto signature_photo = 27;
 * @return {?proto.fleet_service.SignaturePhoto}
 */
proto.fleet_service.InspectionReport.prototype.getSignaturePhoto = function() {
  return /** @type{?proto.fleet_service.SignaturePhoto} */ (
    jspb.Message.getWrapperField(this, proto.fleet_service.SignaturePhoto, 27));
};


/**
 * @param {?proto.fleet_service.SignaturePhoto|undefined} value
 * @return {!proto.fleet_service.InspectionReport} returns this
*/
proto.fleet_service.InspectionReport.prototype.setSignaturePhoto = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.clearSignaturePhoto = function() {
  return this.setSignaturePhoto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.InspectionReport.prototype.hasSignaturePhoto = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional string signed_customer_id = 28;
 * @return {string}
 */
proto.fleet_service.InspectionReport.prototype.getSignedCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.InspectionReport} returns this
 */
proto.fleet_service.InspectionReport.prototype.setSignedCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.CreateInspectionReportRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.CreateInspectionReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.CreateInspectionReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.CreateInspectionReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.CreateInspectionReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    report: (f = msg.getReport()) && proto.fleet_service.InspectionReport.toObject(includeInstance, f),
    fleetPhotosList: jspb.Message.toObjectList(msg.getFleetPhotosList(),
    proto.fleet_service.InspectionPhoto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.CreateInspectionReportRequest}
 */
proto.fleet_service.CreateInspectionReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.CreateInspectionReportRequest;
  return proto.fleet_service.CreateInspectionReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.CreateInspectionReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.CreateInspectionReportRequest}
 */
proto.fleet_service.CreateInspectionReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fleet_service.InspectionReport;
      reader.readMessage(value,proto.fleet_service.InspectionReport.deserializeBinaryFromReader);
      msg.setReport(value);
      break;
    case 2:
      var value = new proto.fleet_service.InspectionPhoto;
      reader.readMessage(value,proto.fleet_service.InspectionPhoto.deserializeBinaryFromReader);
      msg.addFleetPhotos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.CreateInspectionReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.CreateInspectionReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.CreateInspectionReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.CreateInspectionReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReport();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fleet_service.InspectionReport.serializeBinaryToWriter
    );
  }
  f = message.getFleetPhotosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.fleet_service.InspectionPhoto.serializeBinaryToWriter
    );
  }
};


/**
 * optional InspectionReport report = 1;
 * @return {?proto.fleet_service.InspectionReport}
 */
proto.fleet_service.CreateInspectionReportRequest.prototype.getReport = function() {
  return /** @type{?proto.fleet_service.InspectionReport} */ (
    jspb.Message.getWrapperField(this, proto.fleet_service.InspectionReport, 1));
};


/**
 * @param {?proto.fleet_service.InspectionReport|undefined} value
 * @return {!proto.fleet_service.CreateInspectionReportRequest} returns this
*/
proto.fleet_service.CreateInspectionReportRequest.prototype.setReport = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.CreateInspectionReportRequest} returns this
 */
proto.fleet_service.CreateInspectionReportRequest.prototype.clearReport = function() {
  return this.setReport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.CreateInspectionReportRequest.prototype.hasReport = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated InspectionPhoto fleet_photos = 2;
 * @return {!Array<!proto.fleet_service.InspectionPhoto>}
 */
proto.fleet_service.CreateInspectionReportRequest.prototype.getFleetPhotosList = function() {
  return /** @type{!Array<!proto.fleet_service.InspectionPhoto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fleet_service.InspectionPhoto, 2));
};


/**
 * @param {!Array<!proto.fleet_service.InspectionPhoto>} value
 * @return {!proto.fleet_service.CreateInspectionReportRequest} returns this
*/
proto.fleet_service.CreateInspectionReportRequest.prototype.setFleetPhotosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fleet_service.InspectionPhoto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.InspectionPhoto}
 */
proto.fleet_service.CreateInspectionReportRequest.prototype.addFleetPhotos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fleet_service.InspectionPhoto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.CreateInspectionReportRequest} returns this
 */
proto.fleet_service.CreateInspectionReportRequest.prototype.clearFleetPhotosList = function() {
  return this.setFleetPhotosList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.CreateInspectionReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.CreateInspectionReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.CreateInspectionReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.CreateInspectionReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.CreateInspectionReportResponse}
 */
proto.fleet_service.CreateInspectionReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.CreateInspectionReportResponse;
  return proto.fleet_service.CreateInspectionReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.CreateInspectionReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.CreateInspectionReportResponse}
 */
proto.fleet_service.CreateInspectionReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.CreateInspectionReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.CreateInspectionReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.CreateInspectionReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.CreateInspectionReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.fleet_service.CreateInspectionReportResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.CreateInspectionReportResponse} returns this
 */
proto.fleet_service.CreateInspectionReportResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.SignInspectionReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.SignInspectionReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.SignInspectionReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.SignInspectionReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    inspectionReportId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    signaturePhoto: (f = msg.getSignaturePhoto()) && proto.fleet_service.SignaturePhoto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.SignInspectionReportRequest}
 */
proto.fleet_service.SignInspectionReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.SignInspectionReportRequest;
  return proto.fleet_service.SignInspectionReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.SignInspectionReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.SignInspectionReportRequest}
 */
proto.fleet_service.SignInspectionReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInspectionReportId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = new proto.fleet_service.SignaturePhoto;
      reader.readMessage(value,proto.fleet_service.SignaturePhoto.deserializeBinaryFromReader);
      msg.setSignaturePhoto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.SignInspectionReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.SignInspectionReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.SignInspectionReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.SignInspectionReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInspectionReportId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSignaturePhoto();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fleet_service.SignaturePhoto.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 inspection_report_id = 1;
 * @return {number}
 */
proto.fleet_service.SignInspectionReportRequest.prototype.getInspectionReportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.SignInspectionReportRequest} returns this
 */
proto.fleet_service.SignInspectionReportRequest.prototype.setInspectionReportId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.fleet_service.SignInspectionReportRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.SignInspectionReportRequest} returns this
 */
proto.fleet_service.SignInspectionReportRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional SignaturePhoto signature_photo = 3;
 * @return {?proto.fleet_service.SignaturePhoto}
 */
proto.fleet_service.SignInspectionReportRequest.prototype.getSignaturePhoto = function() {
  return /** @type{?proto.fleet_service.SignaturePhoto} */ (
    jspb.Message.getWrapperField(this, proto.fleet_service.SignaturePhoto, 3));
};


/**
 * @param {?proto.fleet_service.SignaturePhoto|undefined} value
 * @return {!proto.fleet_service.SignInspectionReportRequest} returns this
*/
proto.fleet_service.SignInspectionReportRequest.prototype.setSignaturePhoto = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.SignInspectionReportRequest} returns this
 */
proto.fleet_service.SignInspectionReportRequest.prototype.clearSignaturePhoto = function() {
  return this.setSignaturePhoto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.SignInspectionReportRequest.prototype.hasSignaturePhoto = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.SignInspectionReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.SignInspectionReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.SignInspectionReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.SignInspectionReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.SignInspectionReportResponse}
 */
proto.fleet_service.SignInspectionReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.SignInspectionReportResponse;
  return proto.fleet_service.SignInspectionReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.SignInspectionReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.SignInspectionReportResponse}
 */
proto.fleet_service.SignInspectionReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.SignInspectionReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.SignInspectionReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.SignInspectionReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.SignInspectionReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.AddInspectionPhotosRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.AddInspectionPhotosRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.AddInspectionPhotosRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.AddInspectionPhotosRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.AddInspectionPhotosRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    inspectionReportId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    customerPhotosList: jspb.Message.toObjectList(msg.getCustomerPhotosList(),
    proto.fleet_service.InspectionPhoto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.AddInspectionPhotosRequest}
 */
proto.fleet_service.AddInspectionPhotosRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.AddInspectionPhotosRequest;
  return proto.fleet_service.AddInspectionPhotosRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.AddInspectionPhotosRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.AddInspectionPhotosRequest}
 */
proto.fleet_service.AddInspectionPhotosRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInspectionReportId(value);
      break;
    case 2:
      var value = new proto.fleet_service.InspectionPhoto;
      reader.readMessage(value,proto.fleet_service.InspectionPhoto.deserializeBinaryFromReader);
      msg.addCustomerPhotos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.AddInspectionPhotosRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.AddInspectionPhotosRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.AddInspectionPhotosRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.AddInspectionPhotosRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInspectionReportId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCustomerPhotosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.fleet_service.InspectionPhoto.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 inspection_report_id = 1;
 * @return {number}
 */
proto.fleet_service.AddInspectionPhotosRequest.prototype.getInspectionReportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.AddInspectionPhotosRequest} returns this
 */
proto.fleet_service.AddInspectionPhotosRequest.prototype.setInspectionReportId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated InspectionPhoto customer_photos = 2;
 * @return {!Array<!proto.fleet_service.InspectionPhoto>}
 */
proto.fleet_service.AddInspectionPhotosRequest.prototype.getCustomerPhotosList = function() {
  return /** @type{!Array<!proto.fleet_service.InspectionPhoto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fleet_service.InspectionPhoto, 2));
};


/**
 * @param {!Array<!proto.fleet_service.InspectionPhoto>} value
 * @return {!proto.fleet_service.AddInspectionPhotosRequest} returns this
*/
proto.fleet_service.AddInspectionPhotosRequest.prototype.setCustomerPhotosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fleet_service.InspectionPhoto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.InspectionPhoto}
 */
proto.fleet_service.AddInspectionPhotosRequest.prototype.addCustomerPhotos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fleet_service.InspectionPhoto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.AddInspectionPhotosRequest} returns this
 */
proto.fleet_service.AddInspectionPhotosRequest.prototype.clearCustomerPhotosList = function() {
  return this.setCustomerPhotosList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.AddInspectionPhotosResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.AddInspectionPhotosResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.AddInspectionPhotosResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.AddInspectionPhotosResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.AddInspectionPhotosResponse}
 */
proto.fleet_service.AddInspectionPhotosResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.AddInspectionPhotosResponse;
  return proto.fleet_service.AddInspectionPhotosResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.AddInspectionPhotosResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.AddInspectionPhotosResponse}
 */
proto.fleet_service.AddInspectionPhotosResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.AddInspectionPhotosResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.AddInspectionPhotosResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.AddInspectionPhotosResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.AddInspectionPhotosResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetInspectionPhotosRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetInspectionPhotosRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetInspectionPhotosRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetInspectionPhotosRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    inspectionReportId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetInspectionPhotosRequest}
 */
proto.fleet_service.GetInspectionPhotosRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetInspectionPhotosRequest;
  return proto.fleet_service.GetInspectionPhotosRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetInspectionPhotosRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetInspectionPhotosRequest}
 */
proto.fleet_service.GetInspectionPhotosRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInspectionReportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetInspectionPhotosRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetInspectionPhotosRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetInspectionPhotosRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetInspectionPhotosRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInspectionReportId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 inspection_report_id = 1;
 * @return {number}
 */
proto.fleet_service.GetInspectionPhotosRequest.prototype.getInspectionReportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.GetInspectionPhotosRequest} returns this
 */
proto.fleet_service.GetInspectionPhotosRequest.prototype.setInspectionReportId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.GetInspectionPhotosResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetInspectionPhotosResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetInspectionPhotosResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetInspectionPhotosResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetInspectionPhotosResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fleetPhotosList: jspb.Message.toObjectList(msg.getFleetPhotosList(),
    proto.fleet_service.InspectionPhoto.toObject, includeInstance),
    customerPhotosList: jspb.Message.toObjectList(msg.getCustomerPhotosList(),
    proto.fleet_service.InspectionPhoto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetInspectionPhotosResponse}
 */
proto.fleet_service.GetInspectionPhotosResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetInspectionPhotosResponse;
  return proto.fleet_service.GetInspectionPhotosResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetInspectionPhotosResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetInspectionPhotosResponse}
 */
proto.fleet_service.GetInspectionPhotosResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fleet_service.InspectionPhoto;
      reader.readMessage(value,proto.fleet_service.InspectionPhoto.deserializeBinaryFromReader);
      msg.addFleetPhotos(value);
      break;
    case 2:
      var value = new proto.fleet_service.InspectionPhoto;
      reader.readMessage(value,proto.fleet_service.InspectionPhoto.deserializeBinaryFromReader);
      msg.addCustomerPhotos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetInspectionPhotosResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetInspectionPhotosResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetInspectionPhotosResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetInspectionPhotosResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFleetPhotosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fleet_service.InspectionPhoto.serializeBinaryToWriter
    );
  }
  f = message.getCustomerPhotosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.fleet_service.InspectionPhoto.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InspectionPhoto fleet_photos = 1;
 * @return {!Array<!proto.fleet_service.InspectionPhoto>}
 */
proto.fleet_service.GetInspectionPhotosResponse.prototype.getFleetPhotosList = function() {
  return /** @type{!Array<!proto.fleet_service.InspectionPhoto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fleet_service.InspectionPhoto, 1));
};


/**
 * @param {!Array<!proto.fleet_service.InspectionPhoto>} value
 * @return {!proto.fleet_service.GetInspectionPhotosResponse} returns this
*/
proto.fleet_service.GetInspectionPhotosResponse.prototype.setFleetPhotosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fleet_service.InspectionPhoto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.InspectionPhoto}
 */
proto.fleet_service.GetInspectionPhotosResponse.prototype.addFleetPhotos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fleet_service.InspectionPhoto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.GetInspectionPhotosResponse} returns this
 */
proto.fleet_service.GetInspectionPhotosResponse.prototype.clearFleetPhotosList = function() {
  return this.setFleetPhotosList([]);
};


/**
 * repeated InspectionPhoto customer_photos = 2;
 * @return {!Array<!proto.fleet_service.InspectionPhoto>}
 */
proto.fleet_service.GetInspectionPhotosResponse.prototype.getCustomerPhotosList = function() {
  return /** @type{!Array<!proto.fleet_service.InspectionPhoto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fleet_service.InspectionPhoto, 2));
};


/**
 * @param {!Array<!proto.fleet_service.InspectionPhoto>} value
 * @return {!proto.fleet_service.GetInspectionPhotosResponse} returns this
*/
proto.fleet_service.GetInspectionPhotosResponse.prototype.setCustomerPhotosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fleet_service.InspectionPhoto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.InspectionPhoto}
 */
proto.fleet_service.GetInspectionPhotosResponse.prototype.addCustomerPhotos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fleet_service.InspectionPhoto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.GetInspectionPhotosResponse} returns this
 */
proto.fleet_service.GetInspectionPhotosResponse.prototype.clearCustomerPhotosList = function() {
  return this.setCustomerPhotosList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetPresignedWritePhotoURLRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetPresignedWritePhotoURLRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetPresignedWritePhotoURLRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetPresignedWritePhotoURLRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    extension: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetPresignedWritePhotoURLRequest}
 */
proto.fleet_service.GetPresignedWritePhotoURLRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetPresignedWritePhotoURLRequest;
  return proto.fleet_service.GetPresignedWritePhotoURLRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetPresignedWritePhotoURLRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetPresignedWritePhotoURLRequest}
 */
proto.fleet_service.GetPresignedWritePhotoURLRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtension$(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetPresignedWritePhotoURLRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetPresignedWritePhotoURLRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetPresignedWritePhotoURLRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetPresignedWritePhotoURLRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExtension$();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.fleet_service.GetPresignedWritePhotoURLRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetPresignedWritePhotoURLRequest} returns this
 */
proto.fleet_service.GetPresignedWritePhotoURLRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.fleet_service.GetPresignedWritePhotoURLRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetPresignedWritePhotoURLRequest} returns this
 */
proto.fleet_service.GetPresignedWritePhotoURLRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.fleet_service.GetPresignedWritePhotoURLRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetPresignedWritePhotoURLRequest} returns this
 */
proto.fleet_service.GetPresignedWritePhotoURLRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string extension = 4;
 * @return {string}
 */
proto.fleet_service.GetPresignedWritePhotoURLRequest.prototype.getExtension$ = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetPresignedWritePhotoURLRequest} returns this
 */
proto.fleet_service.GetPresignedWritePhotoURLRequest.prototype.setExtension$ = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetPresignedWritePhotoURLResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetPresignedWritePhotoURLResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetPresignedWritePhotoURLResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetPresignedWritePhotoURLResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    path: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetPresignedWritePhotoURLResponse}
 */
proto.fleet_service.GetPresignedWritePhotoURLResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetPresignedWritePhotoURLResponse;
  return proto.fleet_service.GetPresignedWritePhotoURLResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetPresignedWritePhotoURLResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetPresignedWritePhotoURLResponse}
 */
proto.fleet_service.GetPresignedWritePhotoURLResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetPresignedWritePhotoURLResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetPresignedWritePhotoURLResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetPresignedWritePhotoURLResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetPresignedWritePhotoURLResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.fleet_service.GetPresignedWritePhotoURLResponse.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetPresignedWritePhotoURLResponse} returns this
 */
proto.fleet_service.GetPresignedWritePhotoURLResponse.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string path = 2;
 * @return {string}
 */
proto.fleet_service.GetPresignedWritePhotoURLResponse.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetPresignedWritePhotoURLResponse} returns this
 */
proto.fleet_service.GetPresignedWritePhotoURLResponse.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetInspectionSignatureWriteURLRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetInspectionSignatureWriteURLRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetInspectionSignatureWriteURLRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetInspectionSignatureWriteURLRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inspectionReportId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    extension: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetInspectionSignatureWriteURLRequest}
 */
proto.fleet_service.GetInspectionSignatureWriteURLRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetInspectionSignatureWriteURLRequest;
  return proto.fleet_service.GetInspectionSignatureWriteURLRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetInspectionSignatureWriteURLRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetInspectionSignatureWriteURLRequest}
 */
proto.fleet_service.GetInspectionSignatureWriteURLRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInspectionReportId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtension$(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetInspectionSignatureWriteURLRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetInspectionSignatureWriteURLRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetInspectionSignatureWriteURLRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetInspectionSignatureWriteURLRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInspectionReportId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExtension$();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.fleet_service.GetInspectionSignatureWriteURLRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetInspectionSignatureWriteURLRequest} returns this
 */
proto.fleet_service.GetInspectionSignatureWriteURLRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 inspection_report_id = 2;
 * @return {number}
 */
proto.fleet_service.GetInspectionSignatureWriteURLRequest.prototype.getInspectionReportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.GetInspectionSignatureWriteURLRequest} returns this
 */
proto.fleet_service.GetInspectionSignatureWriteURLRequest.prototype.setInspectionReportId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.fleet_service.GetInspectionSignatureWriteURLRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetInspectionSignatureWriteURLRequest} returns this
 */
proto.fleet_service.GetInspectionSignatureWriteURLRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string extension = 4;
 * @return {string}
 */
proto.fleet_service.GetInspectionSignatureWriteURLRequest.prototype.getExtension$ = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetInspectionSignatureWriteURLRequest} returns this
 */
proto.fleet_service.GetInspectionSignatureWriteURLRequest.prototype.setExtension$ = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetInspectionSignatureWriteURLResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetInspectionSignatureWriteURLResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetInspectionSignatureWriteURLResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetInspectionSignatureWriteURLResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    path: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetInspectionSignatureWriteURLResponse}
 */
proto.fleet_service.GetInspectionSignatureWriteURLResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetInspectionSignatureWriteURLResponse;
  return proto.fleet_service.GetInspectionSignatureWriteURLResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetInspectionSignatureWriteURLResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetInspectionSignatureWriteURLResponse}
 */
proto.fleet_service.GetInspectionSignatureWriteURLResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetInspectionSignatureWriteURLResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetInspectionSignatureWriteURLResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetInspectionSignatureWriteURLResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetInspectionSignatureWriteURLResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.fleet_service.GetInspectionSignatureWriteURLResponse.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetInspectionSignatureWriteURLResponse} returns this
 */
proto.fleet_service.GetInspectionSignatureWriteURLResponse.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string path = 2;
 * @return {string}
 */
proto.fleet_service.GetInspectionSignatureWriteURLResponse.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetInspectionSignatureWriteURLResponse} returns this
 */
proto.fleet_service.GetInspectionSignatureWriteURLResponse.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetSubscriptionMileageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetSubscriptionMileageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetSubscriptionMileageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetSubscriptionMileageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetSubscriptionMileageRequest}
 */
proto.fleet_service.GetSubscriptionMileageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetSubscriptionMileageRequest;
  return proto.fleet_service.GetSubscriptionMileageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetSubscriptionMileageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetSubscriptionMileageRequest}
 */
proto.fleet_service.GetSubscriptionMileageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetSubscriptionMileageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetSubscriptionMileageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetSubscriptionMileageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetSubscriptionMileageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.fleet_service.GetSubscriptionMileageRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetSubscriptionMileageRequest} returns this
 */
proto.fleet_service.GetSubscriptionMileageRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.MileageDrivenInMonth.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.MileageDrivenInMonth.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.MileageDrivenInMonth} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.MileageDrivenInMonth.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.MileageDrivenInMonth}
 */
proto.fleet_service.MileageDrivenInMonth.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.MileageDrivenInMonth;
  return proto.fleet_service.MileageDrivenInMonth.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.MileageDrivenInMonth} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.MileageDrivenInMonth}
 */
proto.fleet_service.MileageDrivenInMonth.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.MileageDrivenInMonth.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.MileageDrivenInMonth.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.MileageDrivenInMonth} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.MileageDrivenInMonth.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.fleet_service.MileageDrivenInMonth.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.MileageDrivenInMonth} returns this
 */
proto.fleet_service.MileageDrivenInMonth.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 amount = 2;
 * @return {number}
 */
proto.fleet_service.MileageDrivenInMonth.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.MileageDrivenInMonth} returns this
 */
proto.fleet_service.MileageDrivenInMonth.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.PrepaidExtraMileage.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.PrepaidExtraMileage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.PrepaidExtraMileage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.PrepaidExtraMileage.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalAmount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalPrice: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.PrepaidExtraMileage}
 */
proto.fleet_service.PrepaidExtraMileage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.PrepaidExtraMileage;
  return proto.fleet_service.PrepaidExtraMileage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.PrepaidExtraMileage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.PrepaidExtraMileage}
 */
proto.fleet_service.PrepaidExtraMileage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalAmount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.PrepaidExtraMileage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.PrepaidExtraMileage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.PrepaidExtraMileage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.PrepaidExtraMileage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalAmount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotalPrice();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 total_amount = 1;
 * @return {number}
 */
proto.fleet_service.PrepaidExtraMileage.prototype.getTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.PrepaidExtraMileage} returns this
 */
proto.fleet_service.PrepaidExtraMileage.prototype.setTotalAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 total_price = 2;
 * @return {number}
 */
proto.fleet_service.PrepaidExtraMileage.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.PrepaidExtraMileage} returns this
 */
proto.fleet_service.PrepaidExtraMileage.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.GetSubscriptionMileageResponse.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetSubscriptionMileageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetSubscriptionMileageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetSubscriptionMileageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetSubscriptionMileageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalAllotted: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalDriven: jspb.Message.getFieldWithDefault(msg, 2, 0),
    allottedPerMonth: jspb.Message.getFieldWithDefault(msg, 3, 0),
    drivenPerMonth: jspb.Message.getFieldWithDefault(msg, 4, 0),
    startingOdometer: jspb.Message.getFieldWithDefault(msg, 5, 0),
    lastOdometer: jspb.Message.getFieldWithDefault(msg, 6, 0),
    drivenByMonthList: jspb.Message.toObjectList(msg.getDrivenByMonthList(),
    proto.fleet_service.MileageDrivenInMonth.toObject, includeInstance),
    prepaidExtra: (f = msg.getPrepaidExtra()) && proto.fleet_service.PrepaidExtraMileage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetSubscriptionMileageResponse}
 */
proto.fleet_service.GetSubscriptionMileageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetSubscriptionMileageResponse;
  return proto.fleet_service.GetSubscriptionMileageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetSubscriptionMileageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetSubscriptionMileageResponse}
 */
proto.fleet_service.GetSubscriptionMileageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalAllotted(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalDriven(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAllottedPerMonth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDrivenPerMonth(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartingOdometer(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastOdometer(value);
      break;
    case 7:
      var value = new proto.fleet_service.MileageDrivenInMonth;
      reader.readMessage(value,proto.fleet_service.MileageDrivenInMonth.deserializeBinaryFromReader);
      msg.addDrivenByMonth(value);
      break;
    case 8:
      var value = new proto.fleet_service.PrepaidExtraMileage;
      reader.readMessage(value,proto.fleet_service.PrepaidExtraMileage.deserializeBinaryFromReader);
      msg.setPrepaidExtra(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetSubscriptionMileageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetSubscriptionMileageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetSubscriptionMileageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetSubscriptionMileageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalAllotted();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotalDriven();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAllottedPerMonth();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDrivenPerMonth();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getStartingOdometer();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLastOdometer();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getDrivenByMonthList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.fleet_service.MileageDrivenInMonth.serializeBinaryToWriter
    );
  }
  f = message.getPrepaidExtra();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.fleet_service.PrepaidExtraMileage.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 total_allotted = 1;
 * @return {number}
 */
proto.fleet_service.GetSubscriptionMileageResponse.prototype.getTotalAllotted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.GetSubscriptionMileageResponse} returns this
 */
proto.fleet_service.GetSubscriptionMileageResponse.prototype.setTotalAllotted = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 total_driven = 2;
 * @return {number}
 */
proto.fleet_service.GetSubscriptionMileageResponse.prototype.getTotalDriven = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.GetSubscriptionMileageResponse} returns this
 */
proto.fleet_service.GetSubscriptionMileageResponse.prototype.setTotalDriven = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 allotted_per_month = 3;
 * @return {number}
 */
proto.fleet_service.GetSubscriptionMileageResponse.prototype.getAllottedPerMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.GetSubscriptionMileageResponse} returns this
 */
proto.fleet_service.GetSubscriptionMileageResponse.prototype.setAllottedPerMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 driven_per_month = 4;
 * @return {number}
 */
proto.fleet_service.GetSubscriptionMileageResponse.prototype.getDrivenPerMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.GetSubscriptionMileageResponse} returns this
 */
proto.fleet_service.GetSubscriptionMileageResponse.prototype.setDrivenPerMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 starting_odometer = 5;
 * @return {number}
 */
proto.fleet_service.GetSubscriptionMileageResponse.prototype.getStartingOdometer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.GetSubscriptionMileageResponse} returns this
 */
proto.fleet_service.GetSubscriptionMileageResponse.prototype.setStartingOdometer = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 last_odometer = 6;
 * @return {number}
 */
proto.fleet_service.GetSubscriptionMileageResponse.prototype.getLastOdometer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.GetSubscriptionMileageResponse} returns this
 */
proto.fleet_service.GetSubscriptionMileageResponse.prototype.setLastOdometer = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated MileageDrivenInMonth driven_by_month = 7;
 * @return {!Array<!proto.fleet_service.MileageDrivenInMonth>}
 */
proto.fleet_service.GetSubscriptionMileageResponse.prototype.getDrivenByMonthList = function() {
  return /** @type{!Array<!proto.fleet_service.MileageDrivenInMonth>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fleet_service.MileageDrivenInMonth, 7));
};


/**
 * @param {!Array<!proto.fleet_service.MileageDrivenInMonth>} value
 * @return {!proto.fleet_service.GetSubscriptionMileageResponse} returns this
*/
proto.fleet_service.GetSubscriptionMileageResponse.prototype.setDrivenByMonthList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.fleet_service.MileageDrivenInMonth=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.MileageDrivenInMonth}
 */
proto.fleet_service.GetSubscriptionMileageResponse.prototype.addDrivenByMonth = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.fleet_service.MileageDrivenInMonth, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.GetSubscriptionMileageResponse} returns this
 */
proto.fleet_service.GetSubscriptionMileageResponse.prototype.clearDrivenByMonthList = function() {
  return this.setDrivenByMonthList([]);
};


/**
 * optional PrepaidExtraMileage prepaid_extra = 8;
 * @return {?proto.fleet_service.PrepaidExtraMileage}
 */
proto.fleet_service.GetSubscriptionMileageResponse.prototype.getPrepaidExtra = function() {
  return /** @type{?proto.fleet_service.PrepaidExtraMileage} */ (
    jspb.Message.getWrapperField(this, proto.fleet_service.PrepaidExtraMileage, 8));
};


/**
 * @param {?proto.fleet_service.PrepaidExtraMileage|undefined} value
 * @return {!proto.fleet_service.GetSubscriptionMileageResponse} returns this
*/
proto.fleet_service.GetSubscriptionMileageResponse.prototype.setPrepaidExtra = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.GetSubscriptionMileageResponse} returns this
 */
proto.fleet_service.GetSubscriptionMileageResponse.prototype.clearPrepaidExtra = function() {
  return this.setPrepaidExtra(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.GetSubscriptionMileageResponse.prototype.hasPrepaidExtra = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetSubscriptionMileagePurchasesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetSubscriptionMileagePurchasesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetSubscriptionMileagePurchasesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetSubscriptionMileagePurchasesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetSubscriptionMileagePurchasesRequest}
 */
proto.fleet_service.GetSubscriptionMileagePurchasesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetSubscriptionMileagePurchasesRequest;
  return proto.fleet_service.GetSubscriptionMileagePurchasesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetSubscriptionMileagePurchasesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetSubscriptionMileagePurchasesRequest}
 */
proto.fleet_service.GetSubscriptionMileagePurchasesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetSubscriptionMileagePurchasesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetSubscriptionMileagePurchasesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetSubscriptionMileagePurchasesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetSubscriptionMileagePurchasesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.fleet_service.GetSubscriptionMileagePurchasesRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetSubscriptionMileagePurchasesRequest} returns this
 */
proto.fleet_service.GetSubscriptionMileagePurchasesRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.MileagePurchase.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.MileagePurchase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.MileagePurchase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.MileagePurchase.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    price: jspb.Message.getFieldWithDefault(msg, 3, 0),
    time: jspb.Message.getFieldWithDefault(msg, 4, 0),
    referenceId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.MileagePurchase}
 */
proto.fleet_service.MileagePurchase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.MileagePurchase;
  return proto.fleet_service.MileagePurchase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.MileagePurchase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.MileagePurchase}
 */
proto.fleet_service.MileagePurchase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.MileagePurchase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.MileagePurchase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.MileagePurchase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.MileagePurchase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getReferenceId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.fleet_service.MileagePurchase.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.MileagePurchase} returns this
 */
proto.fleet_service.MileagePurchase.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 amount = 2;
 * @return {number}
 */
proto.fleet_service.MileagePurchase.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.MileagePurchase} returns this
 */
proto.fleet_service.MileagePurchase.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 price = 3;
 * @return {number}
 */
proto.fleet_service.MileagePurchase.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.MileagePurchase} returns this
 */
proto.fleet_service.MileagePurchase.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 time = 4;
 * @return {number}
 */
proto.fleet_service.MileagePurchase.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.MileagePurchase} returns this
 */
proto.fleet_service.MileagePurchase.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string reference_id = 5;
 * @return {string}
 */
proto.fleet_service.MileagePurchase.prototype.getReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.MileagePurchase} returns this
 */
proto.fleet_service.MileagePurchase.prototype.setReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.GetSubscriptionMileagePurchasesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetSubscriptionMileagePurchasesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetSubscriptionMileagePurchasesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetSubscriptionMileagePurchasesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetSubscriptionMileagePurchasesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    purchasesList: jspb.Message.toObjectList(msg.getPurchasesList(),
    proto.fleet_service.MileagePurchase.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetSubscriptionMileagePurchasesResponse}
 */
proto.fleet_service.GetSubscriptionMileagePurchasesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetSubscriptionMileagePurchasesResponse;
  return proto.fleet_service.GetSubscriptionMileagePurchasesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetSubscriptionMileagePurchasesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetSubscriptionMileagePurchasesResponse}
 */
proto.fleet_service.GetSubscriptionMileagePurchasesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fleet_service.MileagePurchase;
      reader.readMessage(value,proto.fleet_service.MileagePurchase.deserializeBinaryFromReader);
      msg.addPurchases(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetSubscriptionMileagePurchasesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetSubscriptionMileagePurchasesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetSubscriptionMileagePurchasesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetSubscriptionMileagePurchasesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurchasesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fleet_service.MileagePurchase.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MileagePurchase purchases = 1;
 * @return {!Array<!proto.fleet_service.MileagePurchase>}
 */
proto.fleet_service.GetSubscriptionMileagePurchasesResponse.prototype.getPurchasesList = function() {
  return /** @type{!Array<!proto.fleet_service.MileagePurchase>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fleet_service.MileagePurchase, 1));
};


/**
 * @param {!Array<!proto.fleet_service.MileagePurchase>} value
 * @return {!proto.fleet_service.GetSubscriptionMileagePurchasesResponse} returns this
*/
proto.fleet_service.GetSubscriptionMileagePurchasesResponse.prototype.setPurchasesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fleet_service.MileagePurchase=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.MileagePurchase}
 */
proto.fleet_service.GetSubscriptionMileagePurchasesResponse.prototype.addPurchases = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fleet_service.MileagePurchase, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.GetSubscriptionMileagePurchasesResponse} returns this
 */
proto.fleet_service.GetSubscriptionMileagePurchasesResponse.prototype.clearPurchasesList = function() {
  return this.setPurchasesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetPrePaidMileageRateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetPrePaidMileageRateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetPrePaidMileageRateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetPrePaidMileageRateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetPrePaidMileageRateRequest}
 */
proto.fleet_service.GetPrePaidMileageRateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetPrePaidMileageRateRequest;
  return proto.fleet_service.GetPrePaidMileageRateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetPrePaidMileageRateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetPrePaidMileageRateRequest}
 */
proto.fleet_service.GetPrePaidMileageRateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetPrePaidMileageRateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetPrePaidMileageRateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetPrePaidMileageRateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetPrePaidMileageRateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.fleet_service.GetPrePaidMileageRateRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetPrePaidMileageRateRequest} returns this
 */
proto.fleet_service.GetPrePaidMileageRateRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetPrePaidMileageRateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetPrePaidMileageRateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetPrePaidMileageRateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetPrePaidMileageRateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    amountPerMile: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetPrePaidMileageRateResponse}
 */
proto.fleet_service.GetPrePaidMileageRateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetPrePaidMileageRateResponse;
  return proto.fleet_service.GetPrePaidMileageRateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetPrePaidMileageRateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetPrePaidMileageRateResponse}
 */
proto.fleet_service.GetPrePaidMileageRateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmountPerMile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetPrePaidMileageRateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetPrePaidMileageRateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetPrePaidMileageRateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetPrePaidMileageRateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmountPerMile();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 amount_per_mile = 1;
 * @return {number}
 */
proto.fleet_service.GetPrePaidMileageRateResponse.prototype.getAmountPerMile = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.GetPrePaidMileageRateResponse} returns this
 */
proto.fleet_service.GetPrePaidMileageRateResponse.prototype.setAmountPerMile = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetMileageRatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetMileageRatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetMileageRatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetMileageRatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetMileageRatesRequest}
 */
proto.fleet_service.GetMileageRatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetMileageRatesRequest;
  return proto.fleet_service.GetMileageRatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetMileageRatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetMileageRatesRequest}
 */
proto.fleet_service.GetMileageRatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetMileageRatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetMileageRatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetMileageRatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetMileageRatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.fleet_service.GetMileageRatesRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetMileageRatesRequest} returns this
 */
proto.fleet_service.GetMileageRatesRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetMileageRatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetMileageRatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetMileageRatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetMileageRatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    prePaidRate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    punitiveRate: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetMileageRatesResponse}
 */
proto.fleet_service.GetMileageRatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetMileageRatesResponse;
  return proto.fleet_service.GetMileageRatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetMileageRatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetMileageRatesResponse}
 */
proto.fleet_service.GetMileageRatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrePaidRate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPunitiveRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetMileageRatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetMileageRatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetMileageRatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetMileageRatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrePaidRate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPunitiveRate();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 pre_paid_rate = 1;
 * @return {number}
 */
proto.fleet_service.GetMileageRatesResponse.prototype.getPrePaidRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.GetMileageRatesResponse} returns this
 */
proto.fleet_service.GetMileageRatesResponse.prototype.setPrePaidRate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 punitive_rate = 2;
 * @return {number}
 */
proto.fleet_service.GetMileageRatesResponse.prototype.getPunitiveRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.GetMileageRatesResponse} returns this
 */
proto.fleet_service.GetMileageRatesResponse.prototype.setPunitiveRate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.StatusGroup.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.StatusGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.StatusGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.StatusGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.StatusGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    vehiclesList: jspb.Message.toObjectList(msg.getVehiclesList(),
    vehicle_pb.Vehicle.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.StatusGroup}
 */
proto.fleet_service.StatusGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.StatusGroup;
  return proto.fleet_service.StatusGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.StatusGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.StatusGroup}
 */
proto.fleet_service.StatusGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fleet_service.StatusGroup.GroupStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 3:
      var value = new vehicle_pb.Vehicle;
      reader.readMessage(value,vehicle_pb.Vehicle.deserializeBinaryFromReader);
      msg.addVehicles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.StatusGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.StatusGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.StatusGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.StatusGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getVehiclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      vehicle_pb.Vehicle.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.fleet_service.StatusGroup.GroupStatus = {
  GROUP_STATUS_UNSPECIFIED: 0,
  GROUP_STATUS_IN_PENDING: 1,
  GROUP_STATUS_READY: 2,
  GROUP_STATUS_SCHEDULED: 3,
  GROUP_STATUS_COMPLETED: 4,
  GROUP_STATUS_OUT_OF_SERVICE: 5
};

/**
 * optional GroupStatus status = 1;
 * @return {!proto.fleet_service.StatusGroup.GroupStatus}
 */
proto.fleet_service.StatusGroup.prototype.getStatus = function() {
  return /** @type {!proto.fleet_service.StatusGroup.GroupStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fleet_service.StatusGroup.GroupStatus} value
 * @return {!proto.fleet_service.StatusGroup} returns this
 */
proto.fleet_service.StatusGroup.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.fleet_service.StatusGroup.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.StatusGroup} returns this
 */
proto.fleet_service.StatusGroup.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated vehicle.Vehicle vehicles = 3;
 * @return {!Array<!proto.vehicle.Vehicle>}
 */
proto.fleet_service.StatusGroup.prototype.getVehiclesList = function() {
  return /** @type{!Array<!proto.vehicle.Vehicle>} */ (
    jspb.Message.getRepeatedWrapperField(this, vehicle_pb.Vehicle, 3));
};


/**
 * @param {!Array<!proto.vehicle.Vehicle>} value
 * @return {!proto.fleet_service.StatusGroup} returns this
*/
proto.fleet_service.StatusGroup.prototype.setVehiclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vehicle.Vehicle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Vehicle}
 */
proto.fleet_service.StatusGroup.prototype.addVehicles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vehicle.Vehicle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.StatusGroup} returns this
 */
proto.fleet_service.StatusGroup.prototype.clearVehiclesList = function() {
  return this.setVehiclesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.AssignVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.AssignVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.AssignVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.AssignVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.AssignVehicleRequest}
 */
proto.fleet_service.AssignVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.AssignVehicleRequest;
  return proto.fleet_service.AssignVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.AssignVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.AssignVehicleRequest}
 */
proto.fleet_service.AssignVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.AssignVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.AssignVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.AssignVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.AssignVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.fleet_service.AssignVehicleRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.AssignVehicleRequest} returns this
 */
proto.fleet_service.AssignVehicleRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.fleet_service.AssignVehicleRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.AssignVehicleRequest} returns this
 */
proto.fleet_service.AssignVehicleRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.AssignVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.AssignVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.AssignVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.AssignVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.AssignVehicleResponse}
 */
proto.fleet_service.AssignVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.AssignVehicleResponse;
  return proto.fleet_service.AssignVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.AssignVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.AssignVehicleResponse}
 */
proto.fleet_service.AssignVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.AssignVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.AssignVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.AssignVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.AssignVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.UnassignVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.UnassignVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.UnassignVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UnassignVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.UnassignVehicleRequest}
 */
proto.fleet_service.UnassignVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.UnassignVehicleRequest;
  return proto.fleet_service.UnassignVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.UnassignVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.UnassignVehicleRequest}
 */
proto.fleet_service.UnassignVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.UnassignVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.UnassignVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.UnassignVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UnassignVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.fleet_service.UnassignVehicleRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.UnassignVehicleRequest} returns this
 */
proto.fleet_service.UnassignVehicleRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.fleet_service.UnassignVehicleRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.UnassignVehicleRequest} returns this
 */
proto.fleet_service.UnassignVehicleRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.UnassignVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.UnassignVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.UnassignVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UnassignVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.UnassignVehicleResponse}
 */
proto.fleet_service.UnassignVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.UnassignVehicleResponse;
  return proto.fleet_service.UnassignVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.UnassignVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.UnassignVehicleResponse}
 */
proto.fleet_service.UnassignVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.UnassignVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.UnassignVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.UnassignVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UnassignVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ListOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ListOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ListOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    startDate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    endDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pageNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
    orderState: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ListOrdersRequest}
 */
proto.fleet_service.ListOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ListOrdersRequest;
  return proto.fleet_service.ListOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ListOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ListOrdersRequest}
 */
proto.fleet_service.ListOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageNumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 5:
      var value = /** @type {!proto.fleet_service.ListOrdersRequest.OrderState} */ (reader.readEnum());
      msg.setOrderState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ListOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ListOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ListOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEndDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPageNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getOrderState();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fleet_service.ListOrdersRequest.OrderState = {
  ACCOUNT_CREATED: 0,
  PENDING: 1,
  QUALIFIED: 2,
  VEHICLE: 3,
  REJECTED: 4
};

/**
 * optional string start_date = 1;
 * @return {string}
 */
proto.fleet_service.ListOrdersRequest.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ListOrdersRequest} returns this
 */
proto.fleet_service.ListOrdersRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string end_date = 2;
 * @return {string}
 */
proto.fleet_service.ListOrdersRequest.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ListOrdersRequest} returns this
 */
proto.fleet_service.ListOrdersRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 page_number = 3;
 * @return {number}
 */
proto.fleet_service.ListOrdersRequest.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.ListOrdersRequest} returns this
 */
proto.fleet_service.ListOrdersRequest.prototype.setPageNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 page_size = 4;
 * @return {number}
 */
proto.fleet_service.ListOrdersRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.ListOrdersRequest} returns this
 */
proto.fleet_service.ListOrdersRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional OrderState order_state = 5;
 * @return {!proto.fleet_service.ListOrdersRequest.OrderState}
 */
proto.fleet_service.ListOrdersRequest.prototype.getOrderState = function() {
  return /** @type {!proto.fleet_service.ListOrdersRequest.OrderState} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.fleet_service.ListOrdersRequest.OrderState} value
 * @return {!proto.fleet_service.ListOrdersRequest} returns this
 */
proto.fleet_service.ListOrdersRequest.prototype.setOrderState = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.ListOrdersResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ListOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ListOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ListOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalOrders: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vehicleOrdersList: jspb.Message.toObjectList(msg.getVehicleOrdersList(),
    proto.fleet_service.VehicleOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ListOrdersResponse}
 */
proto.fleet_service.ListOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ListOrdersResponse;
  return proto.fleet_service.ListOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ListOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ListOrdersResponse}
 */
proto.fleet_service.ListOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalOrders(value);
      break;
    case 2:
      var value = new proto.fleet_service.VehicleOrder;
      reader.readMessage(value,proto.fleet_service.VehicleOrder.deserializeBinaryFromReader);
      msg.addVehicleOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ListOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ListOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ListOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalOrders();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVehicleOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.fleet_service.VehicleOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 total_orders = 1;
 * @return {number}
 */
proto.fleet_service.ListOrdersResponse.prototype.getTotalOrders = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.ListOrdersResponse} returns this
 */
proto.fleet_service.ListOrdersResponse.prototype.setTotalOrders = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated VehicleOrder vehicle_orders = 2;
 * @return {!Array<!proto.fleet_service.VehicleOrder>}
 */
proto.fleet_service.ListOrdersResponse.prototype.getVehicleOrdersList = function() {
  return /** @type{!Array<!proto.fleet_service.VehicleOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fleet_service.VehicleOrder, 2));
};


/**
 * @param {!Array<!proto.fleet_service.VehicleOrder>} value
 * @return {!proto.fleet_service.ListOrdersResponse} returns this
*/
proto.fleet_service.ListOrdersResponse.prototype.setVehicleOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fleet_service.VehicleOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.VehicleOrder}
 */
proto.fleet_service.ListOrdersResponse.prototype.addVehicleOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fleet_service.VehicleOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.ListOrdersResponse} returns this
 */
proto.fleet_service.ListOrdersResponse.prototype.clearVehicleOrdersList = function() {
  return this.setVehicleOrdersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.VehicleOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.VehicleOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.VehicleOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.VehicleOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    pickupDate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    depositPaidDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subscriptionStatus: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dealerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dealerName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    customerName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    customerAccountNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    vehicleModelAndTrim: jspb.Message.getFieldWithDefault(msg, 9, ""),
    vehicleMiles: jspb.Message.getFieldWithDefault(msg, 10, ""),
    vehicleColor: jspb.Message.getFieldWithDefault(msg, 11, ""),
    dateListed: jspb.Message.getFieldWithDefault(msg, 12, ""),
    vehicleVin: jspb.Message.getFieldWithDefault(msg, 13, ""),
    securityDeposit: jspb.Message.getFieldWithDefault(msg, 14, 0),
    startPayment: jspb.Message.getFieldWithDefault(msg, 15, 0),
    monthlyPayment: jspb.Message.getFieldWithDefault(msg, 16, 0),
    reservationDeposit: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.VehicleOrder}
 */
proto.fleet_service.VehicleOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.VehicleOrder;
  return proto.fleet_service.VehicleOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.VehicleOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.VehicleOrder}
 */
proto.fleet_service.VehicleOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPickupDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepositPaidDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerAccountNumber(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleModelAndTrim(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleMiles(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleColor(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateListed(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleVin(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSecurityDeposit(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartPayment(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthlyPayment(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReservationDeposit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.VehicleOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.VehicleOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.VehicleOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.VehicleOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPickupDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDepositPaidDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubscriptionStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDealerName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCustomerName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCustomerAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getVehicleModelAndTrim();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getVehicleMiles();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getVehicleColor();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDateListed();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getVehicleVin();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSecurityDeposit();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getStartPayment();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getMonthlyPayment();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getReservationDeposit();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
};


/**
 * optional string pickup_date = 1;
 * @return {string}
 */
proto.fleet_service.VehicleOrder.prototype.getPickupDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.VehicleOrder} returns this
 */
proto.fleet_service.VehicleOrder.prototype.setPickupDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deposit_paid_date = 2;
 * @return {string}
 */
proto.fleet_service.VehicleOrder.prototype.getDepositPaidDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.VehicleOrder} returns this
 */
proto.fleet_service.VehicleOrder.prototype.setDepositPaidDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subscription_status = 3;
 * @return {string}
 */
proto.fleet_service.VehicleOrder.prototype.getSubscriptionStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.VehicleOrder} returns this
 */
proto.fleet_service.VehicleOrder.prototype.setSubscriptionStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string dealer_id = 4;
 * @return {string}
 */
proto.fleet_service.VehicleOrder.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.VehicleOrder} returns this
 */
proto.fleet_service.VehicleOrder.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string dealer_name = 5;
 * @return {string}
 */
proto.fleet_service.VehicleOrder.prototype.getDealerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.VehicleOrder} returns this
 */
proto.fleet_service.VehicleOrder.prototype.setDealerName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string customer_name = 6;
 * @return {string}
 */
proto.fleet_service.VehicleOrder.prototype.getCustomerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.VehicleOrder} returns this
 */
proto.fleet_service.VehicleOrder.prototype.setCustomerName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string customer_account_number = 7;
 * @return {string}
 */
proto.fleet_service.VehicleOrder.prototype.getCustomerAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.VehicleOrder} returns this
 */
proto.fleet_service.VehicleOrder.prototype.setCustomerAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string subscription_id = 8;
 * @return {string}
 */
proto.fleet_service.VehicleOrder.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.VehicleOrder} returns this
 */
proto.fleet_service.VehicleOrder.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string vehicle_model_and_trim = 9;
 * @return {string}
 */
proto.fleet_service.VehicleOrder.prototype.getVehicleModelAndTrim = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.VehicleOrder} returns this
 */
proto.fleet_service.VehicleOrder.prototype.setVehicleModelAndTrim = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string vehicle_miles = 10;
 * @return {string}
 */
proto.fleet_service.VehicleOrder.prototype.getVehicleMiles = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.VehicleOrder} returns this
 */
proto.fleet_service.VehicleOrder.prototype.setVehicleMiles = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string vehicle_color = 11;
 * @return {string}
 */
proto.fleet_service.VehicleOrder.prototype.getVehicleColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.VehicleOrder} returns this
 */
proto.fleet_service.VehicleOrder.prototype.setVehicleColor = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string date_listed = 12;
 * @return {string}
 */
proto.fleet_service.VehicleOrder.prototype.getDateListed = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.VehicleOrder} returns this
 */
proto.fleet_service.VehicleOrder.prototype.setDateListed = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string vehicle_vin = 13;
 * @return {string}
 */
proto.fleet_service.VehicleOrder.prototype.getVehicleVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.VehicleOrder} returns this
 */
proto.fleet_service.VehicleOrder.prototype.setVehicleVin = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int32 security_deposit = 14;
 * @return {number}
 */
proto.fleet_service.VehicleOrder.prototype.getSecurityDeposit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.VehicleOrder} returns this
 */
proto.fleet_service.VehicleOrder.prototype.setSecurityDeposit = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 start_payment = 15;
 * @return {number}
 */
proto.fleet_service.VehicleOrder.prototype.getStartPayment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.VehicleOrder} returns this
 */
proto.fleet_service.VehicleOrder.prototype.setStartPayment = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 monthly_payment = 16;
 * @return {number}
 */
proto.fleet_service.VehicleOrder.prototype.getMonthlyPayment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.VehicleOrder} returns this
 */
proto.fleet_service.VehicleOrder.prototype.setMonthlyPayment = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 reservation_deposit = 17;
 * @return {number}
 */
proto.fleet_service.VehicleOrder.prototype.getReservationDeposit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.VehicleOrder} returns this
 */
proto.fleet_service.VehicleOrder.prototype.setReservationDeposit = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.AddVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.AddVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.AddVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.AddVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && vehicle_pb.Vehicle.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.AddVehicleRequest}
 */
proto.fleet_service.AddVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.AddVehicleRequest;
  return proto.fleet_service.AddVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.AddVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.AddVehicleRequest}
 */
proto.fleet_service.AddVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vehicle_pb.Vehicle;
      reader.readMessage(value,vehicle_pb.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.AddVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.AddVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.AddVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.AddVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vehicle_pb.Vehicle.serializeBinaryToWriter
    );
  }
};


/**
 * optional vehicle.Vehicle vehicle = 1;
 * @return {?proto.vehicle.Vehicle}
 */
proto.fleet_service.AddVehicleRequest.prototype.getVehicle = function() {
  return /** @type{?proto.vehicle.Vehicle} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Vehicle, 1));
};


/**
 * @param {?proto.vehicle.Vehicle|undefined} value
 * @return {!proto.fleet_service.AddVehicleRequest} returns this
*/
proto.fleet_service.AddVehicleRequest.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.AddVehicleRequest} returns this
 */
proto.fleet_service.AddVehicleRequest.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.AddVehicleRequest.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.AddVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.AddVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.AddVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.AddVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    added: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.AddVehicleResponse}
 */
proto.fleet_service.AddVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.AddVehicleResponse;
  return proto.fleet_service.AddVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.AddVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.AddVehicleResponse}
 */
proto.fleet_service.AddVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdded(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.AddVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.AddVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.AddVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.AddVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdded();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool added = 1;
 * @return {boolean}
 */
proto.fleet_service.AddVehicleResponse.prototype.getAdded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.AddVehicleResponse} returns this
 */
proto.fleet_service.AddVehicleResponse.prototype.setAdded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.VehicleCriteria.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.VehicleCriteria.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.VehicleCriteria} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.VehicleCriteria.toObject = function(includeInstance, msg) {
  var f, obj = {
    property: jspb.Message.getFieldWithDefault(msg, 1, ""),
    query: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.VehicleCriteria}
 */
proto.fleet_service.VehicleCriteria.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.VehicleCriteria;
  return proto.fleet_service.VehicleCriteria.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.VehicleCriteria} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.VehicleCriteria}
 */
proto.fleet_service.VehicleCriteria.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProperty(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.VehicleCriteria.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.VehicleCriteria.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.VehicleCriteria} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.VehicleCriteria.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProperty();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string property = 1;
 * @return {string}
 */
proto.fleet_service.VehicleCriteria.prototype.getProperty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.VehicleCriteria} returns this
 */
proto.fleet_service.VehicleCriteria.prototype.setProperty = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string query = 2;
 * @return {string}
 */
proto.fleet_service.VehicleCriteria.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.VehicleCriteria} returns this
 */
proto.fleet_service.VehicleCriteria.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.ListVehiclesRequest.repeatedFields_ = [1,6,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ListVehiclesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ListVehiclesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ListVehiclesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListVehiclesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    proto.fleet_service.VehicleCriteria.toObject, includeInstance),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    resultsPerPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    customerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    order: (f = msg.getOrder()) && proto.fleet_service.OrderCriteria.toObject(includeInstance, f),
    trimPropertiesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    listByStatus: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    programIdsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ListVehiclesRequest}
 */
proto.fleet_service.ListVehiclesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ListVehiclesRequest;
  return proto.fleet_service.ListVehiclesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ListVehiclesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ListVehiclesRequest}
 */
proto.fleet_service.ListVehiclesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fleet_service.VehicleCriteria;
      reader.readMessage(value,proto.fleet_service.VehicleCriteria.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultsPerPage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 5:
      var value = new proto.fleet_service.OrderCriteria;
      reader.readMessage(value,proto.fleet_service.OrderCriteria.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addTrimProperties(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setListByStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addProgramIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ListVehiclesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ListVehiclesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ListVehiclesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListVehiclesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fleet_service.VehicleCriteria.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getResultsPerPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.fleet_service.OrderCriteria.serializeBinaryToWriter
    );
  }
  f = message.getTrimPropertiesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getListByStatus();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getProgramIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
};


/**
 * repeated VehicleCriteria criteria = 1;
 * @return {!Array<!proto.fleet_service.VehicleCriteria>}
 */
proto.fleet_service.ListVehiclesRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.fleet_service.VehicleCriteria>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fleet_service.VehicleCriteria, 1));
};


/**
 * @param {!Array<!proto.fleet_service.VehicleCriteria>} value
 * @return {!proto.fleet_service.ListVehiclesRequest} returns this
*/
proto.fleet_service.ListVehiclesRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fleet_service.VehicleCriteria=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.VehicleCriteria}
 */
proto.fleet_service.ListVehiclesRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fleet_service.VehicleCriteria, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.ListVehiclesRequest} returns this
 */
proto.fleet_service.ListVehiclesRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.fleet_service.ListVehiclesRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.ListVehiclesRequest} returns this
 */
proto.fleet_service.ListVehiclesRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 results_per_page = 3;
 * @return {number}
 */
proto.fleet_service.ListVehiclesRequest.prototype.getResultsPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.ListVehiclesRequest} returns this
 */
proto.fleet_service.ListVehiclesRequest.prototype.setResultsPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string customer_id = 4;
 * @return {string}
 */
proto.fleet_service.ListVehiclesRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ListVehiclesRequest} returns this
 */
proto.fleet_service.ListVehiclesRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional OrderCriteria order = 5;
 * @return {?proto.fleet_service.OrderCriteria}
 */
proto.fleet_service.ListVehiclesRequest.prototype.getOrder = function() {
  return /** @type{?proto.fleet_service.OrderCriteria} */ (
    jspb.Message.getWrapperField(this, proto.fleet_service.OrderCriteria, 5));
};


/**
 * @param {?proto.fleet_service.OrderCriteria|undefined} value
 * @return {!proto.fleet_service.ListVehiclesRequest} returns this
*/
proto.fleet_service.ListVehiclesRequest.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.ListVehiclesRequest} returns this
 */
proto.fleet_service.ListVehiclesRequest.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.ListVehiclesRequest.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string trim_properties = 6;
 * @return {!Array<string>}
 */
proto.fleet_service.ListVehiclesRequest.prototype.getTrimPropertiesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fleet_service.ListVehiclesRequest} returns this
 */
proto.fleet_service.ListVehiclesRequest.prototype.setTrimPropertiesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.ListVehiclesRequest} returns this
 */
proto.fleet_service.ListVehiclesRequest.prototype.addTrimProperties = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.ListVehiclesRequest} returns this
 */
proto.fleet_service.ListVehiclesRequest.prototype.clearTrimPropertiesList = function() {
  return this.setTrimPropertiesList([]);
};


/**
 * optional bool list_by_status = 7;
 * @return {boolean}
 */
proto.fleet_service.ListVehiclesRequest.prototype.getListByStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.ListVehiclesRequest} returns this
 */
proto.fleet_service.ListVehiclesRequest.prototype.setListByStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * repeated string program_ids = 8;
 * @return {!Array<string>}
 */
proto.fleet_service.ListVehiclesRequest.prototype.getProgramIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fleet_service.ListVehiclesRequest} returns this
 */
proto.fleet_service.ListVehiclesRequest.prototype.setProgramIdsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.ListVehiclesRequest} returns this
 */
proto.fleet_service.ListVehiclesRequest.prototype.addProgramIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.ListVehiclesRequest} returns this
 */
proto.fleet_service.ListVehiclesRequest.prototype.clearProgramIdsList = function() {
  return this.setProgramIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.OrderCriteria.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.OrderCriteria.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.OrderCriteria} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.OrderCriteria.toObject = function(includeInstance, msg) {
  var f, obj = {
    property: jspb.Message.getFieldWithDefault(msg, 1, ""),
    direction: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.OrderCriteria}
 */
proto.fleet_service.OrderCriteria.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.OrderCriteria;
  return proto.fleet_service.OrderCriteria.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.OrderCriteria} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.OrderCriteria}
 */
proto.fleet_service.OrderCriteria.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProperty(value);
      break;
    case 2:
      var value = /** @type {!proto.fleet_service.OrderCriteria.Direction} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.OrderCriteria.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.OrderCriteria.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.OrderCriteria} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.OrderCriteria.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProperty();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fleet_service.OrderCriteria.Direction = {
  ASC: 0,
  DESC: 1
};

/**
 * optional string property = 1;
 * @return {string}
 */
proto.fleet_service.OrderCriteria.prototype.getProperty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.OrderCriteria} returns this
 */
proto.fleet_service.OrderCriteria.prototype.setProperty = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Direction direction = 2;
 * @return {!proto.fleet_service.OrderCriteria.Direction}
 */
proto.fleet_service.OrderCriteria.prototype.getDirection = function() {
  return /** @type {!proto.fleet_service.OrderCriteria.Direction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fleet_service.OrderCriteria.Direction} value
 * @return {!proto.fleet_service.OrderCriteria} returns this
 */
proto.fleet_service.OrderCriteria.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.NexusVehicle.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.NexusVehicle.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.NexusVehicle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.NexusVehicle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.NexusVehicle.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && vehicle_pb.Vehicle.toObject(includeInstance, f),
    customersList: jspb.Message.toObjectList(msg.getCustomersList(),
    domain_pb.Customer.toObject, includeInstance),
    monthsInService: jspb.Message.getFieldWithDefault(msg, 3, 0),
    purchasePrice: jspb.Message.getFieldWithDefault(msg, 4, 0),
    currentValue: jspb.Message.getFieldWithDefault(msg, 5, 0),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.NexusVehicle}
 */
proto.fleet_service.NexusVehicle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.NexusVehicle;
  return proto.fleet_service.NexusVehicle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.NexusVehicle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.NexusVehicle}
 */
proto.fleet_service.NexusVehicle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vehicle_pb.Vehicle;
      reader.readMessage(value,vehicle_pb.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    case 2:
      var value = new domain_pb.Customer;
      reader.readMessage(value,domain_pb.Customer.deserializeBinaryFromReader);
      msg.addCustomers(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthsInService(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPurchasePrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentValue(value);
      break;
    case 6:
      var value = /** @type {!proto.fleet_service.NexusVehicle.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.NexusVehicle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.NexusVehicle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.NexusVehicle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.NexusVehicle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vehicle_pb.Vehicle.serializeBinaryToWriter
    );
  }
  f = message.getCustomersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      domain_pb.Customer.serializeBinaryToWriter
    );
  }
  f = message.getMonthsInService();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPurchasePrice();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCurrentValue();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fleet_service.NexusVehicle.Status = {
  INELIGIBLE: 0,
  ELIGIBLE: 1,
  ASSIGNED: 2,
  SUBSCRIBED: 3
};

/**
 * optional vehicle.Vehicle vehicle = 1;
 * @return {?proto.vehicle.Vehicle}
 */
proto.fleet_service.NexusVehicle.prototype.getVehicle = function() {
  return /** @type{?proto.vehicle.Vehicle} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Vehicle, 1));
};


/**
 * @param {?proto.vehicle.Vehicle|undefined} value
 * @return {!proto.fleet_service.NexusVehicle} returns this
*/
proto.fleet_service.NexusVehicle.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.NexusVehicle} returns this
 */
proto.fleet_service.NexusVehicle.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.NexusVehicle.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated domain.Customer customers = 2;
 * @return {!Array<!proto.domain.Customer>}
 */
proto.fleet_service.NexusVehicle.prototype.getCustomersList = function() {
  return /** @type{!Array<!proto.domain.Customer>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.Customer, 2));
};


/**
 * @param {!Array<!proto.domain.Customer>} value
 * @return {!proto.fleet_service.NexusVehicle} returns this
*/
proto.fleet_service.NexusVehicle.prototype.setCustomersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.domain.Customer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Customer}
 */
proto.fleet_service.NexusVehicle.prototype.addCustomers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.domain.Customer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.NexusVehicle} returns this
 */
proto.fleet_service.NexusVehicle.prototype.clearCustomersList = function() {
  return this.setCustomersList([]);
};


/**
 * optional int32 months_in_service = 3;
 * @return {number}
 */
proto.fleet_service.NexusVehicle.prototype.getMonthsInService = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.NexusVehicle} returns this
 */
proto.fleet_service.NexusVehicle.prototype.setMonthsInService = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 purchase_price = 4;
 * @return {number}
 */
proto.fleet_service.NexusVehicle.prototype.getPurchasePrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.NexusVehicle} returns this
 */
proto.fleet_service.NexusVehicle.prototype.setPurchasePrice = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 current_value = 5;
 * @return {number}
 */
proto.fleet_service.NexusVehicle.prototype.getCurrentValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.NexusVehicle} returns this
 */
proto.fleet_service.NexusVehicle.prototype.setCurrentValue = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional Status status = 6;
 * @return {!proto.fleet_service.NexusVehicle.Status}
 */
proto.fleet_service.NexusVehicle.prototype.getStatus = function() {
  return /** @type {!proto.fleet_service.NexusVehicle.Status} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.fleet_service.NexusVehicle.Status} value
 * @return {!proto.fleet_service.NexusVehicle} returns this
 */
proto.fleet_service.NexusVehicle.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.ListVehiclesResponse.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ListVehiclesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ListVehiclesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ListVehiclesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListVehiclesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vehiclesList: jspb.Message.toObjectList(msg.getVehiclesList(),
    proto.fleet_service.NexusVehicle.toObject, includeInstance),
    statusGroupsList: jspb.Message.toObjectList(msg.getStatusGroupsList(),
    proto.fleet_service.StatusGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ListVehiclesResponse}
 */
proto.fleet_service.ListVehiclesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ListVehiclesResponse;
  return proto.fleet_service.ListVehiclesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ListVehiclesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ListVehiclesResponse}
 */
proto.fleet_service.ListVehiclesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 2:
      var value = new proto.fleet_service.NexusVehicle;
      reader.readMessage(value,proto.fleet_service.NexusVehicle.deserializeBinaryFromReader);
      msg.addVehicles(value);
      break;
    case 3:
      var value = new proto.fleet_service.StatusGroup;
      reader.readMessage(value,proto.fleet_service.StatusGroup.deserializeBinaryFromReader);
      msg.addStatusGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ListVehiclesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ListVehiclesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ListVehiclesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListVehiclesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVehiclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.fleet_service.NexusVehicle.serializeBinaryToWriter
    );
  }
  f = message.getStatusGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.fleet_service.StatusGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 total = 1;
 * @return {number}
 */
proto.fleet_service.ListVehiclesResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.ListVehiclesResponse} returns this
 */
proto.fleet_service.ListVehiclesResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated NexusVehicle vehicles = 2;
 * @return {!Array<!proto.fleet_service.NexusVehicle>}
 */
proto.fleet_service.ListVehiclesResponse.prototype.getVehiclesList = function() {
  return /** @type{!Array<!proto.fleet_service.NexusVehicle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fleet_service.NexusVehicle, 2));
};


/**
 * @param {!Array<!proto.fleet_service.NexusVehicle>} value
 * @return {!proto.fleet_service.ListVehiclesResponse} returns this
*/
proto.fleet_service.ListVehiclesResponse.prototype.setVehiclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fleet_service.NexusVehicle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.NexusVehicle}
 */
proto.fleet_service.ListVehiclesResponse.prototype.addVehicles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fleet_service.NexusVehicle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.ListVehiclesResponse} returns this
 */
proto.fleet_service.ListVehiclesResponse.prototype.clearVehiclesList = function() {
  return this.setVehiclesList([]);
};


/**
 * repeated StatusGroup status_groups = 3;
 * @return {!Array<!proto.fleet_service.StatusGroup>}
 */
proto.fleet_service.ListVehiclesResponse.prototype.getStatusGroupsList = function() {
  return /** @type{!Array<!proto.fleet_service.StatusGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fleet_service.StatusGroup, 3));
};


/**
 * @param {!Array<!proto.fleet_service.StatusGroup>} value
 * @return {!proto.fleet_service.ListVehiclesResponse} returns this
*/
proto.fleet_service.ListVehiclesResponse.prototype.setStatusGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.fleet_service.StatusGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.StatusGroup}
 */
proto.fleet_service.ListVehiclesResponse.prototype.addStatusGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.fleet_service.StatusGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.ListVehiclesResponse} returns this
 */
proto.fleet_service.ListVehiclesResponse.prototype.clearStatusGroupsList = function() {
  return this.setStatusGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetOwnBuyItNowDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetOwnBuyItNowDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetOwnBuyItNowDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetOwnBuyItNowDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetOwnBuyItNowDetailsRequest}
 */
proto.fleet_service.GetOwnBuyItNowDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetOwnBuyItNowDetailsRequest;
  return proto.fleet_service.GetOwnBuyItNowDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetOwnBuyItNowDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetOwnBuyItNowDetailsRequest}
 */
proto.fleet_service.GetOwnBuyItNowDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetOwnBuyItNowDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetOwnBuyItNowDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetOwnBuyItNowDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetOwnBuyItNowDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.fleet_service.GetOwnBuyItNowDetailsRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetOwnBuyItNowDetailsRequest} returns this
 */
proto.fleet_service.GetOwnBuyItNowDetailsRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.fleet_service.GetOwnBuyItNowDetailsRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetOwnBuyItNowDetailsRequest} returns this
 */
proto.fleet_service.GetOwnBuyItNowDetailsRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetOwnBuyItNowDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetOwnBuyItNowDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetOwnBuyItNowDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetOwnBuyItNowDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    manufacturerSuggestedRetailPrice: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currentValue: jspb.Message.getFieldWithDefault(msg, 2, 0),
    buyItNowPrice: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetOwnBuyItNowDetailsResponse}
 */
proto.fleet_service.GetOwnBuyItNowDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetOwnBuyItNowDetailsResponse;
  return proto.fleet_service.GetOwnBuyItNowDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetOwnBuyItNowDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetOwnBuyItNowDetailsResponse}
 */
proto.fleet_service.GetOwnBuyItNowDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setManufacturerSuggestedRetailPrice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBuyItNowPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetOwnBuyItNowDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetOwnBuyItNowDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetOwnBuyItNowDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetOwnBuyItNowDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManufacturerSuggestedRetailPrice();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCurrentValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getBuyItNowPrice();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 manufacturer_suggested_retail_price = 1;
 * @return {number}
 */
proto.fleet_service.GetOwnBuyItNowDetailsResponse.prototype.getManufacturerSuggestedRetailPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.GetOwnBuyItNowDetailsResponse} returns this
 */
proto.fleet_service.GetOwnBuyItNowDetailsResponse.prototype.setManufacturerSuggestedRetailPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 current_value = 2;
 * @return {number}
 */
proto.fleet_service.GetOwnBuyItNowDetailsResponse.prototype.getCurrentValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.GetOwnBuyItNowDetailsResponse} returns this
 */
proto.fleet_service.GetOwnBuyItNowDetailsResponse.prototype.setCurrentValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 buy_it_now_price = 3;
 * @return {number}
 */
proto.fleet_service.GetOwnBuyItNowDetailsResponse.prototype.getBuyItNowPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.GetOwnBuyItNowDetailsResponse} returns this
 */
proto.fleet_service.GetOwnBuyItNowDetailsResponse.prototype.setBuyItNowPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    partialCustomer: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    partialVehicle: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetVehicleRequest}
 */
proto.fleet_service.GetVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetVehicleRequest;
  return proto.fleet_service.GetVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetVehicleRequest}
 */
proto.fleet_service.GetVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPartialCustomer(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPartialVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPartialCustomer();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getPartialVehicle();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fleet_service.GetVehicleRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetVehicleRequest} returns this
 */
proto.fleet_service.GetVehicleRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool partial_customer = 2;
 * @return {boolean}
 */
proto.fleet_service.GetVehicleRequest.prototype.getPartialCustomer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.GetVehicleRequest} returns this
 */
proto.fleet_service.GetVehicleRequest.prototype.setPartialCustomer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool partial_vehicle = 3;
 * @return {boolean}
 */
proto.fleet_service.GetVehicleRequest.prototype.getPartialVehicle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.GetVehicleRequest} returns this
 */
proto.fleet_service.GetVehicleRequest.prototype.setPartialVehicle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && vehicle_pb.Vehicle.toObject(includeInstance, f),
    customer: (f = msg.getCustomer()) && domain_pb.Customer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetVehicleResponse}
 */
proto.fleet_service.GetVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetVehicleResponse;
  return proto.fleet_service.GetVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetVehicleResponse}
 */
proto.fleet_service.GetVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vehicle_pb.Vehicle;
      reader.readMessage(value,vehicle_pb.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    case 2:
      var value = new domain_pb.Customer;
      reader.readMessage(value,domain_pb.Customer.deserializeBinaryFromReader);
      msg.setCustomer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vehicle_pb.Vehicle.serializeBinaryToWriter
    );
  }
  f = message.getCustomer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      domain_pb.Customer.serializeBinaryToWriter
    );
  }
};


/**
 * optional vehicle.Vehicle vehicle = 1;
 * @return {?proto.vehicle.Vehicle}
 */
proto.fleet_service.GetVehicleResponse.prototype.getVehicle = function() {
  return /** @type{?proto.vehicle.Vehicle} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Vehicle, 1));
};


/**
 * @param {?proto.vehicle.Vehicle|undefined} value
 * @return {!proto.fleet_service.GetVehicleResponse} returns this
*/
proto.fleet_service.GetVehicleResponse.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.GetVehicleResponse} returns this
 */
proto.fleet_service.GetVehicleResponse.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.GetVehicleResponse.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional domain.Customer customer = 2;
 * @return {?proto.domain.Customer}
 */
proto.fleet_service.GetVehicleResponse.prototype.getCustomer = function() {
  return /** @type{?proto.domain.Customer} */ (
    jspb.Message.getWrapperField(this, domain_pb.Customer, 2));
};


/**
 * @param {?proto.domain.Customer|undefined} value
 * @return {!proto.fleet_service.GetVehicleResponse} returns this
*/
proto.fleet_service.GetVehicleResponse.prototype.setCustomer = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.GetVehicleResponse} returns this
 */
proto.fleet_service.GetVehicleResponse.prototype.clearCustomer = function() {
  return this.setCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.GetVehicleResponse.prototype.hasCustomer = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetVehicleByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetVehicleByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetVehicleByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetVehicleByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetVehicleByIdRequest}
 */
proto.fleet_service.GetVehicleByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetVehicleByIdRequest;
  return proto.fleet_service.GetVehicleByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetVehicleByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetVehicleByIdRequest}
 */
proto.fleet_service.GetVehicleByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetVehicleByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetVehicleByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetVehicleByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetVehicleByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fleet_service.GetVehicleByIdRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetVehicleByIdRequest} returns this
 */
proto.fleet_service.GetVehicleByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetVehicleByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetVehicleByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetVehicleByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetVehicleByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && proto.fleet_service.Vehicle.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetVehicleByIdResponse}
 */
proto.fleet_service.GetVehicleByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetVehicleByIdResponse;
  return proto.fleet_service.GetVehicleByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetVehicleByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetVehicleByIdResponse}
 */
proto.fleet_service.GetVehicleByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fleet_service.Vehicle;
      reader.readMessage(value,proto.fleet_service.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetVehicleByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetVehicleByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetVehicleByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetVehicleByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fleet_service.Vehicle.serializeBinaryToWriter
    );
  }
};


/**
 * optional Vehicle vehicle = 1;
 * @return {?proto.fleet_service.Vehicle}
 */
proto.fleet_service.GetVehicleByIdResponse.prototype.getVehicle = function() {
  return /** @type{?proto.fleet_service.Vehicle} */ (
    jspb.Message.getWrapperField(this, proto.fleet_service.Vehicle, 1));
};


/**
 * @param {?proto.fleet_service.Vehicle|undefined} value
 * @return {!proto.fleet_service.GetVehicleByIdResponse} returns this
*/
proto.fleet_service.GetVehicleByIdResponse.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.GetVehicleByIdResponse} returns this
 */
proto.fleet_service.GetVehicleByIdResponse.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.GetVehicleByIdResponse.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetVehicleByVinRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetVehicleByVinRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetVehicleByVinRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetVehicleByVinRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vin: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetVehicleByVinRequest}
 */
proto.fleet_service.GetVehicleByVinRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetVehicleByVinRequest;
  return proto.fleet_service.GetVehicleByVinRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetVehicleByVinRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetVehicleByVinRequest}
 */
proto.fleet_service.GetVehicleByVinRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetVehicleByVinRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetVehicleByVinRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetVehicleByVinRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetVehicleByVinRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vin = 1;
 * @return {string}
 */
proto.fleet_service.GetVehicleByVinRequest.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetVehicleByVinRequest} returns this
 */
proto.fleet_service.GetVehicleByVinRequest.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetVehicleByVinResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetVehicleByVinResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetVehicleByVinResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetVehicleByVinResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && proto.fleet_service.Vehicle.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetVehicleByVinResponse}
 */
proto.fleet_service.GetVehicleByVinResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetVehicleByVinResponse;
  return proto.fleet_service.GetVehicleByVinResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetVehicleByVinResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetVehicleByVinResponse}
 */
proto.fleet_service.GetVehicleByVinResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fleet_service.Vehicle;
      reader.readMessage(value,proto.fleet_service.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetVehicleByVinResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetVehicleByVinResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetVehicleByVinResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetVehicleByVinResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fleet_service.Vehicle.serializeBinaryToWriter
    );
  }
};


/**
 * optional Vehicle vehicle = 1;
 * @return {?proto.fleet_service.Vehicle}
 */
proto.fleet_service.GetVehicleByVinResponse.prototype.getVehicle = function() {
  return /** @type{?proto.fleet_service.Vehicle} */ (
    jspb.Message.getWrapperField(this, proto.fleet_service.Vehicle, 1));
};


/**
 * @param {?proto.fleet_service.Vehicle|undefined} value
 * @return {!proto.fleet_service.GetVehicleByVinResponse} returns this
*/
proto.fleet_service.GetVehicleByVinResponse.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.GetVehicleByVinResponse} returns this
 */
proto.fleet_service.GetVehicleByVinResponse.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.GetVehicleByVinResponse.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.TelemetricEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.TelemetricEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.TelemetricEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.TelemetricEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.TelemetricEvent}
 */
proto.fleet_service.TelemetricEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.TelemetricEvent;
  return proto.fleet_service.TelemetricEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.TelemetricEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.TelemetricEvent}
 */
proto.fleet_service.TelemetricEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.TelemetricEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.TelemetricEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.TelemetricEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.TelemetricEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Id = 1;
 * @return {string}
 */
proto.fleet_service.TelemetricEvent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.TelemetricEvent} returns this
 */
proto.fleet_service.TelemetricEvent.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.HandleTelemetricEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.HandleTelemetricEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.HandleTelemetricEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.HandleTelemetricEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    eventprocessed: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.HandleTelemetricEventResponse}
 */
proto.fleet_service.HandleTelemetricEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.HandleTelemetricEventResponse;
  return proto.fleet_service.HandleTelemetricEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.HandleTelemetricEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.HandleTelemetricEventResponse}
 */
proto.fleet_service.HandleTelemetricEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEventprocessed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.HandleTelemetricEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.HandleTelemetricEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.HandleTelemetricEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.HandleTelemetricEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEventprocessed();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string Id = 1;
 * @return {string}
 */
proto.fleet_service.HandleTelemetricEventResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.HandleTelemetricEventResponse} returns this
 */
proto.fleet_service.HandleTelemetricEventResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool EventProcessed = 2;
 * @return {boolean}
 */
proto.fleet_service.HandleTelemetricEventResponse.prototype.getEventprocessed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.HandleTelemetricEventResponse} returns this
 */
proto.fleet_service.HandleTelemetricEventResponse.prototype.setEventprocessed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.DeleteDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.DeleteDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.DeleteDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.DeleteDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    documentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    documentType: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.DeleteDocumentRequest}
 */
proto.fleet_service.DeleteDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.DeleteDocumentRequest;
  return proto.fleet_service.DeleteDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.DeleteDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.DeleteDocumentRequest}
 */
proto.fleet_service.DeleteDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.DeleteDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.DeleteDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.DeleteDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.DeleteDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocumentId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDocumentType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.fleet_service.DeleteDocumentRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.DeleteDocumentRequest} returns this
 */
proto.fleet_service.DeleteDocumentRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string document_id = 2;
 * @return {string}
 */
proto.fleet_service.DeleteDocumentRequest.prototype.getDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.DeleteDocumentRequest} returns this
 */
proto.fleet_service.DeleteDocumentRequest.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string document_type = 3;
 * @return {string}
 */
proto.fleet_service.DeleteDocumentRequest.prototype.getDocumentType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.DeleteDocumentRequest} returns this
 */
proto.fleet_service.DeleteDocumentRequest.prototype.setDocumentType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.DeleteDocumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.DeleteDocumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.DeleteDocumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.DeleteDocumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.DeleteDocumentResponse}
 */
proto.fleet_service.DeleteDocumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.DeleteDocumentResponse;
  return proto.fleet_service.DeleteDocumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.DeleteDocumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.DeleteDocumentResponse}
 */
proto.fleet_service.DeleteDocumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.DeleteDocumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.DeleteDocumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.DeleteDocumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.DeleteDocumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.fleet_service.DeleteDocumentResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.DeleteDocumentResponse} returns this
 */
proto.fleet_service.DeleteDocumentResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.ConfirmVehicleRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ConfirmVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ConfirmVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ConfirmVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ConfirmVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    requestType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    colorOptions: (f = msg.getColorOptions()) && proto.fleet_service.ConfirmVehicleRequest.ColorOptions.toObject(includeInstance, f),
    vehicleModel: jspb.Message.getFieldWithDefault(msg, 5, 0),
    year: jspb.Message.getFieldWithDefault(msg, 6, ""),
    vehiclePreferencesList: jspb.Message.toObjectList(msg.getVehiclePreferencesList(),
    vehicle_pb.Preference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ConfirmVehicleRequest}
 */
proto.fleet_service.ConfirmVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ConfirmVehicleRequest;
  return proto.fleet_service.ConfirmVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ConfirmVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ConfirmVehicleRequest}
 */
proto.fleet_service.ConfirmVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {!proto.fleet_service.ConfirmVehicleRequest.Type} */ (reader.readEnum());
      msg.setRequestType(value);
      break;
    case 4:
      var value = new proto.fleet_service.ConfirmVehicleRequest.ColorOptions;
      reader.readMessage(value,proto.fleet_service.ConfirmVehicleRequest.ColorOptions.deserializeBinaryFromReader);
      msg.setColorOptions(value);
      break;
    case 5:
      var value = /** @type {!proto.fleet_service.ConfirmVehicleRequest.VehicleModel} */ (reader.readEnum());
      msg.setVehicleModel(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 7:
      var value = new vehicle_pb.Preference;
      reader.readMessage(value,vehicle_pb.Preference.deserializeBinaryFromReader);
      msg.addVehiclePreferences(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ConfirmVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ConfirmVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ConfirmVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ConfirmVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRequestType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getColorOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.fleet_service.ConfirmVehicleRequest.ColorOptions.serializeBinaryToWriter
    );
  }
  f = message.getVehicleModel();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getVehiclePreferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      vehicle_pb.Preference.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.fleet_service.ConfirmVehicleRequest.VehicleModel = {
  TESLA_MODEL_3: 0,
  TESLA_MODEL_Y: 1
};

/**
 * @enum {number}
 */
proto.fleet_service.ConfirmVehicleRequest.ColorName = {
  WHITE: 0,
  BLACK: 1,
  GRAY: 2,
  RED: 3,
  BLUE: 4,
  YELLOW: 5,
  ORANGE: 6,
  GREEN: 7,
  VIOLET: 8
};

/**
 * @enum {number}
 */
proto.fleet_service.ConfirmVehicleRequest.Type = {
  SUBSCRIPTION_ACTIVATION: 0,
  RESERVATION_ASSIGNMENT: 1
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ConfirmVehicleRequest.ColorOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ConfirmVehicleRequest.ColorOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ConfirmVehicleRequest.ColorOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ConfirmVehicleRequest.ColorOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    primaryColor: jspb.Message.getFieldWithDefault(msg, 1, 0),
    secondaryColor: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ConfirmVehicleRequest.ColorOptions}
 */
proto.fleet_service.ConfirmVehicleRequest.ColorOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ConfirmVehicleRequest.ColorOptions;
  return proto.fleet_service.ConfirmVehicleRequest.ColorOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ConfirmVehicleRequest.ColorOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ConfirmVehicleRequest.ColorOptions}
 */
proto.fleet_service.ConfirmVehicleRequest.ColorOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fleet_service.ConfirmVehicleRequest.ColorName} */ (reader.readEnum());
      msg.setPrimaryColor(value);
      break;
    case 2:
      var value = /** @type {!proto.fleet_service.ConfirmVehicleRequest.ColorName} */ (reader.readEnum());
      msg.setSecondaryColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ConfirmVehicleRequest.ColorOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ConfirmVehicleRequest.ColorOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ConfirmVehicleRequest.ColorOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ConfirmVehicleRequest.ColorOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrimaryColor();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSecondaryColor();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional ColorName primary_color = 1;
 * @return {!proto.fleet_service.ConfirmVehicleRequest.ColorName}
 */
proto.fleet_service.ConfirmVehicleRequest.ColorOptions.prototype.getPrimaryColor = function() {
  return /** @type {!proto.fleet_service.ConfirmVehicleRequest.ColorName} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fleet_service.ConfirmVehicleRequest.ColorName} value
 * @return {!proto.fleet_service.ConfirmVehicleRequest.ColorOptions} returns this
 */
proto.fleet_service.ConfirmVehicleRequest.ColorOptions.prototype.setPrimaryColor = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ColorName secondary_color = 2;
 * @return {!proto.fleet_service.ConfirmVehicleRequest.ColorName}
 */
proto.fleet_service.ConfirmVehicleRequest.ColorOptions.prototype.getSecondaryColor = function() {
  return /** @type {!proto.fleet_service.ConfirmVehicleRequest.ColorName} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fleet_service.ConfirmVehicleRequest.ColorName} value
 * @return {!proto.fleet_service.ConfirmVehicleRequest.ColorOptions} returns this
 */
proto.fleet_service.ConfirmVehicleRequest.ColorOptions.prototype.setSecondaryColor = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.fleet_service.ConfirmVehicleRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ConfirmVehicleRequest} returns this
 */
proto.fleet_service.ConfirmVehicleRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.fleet_service.ConfirmVehicleRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ConfirmVehicleRequest} returns this
 */
proto.fleet_service.ConfirmVehicleRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Type request_type = 3;
 * @return {!proto.fleet_service.ConfirmVehicleRequest.Type}
 */
proto.fleet_service.ConfirmVehicleRequest.prototype.getRequestType = function() {
  return /** @type {!proto.fleet_service.ConfirmVehicleRequest.Type} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.fleet_service.ConfirmVehicleRequest.Type} value
 * @return {!proto.fleet_service.ConfirmVehicleRequest} returns this
 */
proto.fleet_service.ConfirmVehicleRequest.prototype.setRequestType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional ColorOptions color_options = 4;
 * @return {?proto.fleet_service.ConfirmVehicleRequest.ColorOptions}
 */
proto.fleet_service.ConfirmVehicleRequest.prototype.getColorOptions = function() {
  return /** @type{?proto.fleet_service.ConfirmVehicleRequest.ColorOptions} */ (
    jspb.Message.getWrapperField(this, proto.fleet_service.ConfirmVehicleRequest.ColorOptions, 4));
};


/**
 * @param {?proto.fleet_service.ConfirmVehicleRequest.ColorOptions|undefined} value
 * @return {!proto.fleet_service.ConfirmVehicleRequest} returns this
*/
proto.fleet_service.ConfirmVehicleRequest.prototype.setColorOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.ConfirmVehicleRequest} returns this
 */
proto.fleet_service.ConfirmVehicleRequest.prototype.clearColorOptions = function() {
  return this.setColorOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.ConfirmVehicleRequest.prototype.hasColorOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional VehicleModel vehicle_model = 5;
 * @return {!proto.fleet_service.ConfirmVehicleRequest.VehicleModel}
 */
proto.fleet_service.ConfirmVehicleRequest.prototype.getVehicleModel = function() {
  return /** @type {!proto.fleet_service.ConfirmVehicleRequest.VehicleModel} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.fleet_service.ConfirmVehicleRequest.VehicleModel} value
 * @return {!proto.fleet_service.ConfirmVehicleRequest} returns this
 */
proto.fleet_service.ConfirmVehicleRequest.prototype.setVehicleModel = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string year = 6;
 * @return {string}
 */
proto.fleet_service.ConfirmVehicleRequest.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ConfirmVehicleRequest} returns this
 */
proto.fleet_service.ConfirmVehicleRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated vehicle.Preference vehicle_preferences = 7;
 * @return {!Array<!proto.vehicle.Preference>}
 */
proto.fleet_service.ConfirmVehicleRequest.prototype.getVehiclePreferencesList = function() {
  return /** @type{!Array<!proto.vehicle.Preference>} */ (
    jspb.Message.getRepeatedWrapperField(this, vehicle_pb.Preference, 7));
};


/**
 * @param {!Array<!proto.vehicle.Preference>} value
 * @return {!proto.fleet_service.ConfirmVehicleRequest} returns this
*/
proto.fleet_service.ConfirmVehicleRequest.prototype.setVehiclePreferencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.vehicle.Preference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Preference}
 */
proto.fleet_service.ConfirmVehicleRequest.prototype.addVehiclePreferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.vehicle.Preference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.ConfirmVehicleRequest} returns this
 */
proto.fleet_service.ConfirmVehicleRequest.prototype.clearVehiclePreferencesList = function() {
  return this.setVehiclePreferencesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ConfirmVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ConfirmVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ConfirmVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ConfirmVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    color: jspb.Message.getFieldWithDefault(msg, 2, 0),
    vin: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ConfirmVehicleResponse}
 */
proto.fleet_service.ConfirmVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ConfirmVehicleResponse;
  return proto.fleet_service.ConfirmVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ConfirmVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ConfirmVehicleResponse}
 */
proto.fleet_service.ConfirmVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {!proto.fleet_service.ConfirmVehicleResponse.ColorAssigned} */ (reader.readEnum());
      msg.setColor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ConfirmVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ConfirmVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ConfirmVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ConfirmVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getColor();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fleet_service.ConfirmVehicleResponse.ColorAssigned = {
  NO_COLOR_ASSIGNED: 0,
  PRIMARY_COLOR_ASSIGNED: 1,
  SECONDARY_COLOR_ASSIGNED: 2
};

/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.fleet_service.ConfirmVehicleResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.ConfirmVehicleResponse} returns this
 */
proto.fleet_service.ConfirmVehicleResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional ColorAssigned color = 2;
 * @return {!proto.fleet_service.ConfirmVehicleResponse.ColorAssigned}
 */
proto.fleet_service.ConfirmVehicleResponse.prototype.getColor = function() {
  return /** @type {!proto.fleet_service.ConfirmVehicleResponse.ColorAssigned} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fleet_service.ConfirmVehicleResponse.ColorAssigned} value
 * @return {!proto.fleet_service.ConfirmVehicleResponse} returns this
 */
proto.fleet_service.ConfirmVehicleResponse.prototype.setColor = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string vin = 3;
 * @return {string}
 */
proto.fleet_service.ConfirmVehicleResponse.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ConfirmVehicleResponse} returns this
 */
proto.fleet_service.ConfirmVehicleResponse.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.ListProfilesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ListProfilesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ListProfilesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ListProfilesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListProfilesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    proto.fleet_service.VehicleCriteria.toObject, includeInstance),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    resultsPerPage: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ListProfilesRequest}
 */
proto.fleet_service.ListProfilesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ListProfilesRequest;
  return proto.fleet_service.ListProfilesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ListProfilesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ListProfilesRequest}
 */
proto.fleet_service.ListProfilesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fleet_service.VehicleCriteria;
      reader.readMessage(value,proto.fleet_service.VehicleCriteria.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultsPerPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ListProfilesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ListProfilesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ListProfilesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListProfilesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fleet_service.VehicleCriteria.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getResultsPerPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated VehicleCriteria criteria = 1;
 * @return {!Array<!proto.fleet_service.VehicleCriteria>}
 */
proto.fleet_service.ListProfilesRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.fleet_service.VehicleCriteria>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fleet_service.VehicleCriteria, 1));
};


/**
 * @param {!Array<!proto.fleet_service.VehicleCriteria>} value
 * @return {!proto.fleet_service.ListProfilesRequest} returns this
*/
proto.fleet_service.ListProfilesRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fleet_service.VehicleCriteria=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.VehicleCriteria}
 */
proto.fleet_service.ListProfilesRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fleet_service.VehicleCriteria, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.ListProfilesRequest} returns this
 */
proto.fleet_service.ListProfilesRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.fleet_service.ListProfilesRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.ListProfilesRequest} returns this
 */
proto.fleet_service.ListProfilesRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 results_per_page = 3;
 * @return {number}
 */
proto.fleet_service.ListProfilesRequest.prototype.getResultsPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.ListProfilesRequest} returns this
 */
proto.fleet_service.ListProfilesRequest.prototype.setResultsPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.ListProfilesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ListProfilesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ListProfilesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ListProfilesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListProfilesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    profilesList: jspb.Message.toObjectList(msg.getProfilesList(),
    vehicle_pb.Profile.toObject, includeInstance),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    total: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ListProfilesResponse}
 */
proto.fleet_service.ListProfilesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ListProfilesResponse;
  return proto.fleet_service.ListProfilesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ListProfilesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ListProfilesResponse}
 */
proto.fleet_service.ListProfilesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vehicle_pb.Profile;
      reader.readMessage(value,vehicle_pb.Profile.deserializeBinaryFromReader);
      msg.addProfiles(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ListProfilesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ListProfilesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ListProfilesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListProfilesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      vehicle_pb.Profile.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated vehicle.Profile profiles = 1;
 * @return {!Array<!proto.vehicle.Profile>}
 */
proto.fleet_service.ListProfilesResponse.prototype.getProfilesList = function() {
  return /** @type{!Array<!proto.vehicle.Profile>} */ (
    jspb.Message.getRepeatedWrapperField(this, vehicle_pb.Profile, 1));
};


/**
 * @param {!Array<!proto.vehicle.Profile>} value
 * @return {!proto.fleet_service.ListProfilesResponse} returns this
*/
proto.fleet_service.ListProfilesResponse.prototype.setProfilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vehicle.Profile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Profile}
 */
proto.fleet_service.ListProfilesResponse.prototype.addProfiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vehicle.Profile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.ListProfilesResponse} returns this
 */
proto.fleet_service.ListProfilesResponse.prototype.clearProfilesList = function() {
  return this.setProfilesList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.fleet_service.ListProfilesResponse.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.ListProfilesResponse} returns this
 */
proto.fleet_service.ListProfilesResponse.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 total = 3;
 * @return {number}
 */
proto.fleet_service.ListProfilesResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.ListProfilesResponse} returns this
 */
proto.fleet_service.ListProfilesResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.fleet_service.VehicleProperty.oneofGroups_ = [[1,2,3,4,5,6,7]];

/**
 * @enum {number}
 */
proto.fleet_service.VehicleProperty.PropertyCase = {
  PROPERTY_NOT_SET: 0,
  NEW_VEHICLE_STATE: 1,
  NEW_ACTIVITY_FAILURE: 2,
  NEW_ACTIVITY: 3,
  NEW_ACTIVITY_STATE: 4,
  VEHICLE_PROPERTY: 5,
  EXTERIOR_COLOR: 6,
  INTERIOR_COLOR: 7
};

/**
 * @return {proto.fleet_service.VehicleProperty.PropertyCase}
 */
proto.fleet_service.VehicleProperty.prototype.getPropertyCase = function() {
  return /** @type {proto.fleet_service.VehicleProperty.PropertyCase} */(jspb.Message.computeOneofCase(this, proto.fleet_service.VehicleProperty.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.VehicleProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.VehicleProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.VehicleProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.VehicleProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    newVehicleState: jspb.Message.getFieldWithDefault(msg, 1, 0),
    newActivityFailure: jspb.Message.getFieldWithDefault(msg, 2, 0),
    newActivity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    newActivityState: jspb.Message.getFieldWithDefault(msg, 4, 0),
    vehicleProperty: (f = msg.getVehicleProperty()) && vehicle_pb.Property.toObject(includeInstance, f),
    exteriorColor: (f = msg.getExteriorColor()) && vehicle_pb.Color.toObject(includeInstance, f),
    interiorColor: (f = msg.getInteriorColor()) && vehicle_pb.Color.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.VehicleProperty}
 */
proto.fleet_service.VehicleProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.VehicleProperty;
  return proto.fleet_service.VehicleProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.VehicleProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.VehicleProperty}
 */
proto.fleet_service.VehicleProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vehicle.State} */ (reader.readEnum());
      msg.setNewVehicleState(value);
      break;
    case 2:
      var value = /** @type {!proto.vehicle.ActivityFailure} */ (reader.readEnum());
      msg.setNewActivityFailure(value);
      break;
    case 3:
      var value = /** @type {!proto.vehicle.Activity} */ (reader.readEnum());
      msg.setNewActivity(value);
      break;
    case 4:
      var value = /** @type {!proto.vehicle.ActivityState} */ (reader.readEnum());
      msg.setNewActivityState(value);
      break;
    case 5:
      var value = new vehicle_pb.Property;
      reader.readMessage(value,vehicle_pb.Property.deserializeBinaryFromReader);
      msg.setVehicleProperty(value);
      break;
    case 6:
      var value = new vehicle_pb.Color;
      reader.readMessage(value,vehicle_pb.Color.deserializeBinaryFromReader);
      msg.setExteriorColor(value);
      break;
    case 7:
      var value = new vehicle_pb.Color;
      reader.readMessage(value,vehicle_pb.Color.deserializeBinaryFromReader);
      msg.setInteriorColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.VehicleProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.VehicleProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.VehicleProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.VehicleProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.vehicle.State} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {!proto.vehicle.ActivityFailure} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {!proto.vehicle.Activity} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {!proto.vehicle.ActivityState} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getVehicleProperty();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vehicle_pb.Property.serializeBinaryToWriter
    );
  }
  f = message.getExteriorColor();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vehicle_pb.Color.serializeBinaryToWriter
    );
  }
  f = message.getInteriorColor();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      vehicle_pb.Color.serializeBinaryToWriter
    );
  }
};


/**
 * optional vehicle.State new_vehicle_state = 1;
 * @return {!proto.vehicle.State}
 */
proto.fleet_service.VehicleProperty.prototype.getNewVehicleState = function() {
  return /** @type {!proto.vehicle.State} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vehicle.State} value
 * @return {!proto.fleet_service.VehicleProperty} returns this
 */
proto.fleet_service.VehicleProperty.prototype.setNewVehicleState = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.fleet_service.VehicleProperty.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.fleet_service.VehicleProperty} returns this
 */
proto.fleet_service.VehicleProperty.prototype.clearNewVehicleState = function() {
  return jspb.Message.setOneofField(this, 1, proto.fleet_service.VehicleProperty.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.VehicleProperty.prototype.hasNewVehicleState = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional vehicle.ActivityFailure new_activity_failure = 2;
 * @return {!proto.vehicle.ActivityFailure}
 */
proto.fleet_service.VehicleProperty.prototype.getNewActivityFailure = function() {
  return /** @type {!proto.vehicle.ActivityFailure} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vehicle.ActivityFailure} value
 * @return {!proto.fleet_service.VehicleProperty} returns this
 */
proto.fleet_service.VehicleProperty.prototype.setNewActivityFailure = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.fleet_service.VehicleProperty.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.fleet_service.VehicleProperty} returns this
 */
proto.fleet_service.VehicleProperty.prototype.clearNewActivityFailure = function() {
  return jspb.Message.setOneofField(this, 2, proto.fleet_service.VehicleProperty.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.VehicleProperty.prototype.hasNewActivityFailure = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional vehicle.Activity new_activity = 3;
 * @return {!proto.vehicle.Activity}
 */
proto.fleet_service.VehicleProperty.prototype.getNewActivity = function() {
  return /** @type {!proto.vehicle.Activity} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vehicle.Activity} value
 * @return {!proto.fleet_service.VehicleProperty} returns this
 */
proto.fleet_service.VehicleProperty.prototype.setNewActivity = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.fleet_service.VehicleProperty.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.fleet_service.VehicleProperty} returns this
 */
proto.fleet_service.VehicleProperty.prototype.clearNewActivity = function() {
  return jspb.Message.setOneofField(this, 3, proto.fleet_service.VehicleProperty.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.VehicleProperty.prototype.hasNewActivity = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional vehicle.ActivityState new_activity_state = 4;
 * @return {!proto.vehicle.ActivityState}
 */
proto.fleet_service.VehicleProperty.prototype.getNewActivityState = function() {
  return /** @type {!proto.vehicle.ActivityState} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.vehicle.ActivityState} value
 * @return {!proto.fleet_service.VehicleProperty} returns this
 */
proto.fleet_service.VehicleProperty.prototype.setNewActivityState = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.fleet_service.VehicleProperty.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.fleet_service.VehicleProperty} returns this
 */
proto.fleet_service.VehicleProperty.prototype.clearNewActivityState = function() {
  return jspb.Message.setOneofField(this, 4, proto.fleet_service.VehicleProperty.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.VehicleProperty.prototype.hasNewActivityState = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional vehicle.Property vehicle_property = 5;
 * @return {?proto.vehicle.Property}
 */
proto.fleet_service.VehicleProperty.prototype.getVehicleProperty = function() {
  return /** @type{?proto.vehicle.Property} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Property, 5));
};


/**
 * @param {?proto.vehicle.Property|undefined} value
 * @return {!proto.fleet_service.VehicleProperty} returns this
*/
proto.fleet_service.VehicleProperty.prototype.setVehicleProperty = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.fleet_service.VehicleProperty.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.VehicleProperty} returns this
 */
proto.fleet_service.VehicleProperty.prototype.clearVehicleProperty = function() {
  return this.setVehicleProperty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.VehicleProperty.prototype.hasVehicleProperty = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional vehicle.Color exterior_color = 6;
 * @return {?proto.vehicle.Color}
 */
proto.fleet_service.VehicleProperty.prototype.getExteriorColor = function() {
  return /** @type{?proto.vehicle.Color} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Color, 6));
};


/**
 * @param {?proto.vehicle.Color|undefined} value
 * @return {!proto.fleet_service.VehicleProperty} returns this
*/
proto.fleet_service.VehicleProperty.prototype.setExteriorColor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.fleet_service.VehicleProperty.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.VehicleProperty} returns this
 */
proto.fleet_service.VehicleProperty.prototype.clearExteriorColor = function() {
  return this.setExteriorColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.VehicleProperty.prototype.hasExteriorColor = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional vehicle.Color interior_color = 7;
 * @return {?proto.vehicle.Color}
 */
proto.fleet_service.VehicleProperty.prototype.getInteriorColor = function() {
  return /** @type{?proto.vehicle.Color} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Color, 7));
};


/**
 * @param {?proto.vehicle.Color|undefined} value
 * @return {!proto.fleet_service.VehicleProperty} returns this
*/
proto.fleet_service.VehicleProperty.prototype.setInteriorColor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.fleet_service.VehicleProperty.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.VehicleProperty} returns this
 */
proto.fleet_service.VehicleProperty.prototype.clearInteriorColor = function() {
  return this.setInteriorColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.VehicleProperty.prototype.hasInteriorColor = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.UpdateVehicleRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.UpdateVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.UpdateVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.UpdateVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    propertiesList: jspb.Message.toObjectList(msg.getPropertiesList(),
    proto.fleet_service.VehicleProperty.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.UpdateVehicleRequest}
 */
proto.fleet_service.UpdateVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.UpdateVehicleRequest;
  return proto.fleet_service.UpdateVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.UpdateVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.UpdateVehicleRequest}
 */
proto.fleet_service.UpdateVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.fleet_service.VehicleProperty;
      reader.readMessage(value,proto.fleet_service.VehicleProperty.deserializeBinaryFromReader);
      msg.addProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.UpdateVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.UpdateVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.UpdateVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPropertiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.fleet_service.VehicleProperty.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fleet_service.UpdateVehicleRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.UpdateVehicleRequest} returns this
 */
proto.fleet_service.UpdateVehicleRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated VehicleProperty properties = 2;
 * @return {!Array<!proto.fleet_service.VehicleProperty>}
 */
proto.fleet_service.UpdateVehicleRequest.prototype.getPropertiesList = function() {
  return /** @type{!Array<!proto.fleet_service.VehicleProperty>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fleet_service.VehicleProperty, 2));
};


/**
 * @param {!Array<!proto.fleet_service.VehicleProperty>} value
 * @return {!proto.fleet_service.UpdateVehicleRequest} returns this
*/
proto.fleet_service.UpdateVehicleRequest.prototype.setPropertiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fleet_service.VehicleProperty=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.VehicleProperty}
 */
proto.fleet_service.UpdateVehicleRequest.prototype.addProperties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fleet_service.VehicleProperty, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.UpdateVehicleRequest} returns this
 */
proto.fleet_service.UpdateVehicleRequest.prototype.clearPropertiesList = function() {
  return this.setPropertiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.UpdateVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.UpdateVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.UpdateVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.UpdateVehicleResponse}
 */
proto.fleet_service.UpdateVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.UpdateVehicleResponse;
  return proto.fleet_service.UpdateVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.UpdateVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.UpdateVehicleResponse}
 */
proto.fleet_service.UpdateVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.UpdateVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.UpdateVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.UpdateVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.fleet_service.UpdateVehicleResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.UpdateVehicleResponse} returns this
 */
proto.fleet_service.UpdateVehicleResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.ListDealershipsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ListDealershipsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ListDealershipsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ListDealershipsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListDealershipsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    proto.fleet_service.DealershipCriteria.toObject, includeInstance),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    resultsPerPage: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ListDealershipsRequest}
 */
proto.fleet_service.ListDealershipsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ListDealershipsRequest;
  return proto.fleet_service.ListDealershipsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ListDealershipsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ListDealershipsRequest}
 */
proto.fleet_service.ListDealershipsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fleet_service.DealershipCriteria;
      reader.readMessage(value,proto.fleet_service.DealershipCriteria.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultsPerPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ListDealershipsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ListDealershipsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ListDealershipsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListDealershipsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fleet_service.DealershipCriteria.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getResultsPerPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated DealershipCriteria criteria = 1;
 * @return {!Array<!proto.fleet_service.DealershipCriteria>}
 */
proto.fleet_service.ListDealershipsRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.fleet_service.DealershipCriteria>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fleet_service.DealershipCriteria, 1));
};


/**
 * @param {!Array<!proto.fleet_service.DealershipCriteria>} value
 * @return {!proto.fleet_service.ListDealershipsRequest} returns this
*/
proto.fleet_service.ListDealershipsRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fleet_service.DealershipCriteria=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.DealershipCriteria}
 */
proto.fleet_service.ListDealershipsRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fleet_service.DealershipCriteria, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.ListDealershipsRequest} returns this
 */
proto.fleet_service.ListDealershipsRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.fleet_service.ListDealershipsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.ListDealershipsRequest} returns this
 */
proto.fleet_service.ListDealershipsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 results_per_page = 3;
 * @return {number}
 */
proto.fleet_service.ListDealershipsRequest.prototype.getResultsPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.ListDealershipsRequest} returns this
 */
proto.fleet_service.ListDealershipsRequest.prototype.setResultsPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.ListDealershipsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ListDealershipsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ListDealershipsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ListDealershipsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListDealershipsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipsList: jspb.Message.toObjectList(msg.getDealershipsList(),
    domain_pb.Dealership.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ListDealershipsResponse}
 */
proto.fleet_service.ListDealershipsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ListDealershipsResponse;
  return proto.fleet_service.ListDealershipsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ListDealershipsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ListDealershipsResponse}
 */
proto.fleet_service.ListDealershipsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_pb.Dealership;
      reader.readMessage(value,domain_pb.Dealership.deserializeBinaryFromReader);
      msg.addDealerships(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ListDealershipsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ListDealershipsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ListDealershipsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListDealershipsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      domain_pb.Dealership.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated domain.Dealership dealerships = 1;
 * @return {!Array<!proto.domain.Dealership>}
 */
proto.fleet_service.ListDealershipsResponse.prototype.getDealershipsList = function() {
  return /** @type{!Array<!proto.domain.Dealership>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.Dealership, 1));
};


/**
 * @param {!Array<!proto.domain.Dealership>} value
 * @return {!proto.fleet_service.ListDealershipsResponse} returns this
*/
proto.fleet_service.ListDealershipsResponse.prototype.setDealershipsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.domain.Dealership=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Dealership}
 */
proto.fleet_service.ListDealershipsResponse.prototype.addDealerships = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.domain.Dealership, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.ListDealershipsResponse} returns this
 */
proto.fleet_service.ListDealershipsResponse.prototype.clearDealershipsList = function() {
  return this.setDealershipsList([]);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.fleet_service.ListDealershipsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.ListDealershipsResponse} returns this
 */
proto.fleet_service.ListDealershipsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetDealershipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetDealershipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetDealershipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetDealershipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetDealershipRequest}
 */
proto.fleet_service.GetDealershipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetDealershipRequest;
  return proto.fleet_service.GetDealershipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetDealershipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetDealershipRequest}
 */
proto.fleet_service.GetDealershipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetDealershipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetDealershipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetDealershipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetDealershipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fleet_service.GetDealershipRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetDealershipRequest} returns this
 */
proto.fleet_service.GetDealershipRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetDealershipResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetDealershipResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetDealershipResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetDealershipResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealership: (f = msg.getDealership()) && domain_pb.Dealership.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetDealershipResponse}
 */
proto.fleet_service.GetDealershipResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetDealershipResponse;
  return proto.fleet_service.GetDealershipResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetDealershipResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetDealershipResponse}
 */
proto.fleet_service.GetDealershipResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_pb.Dealership;
      reader.readMessage(value,domain_pb.Dealership.deserializeBinaryFromReader);
      msg.setDealership(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetDealershipResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetDealershipResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetDealershipResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetDealershipResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealership();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      domain_pb.Dealership.serializeBinaryToWriter
    );
  }
};


/**
 * optional domain.Dealership dealership = 1;
 * @return {?proto.domain.Dealership}
 */
proto.fleet_service.GetDealershipResponse.prototype.getDealership = function() {
  return /** @type{?proto.domain.Dealership} */ (
    jspb.Message.getWrapperField(this, domain_pb.Dealership, 1));
};


/**
 * @param {?proto.domain.Dealership|undefined} value
 * @return {!proto.fleet_service.GetDealershipResponse} returns this
*/
proto.fleet_service.GetDealershipResponse.prototype.setDealership = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.GetDealershipResponse} returns this
 */
proto.fleet_service.GetDealershipResponse.prototype.clearDealership = function() {
  return this.setDealership(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.GetDealershipResponse.prototype.hasDealership = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.DealershipCriteria.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.DealershipCriteria.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.DealershipCriteria} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.DealershipCriteria.toObject = function(includeInstance, msg) {
  var f, obj = {
    property: jspb.Message.getFieldWithDefault(msg, 1, ""),
    query: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.DealershipCriteria}
 */
proto.fleet_service.DealershipCriteria.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.DealershipCriteria;
  return proto.fleet_service.DealershipCriteria.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.DealershipCriteria} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.DealershipCriteria}
 */
proto.fleet_service.DealershipCriteria.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProperty(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.DealershipCriteria.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.DealershipCriteria.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.DealershipCriteria} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.DealershipCriteria.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProperty();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string property = 1;
 * @return {string}
 */
proto.fleet_service.DealershipCriteria.prototype.getProperty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.DealershipCriteria} returns this
 */
proto.fleet_service.DealershipCriteria.prototype.setProperty = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string query = 2;
 * @return {string}
 */
proto.fleet_service.DealershipCriteria.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.DealershipCriteria} returns this
 */
proto.fleet_service.DealershipCriteria.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.StartVehicleInspectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.StartVehicleInspectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.StartVehicleInspectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.StartVehicleInspectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.StartVehicleInspectionRequest}
 */
proto.fleet_service.StartVehicleInspectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.StartVehicleInspectionRequest;
  return proto.fleet_service.StartVehicleInspectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.StartVehicleInspectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.StartVehicleInspectionRequest}
 */
proto.fleet_service.StartVehicleInspectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.StartVehicleInspectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.StartVehicleInspectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.StartVehicleInspectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.StartVehicleInspectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.StartVehicleInspectionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.StartVehicleInspectionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.StartVehicleInspectionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.StartVehicleInspectionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionKey: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.StartVehicleInspectionResponse}
 */
proto.fleet_service.StartVehicleInspectionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.StartVehicleInspectionResponse;
  return proto.fleet_service.StartVehicleInspectionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.StartVehicleInspectionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.StartVehicleInspectionResponse}
 */
proto.fleet_service.StartVehicleInspectionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.StartVehicleInspectionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.StartVehicleInspectionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.StartVehicleInspectionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.StartVehicleInspectionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_key = 1;
 * @return {string}
 */
proto.fleet_service.StartVehicleInspectionResponse.prototype.getSessionKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.StartVehicleInspectionResponse} returns this
 */
proto.fleet_service.StartVehicleInspectionResponse.prototype.setSessionKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetVehicleInspectionResultsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetVehicleInspectionResultsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetVehicleInspectionResultsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetVehicleInspectionResultsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    jwt: jspb.Message.getFieldWithDefault(msg, 1, ""),
    request: (f = msg.getRequest()) && inspection_pb.GetInspectionResultsRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetVehicleInspectionResultsRequest}
 */
proto.fleet_service.GetVehicleInspectionResultsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetVehicleInspectionResultsRequest;
  return proto.fleet_service.GetVehicleInspectionResultsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetVehicleInspectionResultsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetVehicleInspectionResultsRequest}
 */
proto.fleet_service.GetVehicleInspectionResultsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJwt(value);
      break;
    case 2:
      var value = new inspection_pb.GetInspectionResultsRequest;
      reader.readMessage(value,inspection_pb.GetInspectionResultsRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetVehicleInspectionResultsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetVehicleInspectionResultsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetVehicleInspectionResultsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetVehicleInspectionResultsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJwt();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      inspection_pb.GetInspectionResultsRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string jwt = 1;
 * @return {string}
 */
proto.fleet_service.GetVehicleInspectionResultsRequest.prototype.getJwt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetVehicleInspectionResultsRequest} returns this
 */
proto.fleet_service.GetVehicleInspectionResultsRequest.prototype.setJwt = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional inspection.GetInspectionResultsRequest request = 2;
 * @return {?proto.inspection.GetInspectionResultsRequest}
 */
proto.fleet_service.GetVehicleInspectionResultsRequest.prototype.getRequest = function() {
  return /** @type{?proto.inspection.GetInspectionResultsRequest} */ (
    jspb.Message.getWrapperField(this, inspection_pb.GetInspectionResultsRequest, 2));
};


/**
 * @param {?proto.inspection.GetInspectionResultsRequest|undefined} value
 * @return {!proto.fleet_service.GetVehicleInspectionResultsRequest} returns this
*/
proto.fleet_service.GetVehicleInspectionResultsRequest.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.GetVehicleInspectionResultsRequest} returns this
 */
proto.fleet_service.GetVehicleInspectionResultsRequest.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.GetVehicleInspectionResultsRequest.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetAvailableModelsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetAvailableModelsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetAvailableModelsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetAvailableModelsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    make: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetAvailableModelsRequest}
 */
proto.fleet_service.GetAvailableModelsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetAvailableModelsRequest;
  return proto.fleet_service.GetAvailableModelsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetAvailableModelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetAvailableModelsRequest}
 */
proto.fleet_service.GetAvailableModelsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetAvailableModelsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetAvailableModelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetAvailableModelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetAvailableModelsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string make = 1;
 * @return {string}
 */
proto.fleet_service.GetAvailableModelsRequest.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetAvailableModelsRequest} returns this
 */
proto.fleet_service.GetAvailableModelsRequest.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.GetAvailableModelsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetAvailableModelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetAvailableModelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetAvailableModelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetAvailableModelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetAvailableModelsResponse}
 */
proto.fleet_service.GetAvailableModelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetAvailableModelsResponse;
  return proto.fleet_service.GetAvailableModelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetAvailableModelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetAvailableModelsResponse}
 */
proto.fleet_service.GetAvailableModelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addModels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetAvailableModelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetAvailableModelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetAvailableModelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetAvailableModelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string models = 1;
 * @return {!Array<string>}
 */
proto.fleet_service.GetAvailableModelsResponse.prototype.getModelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fleet_service.GetAvailableModelsResponse} returns this
 */
proto.fleet_service.GetAvailableModelsResponse.prototype.setModelsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.GetAvailableModelsResponse} returns this
 */
proto.fleet_service.GetAvailableModelsResponse.prototype.addModels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.GetAvailableModelsResponse} returns this
 */
proto.fleet_service.GetAvailableModelsResponse.prototype.clearModelsList = function() {
  return this.setModelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetAvailableTrimsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetAvailableTrimsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetAvailableTrimsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetAvailableTrimsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    make: jspb.Message.getFieldWithDefault(msg, 1, ""),
    model: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetAvailableTrimsRequest}
 */
proto.fleet_service.GetAvailableTrimsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetAvailableTrimsRequest;
  return proto.fleet_service.GetAvailableTrimsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetAvailableTrimsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetAvailableTrimsRequest}
 */
proto.fleet_service.GetAvailableTrimsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetAvailableTrimsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetAvailableTrimsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetAvailableTrimsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetAvailableTrimsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string make = 1;
 * @return {string}
 */
proto.fleet_service.GetAvailableTrimsRequest.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetAvailableTrimsRequest} returns this
 */
proto.fleet_service.GetAvailableTrimsRequest.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model = 2;
 * @return {string}
 */
proto.fleet_service.GetAvailableTrimsRequest.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetAvailableTrimsRequest} returns this
 */
proto.fleet_service.GetAvailableTrimsRequest.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.GetAvailableTrimsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetAvailableTrimsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetAvailableTrimsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetAvailableTrimsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetAvailableTrimsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    trimsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetAvailableTrimsResponse}
 */
proto.fleet_service.GetAvailableTrimsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetAvailableTrimsResponse;
  return proto.fleet_service.GetAvailableTrimsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetAvailableTrimsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetAvailableTrimsResponse}
 */
proto.fleet_service.GetAvailableTrimsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addTrims(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetAvailableTrimsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetAvailableTrimsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetAvailableTrimsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetAvailableTrimsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrimsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string trims = 1;
 * @return {!Array<string>}
 */
proto.fleet_service.GetAvailableTrimsResponse.prototype.getTrimsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fleet_service.GetAvailableTrimsResponse} returns this
 */
proto.fleet_service.GetAvailableTrimsResponse.prototype.setTrimsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.GetAvailableTrimsResponse} returns this
 */
proto.fleet_service.GetAvailableTrimsResponse.prototype.addTrims = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.GetAvailableTrimsResponse} returns this
 */
proto.fleet_service.GetAvailableTrimsResponse.prototype.clearTrimsList = function() {
  return this.setTrimsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ValidateVinRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ValidateVinRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ValidateVinRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ValidateVinRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vin: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ValidateVinRequest}
 */
proto.fleet_service.ValidateVinRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ValidateVinRequest;
  return proto.fleet_service.ValidateVinRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ValidateVinRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ValidateVinRequest}
 */
proto.fleet_service.ValidateVinRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ValidateVinRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ValidateVinRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ValidateVinRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ValidateVinRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string vin = 1;
 * @return {string}
 */
proto.fleet_service.ValidateVinRequest.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ValidateVinRequest} returns this
 */
proto.fleet_service.ValidateVinRequest.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dealership_id = 2;
 * @return {string}
 */
proto.fleet_service.ValidateVinRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ValidateVinRequest} returns this
 */
proto.fleet_service.ValidateVinRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ValidateVinResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ValidateVinResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ValidateVinResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ValidateVinResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    make: jspb.Message.getFieldWithDefault(msg, 3, ""),
    model: jspb.Message.getFieldWithDefault(msg, 4, ""),
    color: jspb.Message.getFieldWithDefault(msg, 5, ""),
    trim: jspb.Message.getFieldWithDefault(msg, 6, ""),
    year: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ValidateVinResponse}
 */
proto.fleet_service.ValidateVinResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ValidateVinResponse;
  return proto.fleet_service.ValidateVinResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ValidateVinResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ValidateVinResponse}
 */
proto.fleet_service.ValidateVinResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrim(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ValidateVinResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ValidateVinResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ValidateVinResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ValidateVinResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTrim();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fleet_service.ValidateVinResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ValidateVinResponse} returns this
 */
proto.fleet_service.ValidateVinResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vin = 2;
 * @return {string}
 */
proto.fleet_service.ValidateVinResponse.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ValidateVinResponse} returns this
 */
proto.fleet_service.ValidateVinResponse.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string make = 3;
 * @return {string}
 */
proto.fleet_service.ValidateVinResponse.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ValidateVinResponse} returns this
 */
proto.fleet_service.ValidateVinResponse.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string model = 4;
 * @return {string}
 */
proto.fleet_service.ValidateVinResponse.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ValidateVinResponse} returns this
 */
proto.fleet_service.ValidateVinResponse.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string color = 5;
 * @return {string}
 */
proto.fleet_service.ValidateVinResponse.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ValidateVinResponse} returns this
 */
proto.fleet_service.ValidateVinResponse.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string trim = 6;
 * @return {string}
 */
proto.fleet_service.ValidateVinResponse.prototype.getTrim = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ValidateVinResponse} returns this
 */
proto.fleet_service.ValidateVinResponse.prototype.setTrim = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string year = 7;
 * @return {string}
 */
proto.fleet_service.ValidateVinResponse.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ValidateVinResponse} returns this
 */
proto.fleet_service.ValidateVinResponse.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.UpdateVehicleCustodyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.UpdateVehicleCustodyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.UpdateVehicleCustodyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateVehicleCustodyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.UpdateVehicleCustodyRequest}
 */
proto.fleet_service.UpdateVehicleCustodyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.UpdateVehicleCustodyRequest;
  return proto.fleet_service.UpdateVehicleCustodyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.UpdateVehicleCustodyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.UpdateVehicleCustodyRequest}
 */
proto.fleet_service.UpdateVehicleCustodyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.UpdateVehicleCustodyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.UpdateVehicleCustodyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.UpdateVehicleCustodyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateVehicleCustodyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fleet_service.UpdateVehicleCustodyRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.UpdateVehicleCustodyRequest} returns this
 */
proto.fleet_service.UpdateVehicleCustodyRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dealership_id = 2;
 * @return {string}
 */
proto.fleet_service.UpdateVehicleCustodyRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.UpdateVehicleCustodyRequest} returns this
 */
proto.fleet_service.UpdateVehicleCustodyRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_id = 3;
 * @return {string}
 */
proto.fleet_service.UpdateVehicleCustodyRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.UpdateVehicleCustodyRequest} returns this
 */
proto.fleet_service.UpdateVehicleCustodyRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.UpdateVehicleCustodyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.UpdateVehicleCustodyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.UpdateVehicleCustodyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateVehicleCustodyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.UpdateVehicleCustodyResponse}
 */
proto.fleet_service.UpdateVehicleCustodyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.UpdateVehicleCustodyResponse;
  return proto.fleet_service.UpdateVehicleCustodyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.UpdateVehicleCustodyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.UpdateVehicleCustodyResponse}
 */
proto.fleet_service.UpdateVehicleCustodyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.UpdateVehicleCustodyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.UpdateVehicleCustodyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.UpdateVehicleCustodyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateVehicleCustodyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetLatestCustodyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetLatestCustodyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetLatestCustodyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetLatestCustodyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    custodyType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetLatestCustodyRequest}
 */
proto.fleet_service.GetLatestCustodyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetLatestCustodyRequest;
  return proto.fleet_service.GetLatestCustodyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetLatestCustodyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetLatestCustodyRequest}
 */
proto.fleet_service.GetLatestCustodyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {!proto.fleet_service.GetLatestCustodyRequest.CustodyType} */ (reader.readEnum());
      msg.setCustodyType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetLatestCustodyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetLatestCustodyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetLatestCustodyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetLatestCustodyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustodyType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fleet_service.GetLatestCustodyRequest.CustodyType = {
  DEALERSHIP: 0,
  CUSTOMER: 1,
  ANY: 2
};

/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.fleet_service.GetLatestCustodyRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetLatestCustodyRequest} returns this
 */
proto.fleet_service.GetLatestCustodyRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CustodyType custody_type = 2;
 * @return {!proto.fleet_service.GetLatestCustodyRequest.CustodyType}
 */
proto.fleet_service.GetLatestCustodyRequest.prototype.getCustodyType = function() {
  return /** @type {!proto.fleet_service.GetLatestCustodyRequest.CustodyType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fleet_service.GetLatestCustodyRequest.CustodyType} value
 * @return {!proto.fleet_service.GetLatestCustodyRequest} returns this
 */
proto.fleet_service.GetLatestCustodyRequest.prototype.setCustodyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.fleet_service.GetLatestCustodyResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.fleet_service.GetLatestCustodyResponse.CustodianCase = {
  CUSTODIAN_NOT_SET: 0,
  DEALERSHIP: 1,
  CUSTOMER: 2
};

/**
 * @return {proto.fleet_service.GetLatestCustodyResponse.CustodianCase}
 */
proto.fleet_service.GetLatestCustodyResponse.prototype.getCustodianCase = function() {
  return /** @type {proto.fleet_service.GetLatestCustodyResponse.CustodianCase} */(jspb.Message.computeOneofCase(this, proto.fleet_service.GetLatestCustodyResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetLatestCustodyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetLatestCustodyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetLatestCustodyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetLatestCustodyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealership: (f = msg.getDealership()) && dealer_pb.Dealership.toObject(includeInstance, f),
    customer: (f = msg.getCustomer()) && domain_pb.Customer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetLatestCustodyResponse}
 */
proto.fleet_service.GetLatestCustodyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetLatestCustodyResponse;
  return proto.fleet_service.GetLatestCustodyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetLatestCustodyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetLatestCustodyResponse}
 */
proto.fleet_service.GetLatestCustodyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dealer_pb.Dealership;
      reader.readMessage(value,dealer_pb.Dealership.deserializeBinaryFromReader);
      msg.setDealership(value);
      break;
    case 2:
      var value = new domain_pb.Customer;
      reader.readMessage(value,domain_pb.Customer.deserializeBinaryFromReader);
      msg.setCustomer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetLatestCustodyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetLatestCustodyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetLatestCustodyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetLatestCustodyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealership();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dealer_pb.Dealership.serializeBinaryToWriter
    );
  }
  f = message.getCustomer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      domain_pb.Customer.serializeBinaryToWriter
    );
  }
};


/**
 * optional dealer.Dealership dealership = 1;
 * @return {?proto.dealer.Dealership}
 */
proto.fleet_service.GetLatestCustodyResponse.prototype.getDealership = function() {
  return /** @type{?proto.dealer.Dealership} */ (
    jspb.Message.getWrapperField(this, dealer_pb.Dealership, 1));
};


/**
 * @param {?proto.dealer.Dealership|undefined} value
 * @return {!proto.fleet_service.GetLatestCustodyResponse} returns this
*/
proto.fleet_service.GetLatestCustodyResponse.prototype.setDealership = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.fleet_service.GetLatestCustodyResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.GetLatestCustodyResponse} returns this
 */
proto.fleet_service.GetLatestCustodyResponse.prototype.clearDealership = function() {
  return this.setDealership(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.GetLatestCustodyResponse.prototype.hasDealership = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional domain.Customer customer = 2;
 * @return {?proto.domain.Customer}
 */
proto.fleet_service.GetLatestCustodyResponse.prototype.getCustomer = function() {
  return /** @type{?proto.domain.Customer} */ (
    jspb.Message.getWrapperField(this, domain_pb.Customer, 2));
};


/**
 * @param {?proto.domain.Customer|undefined} value
 * @return {!proto.fleet_service.GetLatestCustodyResponse} returns this
*/
proto.fleet_service.GetLatestCustodyResponse.prototype.setCustomer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.fleet_service.GetLatestCustodyResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.GetLatestCustodyResponse} returns this
 */
proto.fleet_service.GetLatestCustodyResponse.prototype.clearCustomer = function() {
  return this.setCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.GetLatestCustodyResponse.prototype.hasCustomer = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetVehicleInfleetingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetVehicleInfleetingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetVehicleInfleetingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetVehicleInfleetingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    infleetingRequestType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetVehicleInfleetingRequest}
 */
proto.fleet_service.GetVehicleInfleetingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetVehicleInfleetingRequest;
  return proto.fleet_service.GetVehicleInfleetingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetVehicleInfleetingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetVehicleInfleetingRequest}
 */
proto.fleet_service.GetVehicleInfleetingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.fleet_service.GetVehicleInfleetingRequest.InfleetingRequestType} */ (reader.readEnum());
      msg.setInfleetingRequestType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetVehicleInfleetingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetVehicleInfleetingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetVehicleInfleetingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetVehicleInfleetingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInfleetingRequestType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fleet_service.GetVehicleInfleetingRequest.InfleetingRequestType = {
  LATEST: 0,
  ALL: 1
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.fleet_service.GetVehicleInfleetingRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.GetVehicleInfleetingRequest} returns this
 */
proto.fleet_service.GetVehicleInfleetingRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional InfleetingRequestType infleeting_request_type = 2;
 * @return {!proto.fleet_service.GetVehicleInfleetingRequest.InfleetingRequestType}
 */
proto.fleet_service.GetVehicleInfleetingRequest.prototype.getInfleetingRequestType = function() {
  return /** @type {!proto.fleet_service.GetVehicleInfleetingRequest.InfleetingRequestType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fleet_service.GetVehicleInfleetingRequest.InfleetingRequestType} value
 * @return {!proto.fleet_service.GetVehicleInfleetingRequest} returns this
 */
proto.fleet_service.GetVehicleInfleetingRequest.prototype.setInfleetingRequestType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.GetVehicleInfleetingResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.GetVehicleInfleetingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.GetVehicleInfleetingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.GetVehicleInfleetingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetVehicleInfleetingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    infleetingsList: jspb.Message.toObjectList(msg.getInfleetingsList(),
    vehicle_pb.Infleeting.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.GetVehicleInfleetingResponse}
 */
proto.fleet_service.GetVehicleInfleetingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.GetVehicleInfleetingResponse;
  return proto.fleet_service.GetVehicleInfleetingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.GetVehicleInfleetingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.GetVehicleInfleetingResponse}
 */
proto.fleet_service.GetVehicleInfleetingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vehicle_pb.Infleeting;
      reader.readMessage(value,vehicle_pb.Infleeting.deserializeBinaryFromReader);
      msg.addInfleetings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.GetVehicleInfleetingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.GetVehicleInfleetingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.GetVehicleInfleetingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.GetVehicleInfleetingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfleetingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      vehicle_pb.Infleeting.serializeBinaryToWriter
    );
  }
};


/**
 * repeated vehicle.Infleeting infleetings = 1;
 * @return {!Array<!proto.vehicle.Infleeting>}
 */
proto.fleet_service.GetVehicleInfleetingResponse.prototype.getInfleetingsList = function() {
  return /** @type{!Array<!proto.vehicle.Infleeting>} */ (
    jspb.Message.getRepeatedWrapperField(this, vehicle_pb.Infleeting, 1));
};


/**
 * @param {!Array<!proto.vehicle.Infleeting>} value
 * @return {!proto.fleet_service.GetVehicleInfleetingResponse} returns this
*/
proto.fleet_service.GetVehicleInfleetingResponse.prototype.setInfleetingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vehicle.Infleeting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Infleeting}
 */
proto.fleet_service.GetVehicleInfleetingResponse.prototype.addInfleetings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vehicle.Infleeting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.GetVehicleInfleetingResponse} returns this
 */
proto.fleet_service.GetVehicleInfleetingResponse.prototype.clearInfleetingsList = function() {
  return this.setInfleetingsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.UpdateVehicleInfleetingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.UpdateVehicleInfleetingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.UpdateVehicleInfleetingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateVehicleInfleetingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    infleeting: (f = msg.getInfleeting()) && vehicle_pb.Infleeting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.UpdateVehicleInfleetingRequest}
 */
proto.fleet_service.UpdateVehicleInfleetingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.UpdateVehicleInfleetingRequest;
  return proto.fleet_service.UpdateVehicleInfleetingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.UpdateVehicleInfleetingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.UpdateVehicleInfleetingRequest}
 */
proto.fleet_service.UpdateVehicleInfleetingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vehicle_pb.Infleeting;
      reader.readMessage(value,vehicle_pb.Infleeting.deserializeBinaryFromReader);
      msg.setInfleeting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.UpdateVehicleInfleetingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.UpdateVehicleInfleetingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.UpdateVehicleInfleetingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateVehicleInfleetingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfleeting();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vehicle_pb.Infleeting.serializeBinaryToWriter
    );
  }
};


/**
 * optional vehicle.Infleeting infleeting = 1;
 * @return {?proto.vehicle.Infleeting}
 */
proto.fleet_service.UpdateVehicleInfleetingRequest.prototype.getInfleeting = function() {
  return /** @type{?proto.vehicle.Infleeting} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Infleeting, 1));
};


/**
 * @param {?proto.vehicle.Infleeting|undefined} value
 * @return {!proto.fleet_service.UpdateVehicleInfleetingRequest} returns this
*/
proto.fleet_service.UpdateVehicleInfleetingRequest.prototype.setInfleeting = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.UpdateVehicleInfleetingRequest} returns this
 */
proto.fleet_service.UpdateVehicleInfleetingRequest.prototype.clearInfleeting = function() {
  return this.setInfleeting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.UpdateVehicleInfleetingRequest.prototype.hasInfleeting = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.UpdateVehicleInfleetingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.UpdateVehicleInfleetingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.UpdateVehicleInfleetingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateVehicleInfleetingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    infleeting: (f = msg.getInfleeting()) && vehicle_pb.Infleeting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.UpdateVehicleInfleetingResponse}
 */
proto.fleet_service.UpdateVehicleInfleetingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.UpdateVehicleInfleetingResponse;
  return proto.fleet_service.UpdateVehicleInfleetingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.UpdateVehicleInfleetingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.UpdateVehicleInfleetingResponse}
 */
proto.fleet_service.UpdateVehicleInfleetingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vehicle_pb.Infleeting;
      reader.readMessage(value,vehicle_pb.Infleeting.deserializeBinaryFromReader);
      msg.setInfleeting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.UpdateVehicleInfleetingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.UpdateVehicleInfleetingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.UpdateVehicleInfleetingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateVehicleInfleetingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfleeting();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vehicle_pb.Infleeting.serializeBinaryToWriter
    );
  }
};


/**
 * optional vehicle.Infleeting infleeting = 1;
 * @return {?proto.vehicle.Infleeting}
 */
proto.fleet_service.UpdateVehicleInfleetingResponse.prototype.getInfleeting = function() {
  return /** @type{?proto.vehicle.Infleeting} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Infleeting, 1));
};


/**
 * @param {?proto.vehicle.Infleeting|undefined} value
 * @return {!proto.fleet_service.UpdateVehicleInfleetingResponse} returns this
*/
proto.fleet_service.UpdateVehicleInfleetingResponse.prototype.setInfleeting = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.UpdateVehicleInfleetingResponse} returns this
 */
proto.fleet_service.UpdateVehicleInfleetingResponse.prototype.clearInfleeting = function() {
  return this.setInfleeting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.UpdateVehicleInfleetingResponse.prototype.hasInfleeting = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.RecordMileageDrivenLogRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.RecordMileageDrivenLogRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.RecordMileageDrivenLogRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.RecordMileageDrivenLogRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    time: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.RecordMileageDrivenLogRequest}
 */
proto.fleet_service.RecordMileageDrivenLogRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.RecordMileageDrivenLogRequest;
  return proto.fleet_service.RecordMileageDrivenLogRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.RecordMileageDrivenLogRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.RecordMileageDrivenLogRequest}
 */
proto.fleet_service.RecordMileageDrivenLogRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.RecordMileageDrivenLogRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.RecordMileageDrivenLogRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.RecordMileageDrivenLogRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.RecordMileageDrivenLogRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.fleet_service.RecordMileageDrivenLogRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.RecordMileageDrivenLogRequest} returns this
 */
proto.fleet_service.RecordMileageDrivenLogRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string time = 2;
 * @return {string}
 */
proto.fleet_service.RecordMileageDrivenLogRequest.prototype.getTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.RecordMileageDrivenLogRequest} returns this
 */
proto.fleet_service.RecordMileageDrivenLogRequest.prototype.setTime = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.RecordMileageDrivenLogResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.RecordMileageDrivenLogResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.RecordMileageDrivenLogResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.RecordMileageDrivenLogResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.RecordMileageDrivenLogResponse}
 */
proto.fleet_service.RecordMileageDrivenLogResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.RecordMileageDrivenLogResponse;
  return proto.fleet_service.RecordMileageDrivenLogResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.RecordMileageDrivenLogResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.RecordMileageDrivenLogResponse}
 */
proto.fleet_service.RecordMileageDrivenLogResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.RecordMileageDrivenLogResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.RecordMileageDrivenLogResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.RecordMileageDrivenLogResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.RecordMileageDrivenLogResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.VehiclePickup.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.VehiclePickup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.VehiclePickup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.VehiclePickup.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    odometer: jspb.Message.getFieldWithDefault(msg, 3, 0),
    time: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.VehiclePickup}
 */
proto.fleet_service.VehiclePickup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.VehiclePickup;
  return proto.fleet_service.VehiclePickup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.VehiclePickup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.VehiclePickup}
 */
proto.fleet_service.VehiclePickup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOdometer(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.VehiclePickup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.VehiclePickup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.VehiclePickup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.VehiclePickup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOdometer();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.fleet_service.VehiclePickup.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.VehiclePickup} returns this
 */
proto.fleet_service.VehiclePickup.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.fleet_service.VehiclePickup.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.VehiclePickup} returns this
 */
proto.fleet_service.VehiclePickup.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 odometer = 3;
 * @return {number}
 */
proto.fleet_service.VehiclePickup.prototype.getOdometer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.VehiclePickup} returns this
 */
proto.fleet_service.VehiclePickup.prototype.setOdometer = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string time = 4;
 * @return {string}
 */
proto.fleet_service.VehiclePickup.prototype.getTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.VehiclePickup} returns this
 */
proto.fleet_service.VehiclePickup.prototype.setTime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.ListVehiclePickupsQuery.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ListVehiclePickupsQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ListVehiclePickupsQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ListVehiclePickupsQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListVehiclePickupsQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    excludeReturned: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    subscriptionIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ListVehiclePickupsQuery}
 */
proto.fleet_service.ListVehiclePickupsQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ListVehiclePickupsQuery;
  return proto.fleet_service.ListVehiclePickupsQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ListVehiclePickupsQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ListVehiclePickupsQuery}
 */
proto.fleet_service.ListVehiclePickupsQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludeReturned(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSubscriptionIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ListVehiclePickupsQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ListVehiclePickupsQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ListVehiclePickupsQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListVehiclePickupsQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExcludeReturned();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSubscriptionIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional bool exclude_returned = 1;
 * @return {boolean}
 */
proto.fleet_service.ListVehiclePickupsQuery.prototype.getExcludeReturned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.ListVehiclePickupsQuery} returns this
 */
proto.fleet_service.ListVehiclePickupsQuery.prototype.setExcludeReturned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated string subscription_ids = 2;
 * @return {!Array<string>}
 */
proto.fleet_service.ListVehiclePickupsQuery.prototype.getSubscriptionIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fleet_service.ListVehiclePickupsQuery} returns this
 */
proto.fleet_service.ListVehiclePickupsQuery.prototype.setSubscriptionIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.ListVehiclePickupsQuery} returns this
 */
proto.fleet_service.ListVehiclePickupsQuery.prototype.addSubscriptionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.ListVehiclePickupsQuery} returns this
 */
proto.fleet_service.ListVehiclePickupsQuery.prototype.clearSubscriptionIdsList = function() {
  return this.setSubscriptionIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ListVehiclePickupsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ListVehiclePickupsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ListVehiclePickupsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListVehiclePickupsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    query: (f = msg.getQuery()) && proto.fleet_service.ListVehiclePickupsQuery.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ListVehiclePickupsRequest}
 */
proto.fleet_service.ListVehiclePickupsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ListVehiclePickupsRequest;
  return proto.fleet_service.ListVehiclePickupsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ListVehiclePickupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ListVehiclePickupsRequest}
 */
proto.fleet_service.ListVehiclePickupsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = new proto.fleet_service.ListVehiclePickupsQuery;
      reader.readMessage(value,proto.fleet_service.ListVehiclePickupsQuery.deserializeBinaryFromReader);
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ListVehiclePickupsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ListVehiclePickupsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ListVehiclePickupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListVehiclePickupsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuery();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fleet_service.ListVehiclePickupsQuery.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.fleet_service.ListVehiclePickupsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.ListVehiclePickupsRequest} returns this
 */
proto.fleet_service.ListVehiclePickupsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.fleet_service.ListVehiclePickupsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ListVehiclePickupsRequest} returns this
 */
proto.fleet_service.ListVehiclePickupsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ListVehiclePickupsQuery query = 3;
 * @return {?proto.fleet_service.ListVehiclePickupsQuery}
 */
proto.fleet_service.ListVehiclePickupsRequest.prototype.getQuery = function() {
  return /** @type{?proto.fleet_service.ListVehiclePickupsQuery} */ (
    jspb.Message.getWrapperField(this, proto.fleet_service.ListVehiclePickupsQuery, 3));
};


/**
 * @param {?proto.fleet_service.ListVehiclePickupsQuery|undefined} value
 * @return {!proto.fleet_service.ListVehiclePickupsRequest} returns this
*/
proto.fleet_service.ListVehiclePickupsRequest.prototype.setQuery = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.ListVehiclePickupsRequest} returns this
 */
proto.fleet_service.ListVehiclePickupsRequest.prototype.clearQuery = function() {
  return this.setQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.ListVehiclePickupsRequest.prototype.hasQuery = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.ListVehiclePickupsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ListVehiclePickupsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ListVehiclePickupsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ListVehiclePickupsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListVehiclePickupsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pickupsList: jspb.Message.toObjectList(msg.getPickupsList(),
    proto.fleet_service.VehiclePickup.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ListVehiclePickupsResponse}
 */
proto.fleet_service.ListVehiclePickupsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ListVehiclePickupsResponse;
  return proto.fleet_service.ListVehiclePickupsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ListVehiclePickupsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ListVehiclePickupsResponse}
 */
proto.fleet_service.ListVehiclePickupsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fleet_service.VehiclePickup;
      reader.readMessage(value,proto.fleet_service.VehiclePickup.deserializeBinaryFromReader);
      msg.addPickups(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ListVehiclePickupsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ListVehiclePickupsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ListVehiclePickupsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListVehiclePickupsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPickupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fleet_service.VehiclePickup.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated VehiclePickup pickups = 1;
 * @return {!Array<!proto.fleet_service.VehiclePickup>}
 */
proto.fleet_service.ListVehiclePickupsResponse.prototype.getPickupsList = function() {
  return /** @type{!Array<!proto.fleet_service.VehiclePickup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fleet_service.VehiclePickup, 1));
};


/**
 * @param {!Array<!proto.fleet_service.VehiclePickup>} value
 * @return {!proto.fleet_service.ListVehiclePickupsResponse} returns this
*/
proto.fleet_service.ListVehiclePickupsResponse.prototype.setPickupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fleet_service.VehiclePickup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.VehiclePickup}
 */
proto.fleet_service.ListVehiclePickupsResponse.prototype.addPickups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fleet_service.VehiclePickup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.ListVehiclePickupsResponse} returns this
 */
proto.fleet_service.ListVehiclePickupsResponse.prototype.clearPickupsList = function() {
  return this.setPickupsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.fleet_service.ListVehiclePickupsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ListVehiclePickupsResponse} returns this
 */
proto.fleet_service.ListVehiclePickupsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ListVehiclePickupsToken.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ListVehiclePickupsToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ListVehiclePickupsToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListVehiclePickupsToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastTime: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastPickupId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ListVehiclePickupsToken}
 */
proto.fleet_service.ListVehiclePickupsToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ListVehiclePickupsToken;
  return proto.fleet_service.ListVehiclePickupsToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ListVehiclePickupsToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ListVehiclePickupsToken}
 */
proto.fleet_service.ListVehiclePickupsToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastPickupId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ListVehiclePickupsToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ListVehiclePickupsToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ListVehiclePickupsToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListVehiclePickupsToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastTime();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastPickupId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string last_time = 1;
 * @return {string}
 */
proto.fleet_service.ListVehiclePickupsToken.prototype.getLastTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ListVehiclePickupsToken} returns this
 */
proto.fleet_service.ListVehiclePickupsToken.prototype.setLastTime = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 last_pickup_id = 2;
 * @return {number}
 */
proto.fleet_service.ListVehiclePickupsToken.prototype.getLastPickupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.ListVehiclePickupsToken} returns this
 */
proto.fleet_service.ListVehiclePickupsToken.prototype.setLastPickupId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ListInspectionReportsQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ListInspectionReportsQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ListInspectionReportsQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListInspectionReportsQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAfter: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ListInspectionReportsQuery}
 */
proto.fleet_service.ListInspectionReportsQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ListInspectionReportsQuery;
  return proto.fleet_service.ListInspectionReportsQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ListInspectionReportsQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ListInspectionReportsQuery}
 */
proto.fleet_service.ListInspectionReportsQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAfter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ListInspectionReportsQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ListInspectionReportsQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ListInspectionReportsQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListInspectionReportsQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAfter();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.fleet_service.ListInspectionReportsQuery.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ListInspectionReportsQuery} returns this
 */
proto.fleet_service.ListInspectionReportsQuery.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 created_after = 2;
 * @return {number}
 */
proto.fleet_service.ListInspectionReportsQuery.prototype.getCreatedAfter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.ListInspectionReportsQuery} returns this
 */
proto.fleet_service.ListInspectionReportsQuery.prototype.setCreatedAfter = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ListInspectionReportsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ListInspectionReportsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ListInspectionReportsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListInspectionReportsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    query: (f = msg.getQuery()) && proto.fleet_service.ListInspectionReportsQuery.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ListInspectionReportsRequest}
 */
proto.fleet_service.ListInspectionReportsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ListInspectionReportsRequest;
  return proto.fleet_service.ListInspectionReportsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ListInspectionReportsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ListInspectionReportsRequest}
 */
proto.fleet_service.ListInspectionReportsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = new proto.fleet_service.ListInspectionReportsQuery;
      reader.readMessage(value,proto.fleet_service.ListInspectionReportsQuery.deserializeBinaryFromReader);
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ListInspectionReportsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ListInspectionReportsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ListInspectionReportsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListInspectionReportsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuery();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fleet_service.ListInspectionReportsQuery.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.fleet_service.ListInspectionReportsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.ListInspectionReportsRequest} returns this
 */
proto.fleet_service.ListInspectionReportsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.fleet_service.ListInspectionReportsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ListInspectionReportsRequest} returns this
 */
proto.fleet_service.ListInspectionReportsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ListInspectionReportsQuery query = 3;
 * @return {?proto.fleet_service.ListInspectionReportsQuery}
 */
proto.fleet_service.ListInspectionReportsRequest.prototype.getQuery = function() {
  return /** @type{?proto.fleet_service.ListInspectionReportsQuery} */ (
    jspb.Message.getWrapperField(this, proto.fleet_service.ListInspectionReportsQuery, 3));
};


/**
 * @param {?proto.fleet_service.ListInspectionReportsQuery|undefined} value
 * @return {!proto.fleet_service.ListInspectionReportsRequest} returns this
*/
proto.fleet_service.ListInspectionReportsRequest.prototype.setQuery = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fleet_service.ListInspectionReportsRequest} returns this
 */
proto.fleet_service.ListInspectionReportsRequest.prototype.clearQuery = function() {
  return this.setQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fleet_service.ListInspectionReportsRequest.prototype.hasQuery = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fleet_service.ListInspectionReportsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ListInspectionReportsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ListInspectionReportsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ListInspectionReportsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListInspectionReportsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportsList: jspb.Message.toObjectList(msg.getReportsList(),
    proto.fleet_service.InspectionReport.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ListInspectionReportsResponse}
 */
proto.fleet_service.ListInspectionReportsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ListInspectionReportsResponse;
  return proto.fleet_service.ListInspectionReportsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ListInspectionReportsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ListInspectionReportsResponse}
 */
proto.fleet_service.ListInspectionReportsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fleet_service.InspectionReport;
      reader.readMessage(value,proto.fleet_service.InspectionReport.deserializeBinaryFromReader);
      msg.addReports(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ListInspectionReportsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ListInspectionReportsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ListInspectionReportsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListInspectionReportsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fleet_service.InspectionReport.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated InspectionReport reports = 1;
 * @return {!Array<!proto.fleet_service.InspectionReport>}
 */
proto.fleet_service.ListInspectionReportsResponse.prototype.getReportsList = function() {
  return /** @type{!Array<!proto.fleet_service.InspectionReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fleet_service.InspectionReport, 1));
};


/**
 * @param {!Array<!proto.fleet_service.InspectionReport>} value
 * @return {!proto.fleet_service.ListInspectionReportsResponse} returns this
*/
proto.fleet_service.ListInspectionReportsResponse.prototype.setReportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fleet_service.InspectionReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fleet_service.InspectionReport}
 */
proto.fleet_service.ListInspectionReportsResponse.prototype.addReports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fleet_service.InspectionReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fleet_service.ListInspectionReportsResponse} returns this
 */
proto.fleet_service.ListInspectionReportsResponse.prototype.clearReportsList = function() {
  return this.setReportsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.fleet_service.ListInspectionReportsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ListInspectionReportsResponse} returns this
 */
proto.fleet_service.ListInspectionReportsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.ListInspectionReportsToken.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.ListInspectionReportsToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.ListInspectionReportsToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListInspectionReportsToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastCreatedAt: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastReportId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.ListInspectionReportsToken}
 */
proto.fleet_service.ListInspectionReportsToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.ListInspectionReportsToken;
  return proto.fleet_service.ListInspectionReportsToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.ListInspectionReportsToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.ListInspectionReportsToken}
 */
proto.fleet_service.ListInspectionReportsToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastCreatedAt(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastReportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.ListInspectionReportsToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.ListInspectionReportsToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.ListInspectionReportsToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.ListInspectionReportsToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastReportId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string last_created_at = 1;
 * @return {string}
 */
proto.fleet_service.ListInspectionReportsToken.prototype.getLastCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.ListInspectionReportsToken} returns this
 */
proto.fleet_service.ListInspectionReportsToken.prototype.setLastCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 last_report_id = 2;
 * @return {number}
 */
proto.fleet_service.ListInspectionReportsToken.prototype.getLastReportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.ListInspectionReportsToken} returns this
 */
proto.fleet_service.ListInspectionReportsToken.prototype.setLastReportId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.UpdateStateToInListingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.UpdateStateToInListingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.UpdateStateToInListingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateStateToInListingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.UpdateStateToInListingRequest}
 */
proto.fleet_service.UpdateStateToInListingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.UpdateStateToInListingRequest;
  return proto.fleet_service.UpdateStateToInListingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.UpdateStateToInListingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.UpdateStateToInListingRequest}
 */
proto.fleet_service.UpdateStateToInListingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.UpdateStateToInListingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.UpdateStateToInListingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.UpdateStateToInListingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateStateToInListingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.fleet_service.UpdateStateToInListingRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.UpdateStateToInListingRequest} returns this
 */
proto.fleet_service.UpdateStateToInListingRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.UpdateStateToInListingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.UpdateStateToInListingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.UpdateStateToInListingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateStateToInListingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.UpdateStateToInListingResponse}
 */
proto.fleet_service.UpdateStateToInListingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.UpdateStateToInListingResponse;
  return proto.fleet_service.UpdateStateToInListingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.UpdateStateToInListingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.UpdateStateToInListingResponse}
 */
proto.fleet_service.UpdateStateToInListingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.UpdateStateToInListingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.UpdateStateToInListingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.UpdateStateToInListingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateStateToInListingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.UpdateStateToInProcessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.UpdateStateToInProcessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.UpdateStateToInProcessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateStateToInProcessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.UpdateStateToInProcessRequest}
 */
proto.fleet_service.UpdateStateToInProcessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.UpdateStateToInProcessRequest;
  return proto.fleet_service.UpdateStateToInProcessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.UpdateStateToInProcessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.UpdateStateToInProcessRequest}
 */
proto.fleet_service.UpdateStateToInProcessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.UpdateStateToInProcessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.UpdateStateToInProcessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.UpdateStateToInProcessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateStateToInProcessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.fleet_service.UpdateStateToInProcessRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.UpdateStateToInProcessRequest} returns this
 */
proto.fleet_service.UpdateStateToInProcessRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.UpdateStateToInProcessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.UpdateStateToInProcessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.UpdateStateToInProcessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateStateToInProcessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.UpdateStateToInProcessResponse}
 */
proto.fleet_service.UpdateStateToInProcessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.UpdateStateToInProcessResponse;
  return proto.fleet_service.UpdateStateToInProcessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.UpdateStateToInProcessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.UpdateStateToInProcessResponse}
 */
proto.fleet_service.UpdateStateToInProcessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.UpdateStateToInProcessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.UpdateStateToInProcessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.UpdateStateToInProcessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateStateToInProcessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.UpdateStateToSoldRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.UpdateStateToSoldRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.UpdateStateToSoldRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateStateToSoldRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.UpdateStateToSoldRequest}
 */
proto.fleet_service.UpdateStateToSoldRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.UpdateStateToSoldRequest;
  return proto.fleet_service.UpdateStateToSoldRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.UpdateStateToSoldRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.UpdateStateToSoldRequest}
 */
proto.fleet_service.UpdateStateToSoldRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.UpdateStateToSoldRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.UpdateStateToSoldRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.UpdateStateToSoldRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateStateToSoldRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.fleet_service.UpdateStateToSoldRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.UpdateStateToSoldRequest} returns this
 */
proto.fleet_service.UpdateStateToSoldRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.UpdateStateToSoldResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.UpdateStateToSoldResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.UpdateStateToSoldResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateStateToSoldResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.UpdateStateToSoldResponse}
 */
proto.fleet_service.UpdateStateToSoldResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.UpdateStateToSoldResponse;
  return proto.fleet_service.UpdateStateToSoldResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.UpdateStateToSoldResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.UpdateStateToSoldResponse}
 */
proto.fleet_service.UpdateStateToSoldResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.UpdateStateToSoldResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.UpdateStateToSoldResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.UpdateStateToSoldResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateStateToSoldResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.UpdateStateToTotalLossRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.UpdateStateToTotalLossRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.UpdateStateToTotalLossRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateStateToTotalLossRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.UpdateStateToTotalLossRequest}
 */
proto.fleet_service.UpdateStateToTotalLossRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.UpdateStateToTotalLossRequest;
  return proto.fleet_service.UpdateStateToTotalLossRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.UpdateStateToTotalLossRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.UpdateStateToTotalLossRequest}
 */
proto.fleet_service.UpdateStateToTotalLossRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.UpdateStateToTotalLossRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.UpdateStateToTotalLossRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.UpdateStateToTotalLossRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateStateToTotalLossRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.fleet_service.UpdateStateToTotalLossRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.UpdateStateToTotalLossRequest} returns this
 */
proto.fleet_service.UpdateStateToTotalLossRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.UpdateStateToTotalLossResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.UpdateStateToTotalLossResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.UpdateStateToTotalLossResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateStateToTotalLossResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.UpdateStateToTotalLossResponse}
 */
proto.fleet_service.UpdateStateToTotalLossResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.UpdateStateToTotalLossResponse;
  return proto.fleet_service.UpdateStateToTotalLossResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.UpdateStateToTotalLossResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.UpdateStateToTotalLossResponse}
 */
proto.fleet_service.UpdateStateToTotalLossResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.UpdateStateToTotalLossResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.UpdateStateToTotalLossResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.UpdateStateToTotalLossResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.UpdateStateToTotalLossResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.LogMilesPurchasedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.LogMilesPurchasedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.LogMilesPurchasedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.LogMilesPurchasedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    price: jspb.Message.getFieldWithDefault(msg, 4, 0),
    time: jspb.Message.getFieldWithDefault(msg, 5, 0),
    referenceId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.LogMilesPurchasedRequest}
 */
proto.fleet_service.LogMilesPurchasedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.LogMilesPurchasedRequest;
  return proto.fleet_service.LogMilesPurchasedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.LogMilesPurchasedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.LogMilesPurchasedRequest}
 */
proto.fleet_service.LogMilesPurchasedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.LogMilesPurchasedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.LogMilesPurchasedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.LogMilesPurchasedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.LogMilesPurchasedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getReferenceId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.fleet_service.LogMilesPurchasedRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.LogMilesPurchasedRequest} returns this
 */
proto.fleet_service.LogMilesPurchasedRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.fleet_service.LogMilesPurchasedRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.LogMilesPurchasedRequest} returns this
 */
proto.fleet_service.LogMilesPurchasedRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 amount = 3;
 * @return {number}
 */
proto.fleet_service.LogMilesPurchasedRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.LogMilesPurchasedRequest} returns this
 */
proto.fleet_service.LogMilesPurchasedRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 price = 4;
 * @return {number}
 */
proto.fleet_service.LogMilesPurchasedRequest.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.LogMilesPurchasedRequest} returns this
 */
proto.fleet_service.LogMilesPurchasedRequest.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 time = 5;
 * @return {number}
 */
proto.fleet_service.LogMilesPurchasedRequest.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.LogMilesPurchasedRequest} returns this
 */
proto.fleet_service.LogMilesPurchasedRequest.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string reference_id = 6;
 * @return {string}
 */
proto.fleet_service.LogMilesPurchasedRequest.prototype.getReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.LogMilesPurchasedRequest} returns this
 */
proto.fleet_service.LogMilesPurchasedRequest.prototype.setReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.LogMilesPurchasedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.LogMilesPurchasedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.LogMilesPurchasedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.LogMilesPurchasedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.LogMilesPurchasedResponse}
 */
proto.fleet_service.LogMilesPurchasedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.LogMilesPurchasedResponse;
  return proto.fleet_service.LogMilesPurchasedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.LogMilesPurchasedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.LogMilesPurchasedResponse}
 */
proto.fleet_service.LogMilesPurchasedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.LogMilesPurchasedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.LogMilesPurchasedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.LogMilesPurchasedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.LogMilesPurchasedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.fleet_service.LogMilesPurchasedResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.LogMilesPurchasedResponse} returns this
 */
proto.fleet_service.LogMilesPurchasedResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.DeleteMilesPurchasedLogRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.DeleteMilesPurchasedLogRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.DeleteMilesPurchasedLogRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.DeleteMilesPurchasedLogRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.DeleteMilesPurchasedLogRequest}
 */
proto.fleet_service.DeleteMilesPurchasedLogRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.DeleteMilesPurchasedLogRequest;
  return proto.fleet_service.DeleteMilesPurchasedLogRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.DeleteMilesPurchasedLogRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.DeleteMilesPurchasedLogRequest}
 */
proto.fleet_service.DeleteMilesPurchasedLogRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.DeleteMilesPurchasedLogRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.DeleteMilesPurchasedLogRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.DeleteMilesPurchasedLogRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.DeleteMilesPurchasedLogRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.fleet_service.DeleteMilesPurchasedLogRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fleet_service.DeleteMilesPurchasedLogRequest} returns this
 */
proto.fleet_service.DeleteMilesPurchasedLogRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.DeleteMilesPurchasedLogResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.DeleteMilesPurchasedLogResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.DeleteMilesPurchasedLogResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.DeleteMilesPurchasedLogResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.DeleteMilesPurchasedLogResponse}
 */
proto.fleet_service.DeleteMilesPurchasedLogResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.DeleteMilesPurchasedLogResponse;
  return proto.fleet_service.DeleteMilesPurchasedLogResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.DeleteMilesPurchasedLogResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.DeleteMilesPurchasedLogResponse}
 */
proto.fleet_service.DeleteMilesPurchasedLogResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.DeleteMilesPurchasedLogResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.DeleteMilesPurchasedLogResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.DeleteMilesPurchasedLogResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.DeleteMilesPurchasedLogResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.fleet_service.DeleteMilesPurchasedLogResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fleet_service.DeleteMilesPurchasedLogResponse} returns this
 */
proto.fleet_service.DeleteMilesPurchasedLogResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fleet_service.InfleetVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fleet_service.InfleetVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fleet_service.InfleetVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.InfleetVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleJson: jspb.Message.getFieldWithDefault(msg, 1, ""),
    programId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    financier: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fleet_service.InfleetVehicleRequest}
 */
proto.fleet_service.InfleetVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fleet_service.InfleetVehicleRequest;
  return proto.fleet_service.InfleetVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fleet_service.InfleetVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fleet_service.InfleetVehicleRequest}
 */
proto.fleet_service.InfleetVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleJson(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinancier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fleet_service.InfleetVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fleet_service.InfleetVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fleet_service.InfleetVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fleet_service.InfleetVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleJson();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProgramId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFinancier();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string vehicle_json = 1;
 * @return {string}
 */
proto.fleet_service.InfleetVehicleRequest.prototype.getVehicleJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.InfleetVehicleRequest} returns this
 */
proto.fleet_service.InfleetVehicleRequest.prototype.setVehicleJson = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string program_id = 2;
 * @return {string}
 */
proto.fleet_service.InfleetVehicleRequest.prototype.getProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.InfleetVehicleRequest} returns this
 */
proto.fleet_service.InfleetVehicleRequest.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string financier = 3;
 * @return {string}
 */
proto.fleet_service.InfleetVehicleRequest.prototype.getFinancier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fleet_service.InfleetVehicleRequest} returns this
 */
proto.fleet_service.InfleetVehicleRequest.prototype.setFinancier = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.fleet_service.MatsStatus = {
  MATS_STATUS_UNSPECIFIED: 0,
  MATS_STATUS_ALL: 1,
  MATS_STATUS_MISSING_FRONT: 2,
  MATS_STATUS_MISSING_REAR: 3,
  MATS_STATUS_NONE: 4
};

/**
 * @enum {number}
 */
proto.fleet_service.PhotoArea = {
  PHOTO_AREA_UNSPECIFIED: 0,
  PHOTO_AREA_HERO_SHOT: 1,
  PHOTO_AREA_BACK_PLATE: 2,
  PHOTO_AREA_ODOMETER: 3,
  PHOTO_AREA_OTHER: 4
};

/**
 * @enum {number}
 */
proto.fleet_service.Priority = {
  PRIMARY: 0,
  SECONDARY: 1
};

goog.object.extend(exports, proto.fleet_service);
